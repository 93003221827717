<ion-content>

  <ion-textarea [(ngModel)]="entry.text" style="resize: none"
    placeholder="Door codes, hair preferences, or rabid dogs?">
  </ion-textarea>

  <ion-button class="SC-long-button" color="marine" (click)="save()" type="submit" block>
    Save
  </ion-button>

</ion-content>