import { App } from './app';

import * as Parse from 'parse';

export class CloudCode {

  static run(
    funcName: string, 
    params: any,
    successCallback: (obj: any) => void, 
    errorCallback: (msg: string) => void) {

    App.log("Running " + funcName);
    App.log(params)

    Parse.Cloud.run(funcName, params).then((obj: any) => {
      App.log("Success: " + funcName);
      App.log(obj);
      successCallback(obj);
    }).catch((e: any) => {
      App.log("Error: " + funcName);
      App.saveVibesError(funcName, params, e.message);

      var msg = e.message

      if (msg.indexOf('Unable to connect') !== -1) {
        msg = "Unable to connect. Please refresh or contact support."
      }

      errorCallback(msg);
    })
  }

}
