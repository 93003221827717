import { Injectable } from '@angular/core';
import { CloudCode } from './cloudcode';
import { EventSeriesInfo } from './../models/event-series-info';
import {
  PublicEventInfo,
  EventBarber,
  PublicEventTimeslot,
  EventServiceOffering,
  EventBarberAndTimeslots,
} from './../models/public-event-info';
import { ServiceType } from './../models/service-type';
import { Timezone } from './timezones';

export interface EventApptCost {
  tax: number;
  subtotal: number;
  total: number;
  creditAmt?: number;
  currency: string;
}

export interface EventTimeslotInfo {
  id: string;
  startTime: Date;
  endTime: Date;
  barber: EventBarber;
  costs: EventApptCost;
  selectedService: EventServiceOffering;
  timezone: Timezone;
  eventInfo: UpcomingEventInfo;
}

export interface UpcomingEventInfo {
  id: string;
  mobileEventCode?: string;
  name: string;
  startTime: Date;
  description: string;
  locationDescription: string;
  requiresPayment: boolean;
  timezoneAbbreviation: string;
  sponsorName?: string;
  eventLinkURL?: string;
}

export interface RecentlyViewedEvent {
  mobileEventCode: string;
  name: string;
  lastViewed: Date;
}

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor() {}

  submitMobileEventCode(
    eventCode: string,
    successCallback: (eventSeriesArray: EventSeriesInfo[]) => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = { mobileEventCode: eventCode.toUpperCase() };

    CloudCode.run(
      'submitMobileEventCode',
      params,
      (rsp: EventSeriesInfo[]) => {
        if (rsp) {
          successCallback(rsp);
        } else {
          errorCallback('No event found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  pullPublicEventInfo(
    eventID: string,
    successCallback: (eventInfo: PublicEventInfo) => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = { eventID: eventID };

    CloudCode.run(
      'pullEventInfoAndPros',
      params,
      (rsp: PublicEventInfo) => {
        if (rsp) {
          successCallback(rsp);
        } else {
          errorCallback('No event found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  pullEventProsAndTimeslotsForService(
    eventID: string,
    serviceID: string,
    successCallback: (prosAndTimeslots: EventBarberAndTimeslots[]) => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = { eventID: eventID, serviceID: serviceID };

    CloudCode.run(
      'pullEventProsAndTimeslotsForService',
      params,
      (rsp: EventBarberAndTimeslots[]) => {
        if (rsp) {
          successCallback(rsp);
        } else {
          errorCallback('No event found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  lockEventTimeslot(
    timeslotID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = { timeslotID: timeslotID };

    CloudCode.run(
      'lockEventTimeslot',
      params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  rejectEventTimeslot(
    successCallback?: () => void,
    errorCallback?: (msg: string) => void,
  ) {
    CloudCode.run(
      'rejectEventTimeslot',
      {},
      (rsp: any) => {
        if (successCallback) {
          successCallback();
        }
      },
      (msg: string) => {
        if (errorCallback) {
          errorCallback(msg);
        }
      },
    );
  }

  calculateCost(
    eventID: string,
    serviceID: string,
    successCallback: (apptCost: EventApptCost) => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = {
      eventID: eventID,
      serviceID: serviceID,
    };

    CloudCode.run(
      'calculateCost_Event',
      params,
      (rsp: EventApptCost) => {
        successCallback(rsp);
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  signUpForTimeslot(
    timeslotID: string,
    serviceID: string,
    shouldCancelUpcomingTimeslot: boolean,
    successCallback: () => void,
    errorCallback: (msg: string) => void,
  ) {
    let params: any = {
      timeslotID: timeslotID,
      serviceID: serviceID,
      shouldCancelUpcomingTimeslot: shouldCancelUpcomingTimeslot,
      isNewWebSignup: true,
    };

    CloudCode.run(
      'signUpForTimeslot',
      params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  cancelTimeslotMobile(
    timeslotID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = {
      timeslotID: timeslotID,
    };

    CloudCode.run(
      'cancelTimeslotMobile',
      params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  joinWaitlist(
    eventID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = {
      eventID: eventID,
    };

    CloudCode.run(
      'joinWaitlist',
      params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  pullActiveEventServices(
    type: string,
    successCallback: (serviceTypes: ServiceType[]) => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = { type: type };

    CloudCode.run(
      'pullActiveEventServices',
      params,
      (rsp: ServiceType[]) => {
        if (rsp) {
          successCallback(rsp);
        } else {
          errorCallback('No services found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  pullServicesForEvent(
    eventID: string,
    successCallback: (serviceTypes: ServiceType[]) => void,
    errorCallback: (msg: string) => void,
  ) {
    let params = { eventID: eventID };

    CloudCode.run(
      'pullServicesForEvent',
      params,
      (rsp: ServiceType[]) => {
        if (rsp) {
          successCallback(rsp);
        } else {
          errorCallback('No services found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }

  completeEventTimeslot(
    eventTimeslotID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void,
  ) {
    let params: any = {
      eventTimeslotID: eventTimeslotID,
    };

    CloudCode.run(
      'completeEventTimeslot',
      params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      },
    );
  }
}
