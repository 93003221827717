<ion-header>
  <ion-toolbar color="marine">
    <ion-buttons slot="start">
      <ion-button slot="icon-only" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{barber.firstName}}'s reviews </ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>

  <div class="SC-empty-state-div" *ngIf="reviews && reviews.length == 0" style="margin-left: inherit; margin-right: inherit;">
    <ion-icon name="document-outline" class="SC-empty-state-icon"></ion-icon>
    <p class="SC-empty-state-text">This Pro has no public reviews.</p>
  </div>

  <div id="barber-reviews-section" *ngIf="reviews">

    <div *ngFor="let review of reviews; let last = last">

      <ion-item lines="none" style="height: 64px" class="review-item">
        <ion-thumbnail slot="start" style="min-width: 40px; min-height: 40px; line-height: 40px">
          <img [src]="review.thumbnail || placeholderImg" class="SC-round-thumb"
            style="width: 40px !important; height: 40px !important; line-height: 40px !important">
        </ion-thumbnail>
        <ion-label>
          {{review.firstName}}
          <p>{{mmddyyDate(review.date)}}</p>
          <span class="review-stars">
            <ion-icon name="star" *ngFor="let _ of toRange(review.rating)"></ion-icon>
          </span>
        </ion-label>
      </ion-item>

      <p class="review-message">{{review.message}}</p>

      <hr class="margined-divider" *ngIf="!last">

    </div>
  </div>

</ion-content>