import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController, ModalController, LoadingController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
// import { Router } from '@angular/router';

import { Appointment } from "../../models/appointment";
import { EventBarber } from '../../models/public-event-info';
import { Constants } from "../../models/constants";

import { ReviewService, EventCosts, EventTimeslot } from "../../services/review-service";
import { Alert } from "../../services/alert";
import { Util } from "../../services/util";
import { UserService } from "../../services/user-service";
import { Timezone } from "../../services/timezones";
import { ReceiptPage } from '../receipt/receipt.page';
import { EventReceiptPage } from '../event-receipt/event-receipt.page';
import { ObservableService } from 'src/app/services/observable-service';

interface TipChoice {
  id: string;
  displayString: string;
  value: number;
  isCustom?: boolean;
  showIcon?: boolean;
}

@Component({
  selector: 'app-appt-review',
  templateUrl: './appt-review.page.html',
  styleUrls: ['./appt-review.page.scss'],
})
export class ApptReviewPage implements OnInit {

  appt: Appointment;
  eventTimeslot: EventTimeslot;

  tipChoices: TipChoice[]
  selectedTipChoice: TipChoice

  notes: string = ''
  selectedRating = 0
  selectedTip = 0

  submittingReview = false

  constructor(
    private navCtrl: NavController,
    private alert: Alert,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private reviewService: ReviewService,
    private modalCtrl: ModalController,
    private userService: UserService,
    private storage: Storage,
    private observables: ObservableService,
    // private router: Router,
    private changeRef: ChangeDetectorRef,
  ) {

    // if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
    //   // We came from nav push. Load in initial props.
    //   const { state: navState } = this.router.getCurrentNavigation().extras
    //   if (navState) {
    //     this.appt = navState.data.appt;
    //     this.eventTimeslot = navState.data.eventTimeslot;

    //     console.log(this.appt)
    //     console.log(this.eventTimeslot)
    //   }
    // }

  }

  ngOnInit() {

    if (this.appt) {
      this.loadTipChoices()
    } else {
      this.loadTipChoices_Event()
    }
  }

  private loadTipChoices() {

    let fifteenPercentCost = Util.formatCost(this.appt.costs.charge * 0.15, this.appt.costs.currency)
    let twentyPercentCost = Util.formatCost(this.appt.costs.charge * 0.2, this.appt.costs.currency)

    this.tipChoices = [
      {
        id: '0',
        displayString: "No tip",
        value: 0,
      },
      {
        id: '1',
        displayString: `15% (${fifteenPercentCost})`,
        value: this.appt.costs.charge * 0.15,
      }, {
        id: '2',
        displayString: `20% (${twentyPercentCost})`,
        value: this.appt.costs.charge * 0.20,
      }, {
        id: '3',
        displayString: "Custom",
        value: 0,
        isCustom: true
      }]

    this.selectedTip = 0
    this.selectedTipChoice = this.tipChoices[0]
    this.changeRef.detectChanges()
  }

  private loadTipChoices_Event() {

    this.tipChoices = [{
      id: '0',
      displayString: `No tip`,
      value: 0,
    }, {
      id: '1',
      displayString: Util.formatCost(5, this.eventTimeslot.costs.currency, 0),
      value: 5,
    }, {
      id: '2',
      displayString: Util.formatCost(10, this.eventTimeslot.costs.currency, 0),
      value: 10,
    }, {
      id: '3',
      displayString: "Custom",
      value: 0,
      isCustom: true
    }]

    this.selectedTip = 0

    this.selectedTipChoice = this.tipChoices[0]
    this.changeRef.detectChanges()
  }

  async submit() {

    if (this.submittingReview) {
      return
    }

    this.submittingReview = true

    let tip = this.selectedTipChoice.value;
    let rating = this.selectedRating;
    let notes = this.notes;

    console.log(tip)
    console.log(rating)
    console.log(notes)

    if (rating === 0) {
      this.submittingReview = false
      this.alert.show('Error', 'Please select a rating.', 'Ok');
      return;
    }

    if (tip > 50) {
      this.alert.show(
        'Please Confirm',
        `Tip amount: ${Util.formatCost(tip, this.tipCurrency())}`,
        'Confirm',
        () => {
          this.completeSubmit(tip, rating, notes);
        },
        'Cancel',
        () => { }
      )
    } else {
      this.completeSubmit(tip, rating, notes);
    }
  }

  private async completeSubmit(tip: number, rating: number, notes: string) {
    let loading = await this.loadingCtrl.create();
    loading.present();

    if (this.appt) {

      this.reviewService.submitReview(this.appt.id, tip, rating, notes, () => {
        this.submittingReview = false
        loading.dismiss();
        this.modalCtrl.dismiss()
        this.observables.publishApptReviewCompleted()
        if (rating === 5) {
          // TODO: Re-style ReferFriendPage and implement a browser share service

          // this.storage.get(Constants.INVITE_SENT).then((val) => {
          //   if (!val && this.userService.user.numCompletedAppts <= 3) {
          //     this.alert.show(
          //       'Enjoying Shortcut?',
          //       'Invite a friend for $10 credit! Your referral will get 15% off their first in-home appointment.',
          //       'Continue',
          //       () => {
          //         this.storage.set(Constants.INVITE_SENT, true)
          //         this.events.publish(Constants.SHOW_REFER_A_FRIEND)
          //       },
          //       'Skip',
          //       () => {})
          //   }
          // });
        }
      }, (errorMsg: string) => {
        this.submittingReview = false
        loading.dismiss();
        this.reviewError(errorMsg);
      });

    } else {

      this.reviewService.submitEventReview(this.eventTimeslot.id, tip, rating, notes, () => {
        this.submittingReview = false
        loading.dismiss();
        this.modalCtrl.dismiss()
      }, (errorMsg: string) => {
        this.submittingReview = false
        loading.dismiss();
        this.reviewError(errorMsg);
      });

    }
  }

  private reviewError(errorMsg: string) {
    this.alert.show('Error', errorMsg, 'Update payment method', () => {
      this.navCtrl.navigateForward('/payment');
    });
  }

  onCancel() {
    this.alert.show(
      'Skip rating and tip?',
      'Pros depend on reviews to attract new customers. You can complete this rating and tip later in your Order History.',
      'Skip Review',
      async () => {
        let loading = await this.loadingCtrl.create();
        loading.present();

        this.reviewService.skipApptReview(this.appt.id, () => {
          loading.dismiss()
          this.modalCtrl.dismiss()
        }, (errorMsg: string) => {
          loading.dismiss()
          this.modalCtrl.dismiss()
        })
      },
      'Cancel',
      () => { }
    )
  }



  // Helpers

  getLongDateString(date: Date, timezone: Timezone): string {
    return Util.longDateString(date, timezone)
  }

  tipChoicePressed(tipChoice: TipChoice) {

    if (tipChoice.isCustom) {
      this.showCustomTipSelector(tipChoice)
      return
    }

    this.selectedTipChoice = tipChoice

  }



  private tipCurrency() {
    return this.appt ? this.appt.costs.currency : this.eventTimeslot.costs.currency;
  }

  tipButtonText() {
    if (this.selectedTipChoice && this.selectedTipChoice.value) {
      return `Rate and tip ${Util.formatCost(this.selectedTipChoice.value, this.tipCurrency())}`
    } else if (this.selectedTipChoice && !this.selectedTipChoice.value) {
      return "Rate and don't tip";
    }
    return "Rate and tip";
  }

  private async showCustomTipSelector(tipChoice: TipChoice) {

    const currency = this.tipCurrency();

    let alert = await this.alertCtrl.create({
      inputs: [
        {
          name: 'value',
          placeholder: `Enter tip in ${currency}`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Save',
          handler: (data: any) => {
            let value = parseFloat(data.value)
            tipChoice.value = value
            tipChoice.displayString = Util.formatCost(value, currency);
            tipChoice.showIcon = true
            this.selectedTipChoice = tipChoice
          }
        }
      ]
    });
    alert.present();
  }


  // Nav

  async receipt() {

    if (this.appt) {

      const receiptModal = await this.modalCtrl.create({
        component: ReceiptPage,
        componentProps: {
          appt: this.appt,
        },
        cssClass: 'auto-height'
      })

      receiptModal.present()

    } else {

      const receiptModal = await this.modalCtrl.create({
        component: EventReceiptPage,
        componentProps: {
          cost: this.eventTimeslot.costs,
          serviceTitle: (this.eventTimeslot.selectedService || {}).serviceTitle,
        },
        cssClass: 'auto-height'
      })

      receiptModal.present()

    }
  }

}
