import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/models/constants';

@Component({
  selector: 'confirm-footer',
  templateUrl: './confirm-footer.component.html',
  styleUrls: ['./confirm-footer.component.scss'],
})
export class ConfirmFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  goToWebsite() {
    window.open(Constants.MAIN_WEBSITE_URL, '_blank')
  }
}
