import { Injectable } from '@angular/core';

import { AlertController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class Alert {

  constructor(private alertCtrl: AlertController) {
  }

  async show(
    title: string,
    message: string, 
    okButton?: string, 
    okAction?: () => void,
    cancelText?: string,
    cancelAction?: () => void,
    backgroundDismiss: boolean = true
  ) {

    let buttons: any[] = [{
      text: okButton || "Ok",
      handler: okAction || (() => {})
    }]

    if (cancelText && cancelAction) {
      buttons.push({
        text: cancelText,
        role: 'cancel',
        handler: cancelAction
      })
    } 

    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: buttons,
      backdropDismiss: backgroundDismiss
    });

    alert.present();
  }

  async showPrompt(
    title: string, 
    message: string,
    inputName: string,
    inputPlaceholder: string,
    inputType: ("number" | "search" | "password" | "time" | "text" | "tel" | "url" | "email" | "date" | "checkbox" | "radio"),
    okButton?: string, 
    okAction?: (data: any) => void,
    cancelText?: string,
    cancelAction?: (data: any) => void,
    cssClass?: string,
    backgroundDismiss: boolean = true
  ) {

    let buttons: any[] = [{
      text: okButton || "Ok",
      handler: okAction || (() => {})
    }]

    if (cancelText && cancelAction) {
      buttons.push({
        text: cancelText,
        role: 'cancel',
        handler: cancelAction
      })
    } 

    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      inputs: [
        {
          type: inputType,
          name: inputName,
          placeholder: inputPlaceholder,
          handler: okAction || (() => {})
        },
      ],
      buttons: buttons,
      cssClass: cssClass,
      backdropDismiss: backgroundDismiss
    });

    alert.present();
  }

}
