import { Address } from "./address";
import { CountdownTimer } from "./countdown-timer";
import { Appointment } from "./appointment";
import { ProductQuantityList } from "../services/product-sale-request-tasks";
import { CCMapType } from "./cc-types";
import { Barber } from "./barber";

export interface Room {
	id: string
    name: string
    img: string
	type: string
	numMessages: number
	lastMsg: Message
    isMuted: boolean
    roomApptInfo: RoomApptInfo
    client?: RoomClient
    barber?: RoomBarber
    showNames: boolean
    clientCanSendImage: boolean
}

export interface RoomClient {
    id: string
    img?: string
    firstName: string
    fullName: string
    address?: Address
}

export interface RoomBarber {
    id: string
    pic: string
    firstName: string
    fullName: string
    link: string
    serviceIDs: string[]
    servicePricing: object
    dateLastActiveInMessages: Date
    businessID?: string;
    businessName?: string;
    businessPic?: string;
}

export interface RoomApptInfo {
    message: string
    confirmed: boolean
    expiryDate?: Date
    countdownTimer?: CountdownTimer
    apptID: string
    apptObj: Appointment
    apptRequestID?: string
}

export interface ProductSaleRequestInfo {
    id: string;
    productSaleID: string;
    purchaseDate?: Date;
    expiryDate: Date;
    address: Address;
    productQuantityList: ProductQuantityList;
    productImageMap: CCMapType<string>;
    costs: RestrictedProductSaleCosts;
    barberID: string;
    expired: boolean;
    purchased: boolean;
    clientFullName: string;
    skipShipping: boolean;

    // Frontend
    countdownTimer?: any;
    barber?: Barber;
}

interface RestrictedProductSaleCosts {
    productQuantityList: ProductQuantityList;
    subtotal: number;
    finalCost: number;
    tax: number;
    shippingCost: number;
    productSubtotalMap: CCMapType<number>;
}

export interface Message {
    id?: string
	msgType: MessageType
    text?: string
	sender: Sender
    blurb?: string
    isUnread?: boolean
    createdAt: Date
    apptRequestInfo?: any
    invoiceRequestInfo?: any
    reservedApptInfo?: any
    sendingID?: string
}

export interface Sender {
    id: string
    type?: SenderType
    firstName: string
    fullName: string
    img?: string
}

export enum MessageType {
    Text = "text",
}

enum SenderType {
    Client = "client",
    Barber = "barber",
    Admin = "admin",
}