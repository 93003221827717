<ion-content>

  <div class="logo">
    <img src="assets/img/shortcut-icon.png" alt="Shortcut">
  </div>

  <p class="SCWEB-header-text">Upload a nice photo<br>of yourself.</p>

  <p id="description-text">Pros love to see who they're chatting with.</p>

  <div class="image-backdrop" (click)="onUploadImage()">
    <ion-icon *ngIf="!hasPhoto" name="download" color="marine"></ion-icon>
    <p *ngIf="!hasPhoto">Attach photo</p>
    <img *ngIf="hasPhoto" [src]="imgURL" id="profile-img" />
  </div>

  <ion-button *ngIf="!hasPhoto" class="SC-long-button submit-button" color="marine" (click)="onUploadImage()"
    type="submit" block>
    Upload photo
  </ion-button>
  <ion-button *ngIf="hasPhoto" class="SC-long-button submit-button" color="marine" (click)="onContinue()" type="submit"
    block>
    Continue
  </ion-button>

  <ion-row class="SC-bottom-button-row">
    <ion-col>
      <ion-button class="SC-light-bottom-button" fill="clear" (click)="onSkip()">Skip</ion-button>
    </ion-col>
  </ion-row>

</ion-content>