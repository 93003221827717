
export interface SelectedServiceType {
  id: string
  title: string
  quantity: number
  price?: number
  isChatOnlyService: boolean
}

export class SelectedServiceTypeList {

  private min = 0
  private max = 4
  
  items: SelectedServiceType[] = []
  longText: string = ""
  text: string = ""

  constructor() {
    this.refreshText()
  }

  addNewItem(id: string, title: string, quantity: number, isChatOnlyService: boolean) {
    let item: SelectedServiceType = {
      id: id,
      title: title,
      quantity: this.ensureWithinRange(quantity),
      isChatOnlyService: isChatOnlyService
    }

    if (quantity > 0) this.items.push(item)

    this.refreshText()
  }

  quantityForId(id: string) {

    for (let item of this.items) {
      if (item.id == id) {
         return item.quantity
      }
    }

    return 0
  }

  refreshText() {

    var serviceCount = 0
    var totalQuantity = 0
    var lastTitle: string
    var newText: string

    for (let item of this.items) {
      totalQuantity += item.quantity

      if (item.quantity > 0) {
        serviceCount++
        lastTitle = item.title
      }
    }

    if (serviceCount == 0) {
      newText = "Any service"
    } else if (serviceCount == 1) {
      newText = `${totalQuantity} ${lastTitle}`
    } else {
      newText = `${totalQuantity} service`
    }

    if (totalQuantity > 1) {
      newText += 's';
    }

    this.text = newText

    this.refreshLongText()

  }

  private refreshLongText() {

    if (!this.items.length) {
      this.longText = "Any service"
      return
    }

    var quantityDescriptions = []

    for (let item of this.items) {

      if (item.quantity > 0) {

        var description = item.quantity + " " + item.title

        if (item.quantity > 1) {
          description += 's';
        }

        quantityDescriptions.push(description)
      }
    }

    this.longText = quantityDescriptions.join(", ")
  }

  private ensureWithinRange(q: number): number {
    return Math.min( Math.max(q, this.min), this.max)
  }

}