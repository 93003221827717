import { Device } from "@ionic-native/device/ngx";

import { Util } from "./util";
import { DB } from "./db";

import * as Parse from "parse";

export class App {
  private static device = new Device();

  static log(message: string) {
    console.log(message);
  }

  static error(message: string) {
    console.error(message);
  }

  static saveVibesError(funcName: string, params: any, message: string) {
    if (Util.isNavigationError(message)) {
      return;
    }

    App.error(message);

    // DB.saveNew("Error", {
    //   function: funcName,
    //   params: params,
    //   message: message,
    //   user: Parse.User.current(),
    //   app: "Web Vibes",
    //   deviceInfo: App.deviceInfo()
    // },
    //   (obj: any) => {
    //     // Success. Do nothing.
    //   },
    //   (msg: string) => {
    //     App.error("Could not save Error.");
    //     App.error(msg);
    // });
  }

  private static deviceInfo() {
    return {
      id: App.device.uuid,
      model: App.device.model,
      cordova: App.device.cordova,
      platform: App.device.platform,
      version: App.device.version,
      manufacturer: App.device.manufacturer,
      serial: App.device.serial,
      isVirtual: App.device.isVirtual,
    };
  }
}
