import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '../services/util';


@Pipe({
    name: 'timeslotTime',
})
export class TimeslotTimePipe implements PipeTransform {
    transform(value: Date) {
        return Util.timeslotTime(value);
    }
}