import { Address, Coordinates } from '../models/address';

import { CloudCode } from './cloudcode';
import { Util } from './util';

export interface Timezone {
  abbr?: string;
  offset: number;
}

export class Timezones {

  static timezoneInfoForAddress(
    address: Address, 
    date: Date, 
    successCallback: (timezone: Timezone) => void,
    errorCallback: (msg: string) => void) {

    let params = {
      address: address,
      apptDate: date
    }
    
    CloudCode.run('timezoneInfoForAddress', params, 
      (rsp: any) => {
        if (rsp.abbreviation && rsp.timezoneOffset !== null && rsp.timezoneOffset !== undefined) {
          let timezone = {
            abbr: rsp.abbreviation,
            offset: rsp.timezoneOffset
          }
          successCallback(timezone);
        } else {
          errorCallback("Failed to form timezone.");
        }
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static convertToAddressTimezone(d: Date, addressTimezoneOffset: number): Date {
    
    let localOffset = d.getTimezoneOffset()
    let offsetDifference = addressTimezoneOffset - localOffset;
    return Util.addMinutes(d, offsetDifference);

  }

  // The returned date may be "incorrect", but will show accurately when using the
  //   function Util.prettyTime.
  static timezoneAgnosticDate(d: Date, timezoneOffset: number): Date {

    var localOffset = d.getTimezoneOffset();
    var offsetDifference = localOffset - timezoneOffset;
    d = Util.addMinutes(d, offsetDifference);
    return d;

  }

  static coordinatesForAddress(
    address: Address, 
    successCallback: (coordinates: any) => void,
    errorCallback: (msg: string) => void) {

    let params = {
      address: address,
    }

    CloudCode.run('coordinatesForAddress', 
      params, 
      (coordinates: any) => {
        successCallback(coordinates);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static addressForCoordinates(
    coordinates: Coordinates, 
    successCallback: (address: Address) => void,
    errorCallback: (msg: string) => void) {

    let params = {
      coordinates
    }

    CloudCode.run('addressForCoordinates', 
      params, 
      (address: Address) => {
        successCallback(address);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

}
