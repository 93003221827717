import { CloudCode } from './cloudcode';

import { Room } from '../models/message'

export class RoomTasks {

  static getRoomInfo(
    roomID: string,
    successCallback: (room: Room) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('getRoomInfo',
      {
        roomID: roomID
      },
      (room: Room) => {
        successCallback(room);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static pullSubscribedRooms(
    showMutedRooms: boolean,
    successCallback: (rooms: Room[]) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('pullSubscribedRooms',
      {
        showMutedRooms: showMutedRooms
      },
      (rooms: Room[]) => {
        successCallback(rooms);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  // static pullAllRooms(
  //   showMutedRooms: boolean,
  //   successCallback: (rooms: Room[]) => void,
  //   errorCallback: (msg: string) => void) {

  //   CloudCode.run('pullAllRooms',
  //     {
  //       // showMutedRooms: showMutedRooms
  //     },
  //     (rooms: Room[]) => {
  //       successCallback(rooms);
  //     },
  //     (msg: string) => {
  //       errorCallback(msg);
  //     });
  // }

  static toggleMuteRoom(
    roomID: string,
    shouldMute: boolean,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('toggleNotificationsForRoom',
      {
        roomID: roomID,
        shouldGetNotifications: !shouldMute
      },
      () => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static getNumUnreadMessages(
    successCallback: (num: number) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('getNumUnreadMessages',
      {},
      (num: number) => {
        successCallback(num);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static hasUnreadMessages(
    successCallback: (hasUnreadMessages: boolean, firstUnreadRoomID?: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('hasUnreadMessages_WithRoomID',
      {},
      (rsp: any) => {
        successCallback(rsp.hasUnreadMessages, rsp.firstUnreadRoomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static createClientBarberRoom(
    barberID: string,
    successCallback: (roomID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('createClientBarberRoom',
      {
        barberID: barberID
      },
      (roomID: string) => {
        successCallback(roomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static createBusinessBarberRoom(
    barberID: string,
    successCallback: (roomID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('createBusinessBarberRoom_Business',
      {
        barberID: barberID,
      },
      (roomID: string) => {
        successCallback(roomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static createBusinessClientRoom(
    clientID: string,
    successCallback: (roomID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('createBusinessClientRoom_Business',
      {
        clientID: clientID,
      },
      (roomID: string) => {
        successCallback(roomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static createClientBarberRoom_Business(
    clientID: string,
    barberID: string,
    successCallback: (roomID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('createClientBarberRoom_Business',
      {
        clientID: clientID,
        barberID: barberID,
      },
      (roomID: string) => {
        successCallback(roomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static createClientAdminRoom(
    successCallback: (roomID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('createClientAdminRoom',
      {},
      (roomID: string) => {
        successCallback(roomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static createClientConciergeRoom(
    successCallback: (roomID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('createClientConciergeRoom',
      {},
      (roomID: string) => {
        successCallback(roomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static createBusinessAdminRoom(
    successCallback: (roomID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('createBusinessAdminRoom',
      {},
      (roomID: string) => {
        successCallback(roomID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  // static createBarberClientRoom(
  //   userID: string,
  //   successCallback: (roomID: string) => void,
  //   errorCallback: (msg: string) => void) {

  //   CloudCode.run('createBarberClientRoom',
  //     {
  //       userID: userID
  //     },
  //     (roomID: string) => {
  //       successCallback(roomID);
  //     },
  //     (msg: string) => {
  //       errorCallback(msg);
  //     });
  // }

}