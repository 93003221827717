export class Constants {
  static BROWSER_MODE = true;

  static DEV_MODE = false;
  static LOCAL_MODE = false;

  static WEB_MOBILE_VERSION_URL = Constants.DEV_MODE
    ? 'https://dev-app.getshortcut.co'
    : 'https://app.getshortcut.co';

  // PROD CONSTANTS
  static PARSE_JS_KEY = '9XfHVi9UJIxa3IPioC8FJN2Lc2dqFnpvTZljsl7C';
  static PARSE_SERVER = Constants.LOCAL_MODE
    ? 'http://localhost:1337/parse'
    : 'https://parse.getshortcut.co/parse';
  static STRIPE_KEY = 'pk_live_eG4AvdbW8kQpluIATLrlIDCU';
  static BRANCH_KEY = Constants.DEV_MODE
    ? 'key_test_bbCaga7HYTo23NjXcXJjtijpuyft0adK'
    : 'key_live_cpzfao6M0Kj6ZRh1c4sGSjimzEgy5ddA';

  // DEV CONSTANTS
  static DEV_PARSE_JS_KEY = 'dqawSCSE8nRofhUer0DjiMp9eyNZ4NIPZkzzk1ZW';
  static DEV_PARSE_SERVER = Constants.LOCAL_MODE
    ? 'http://localhost:1337/parse'
    : 'https://parse-dev.getshortcut.co/parse';
  static DEV_STRIPE_KEY = 'pk_test_mO1srTwdWaFiD0gVAFPuBQfM';

  static SCC_ADMIN_URL = Constants.DEV_MODE
    ? 'https://dev-admin.shortcutpros.com'
    : 'https://admin.shortcutpros.com';
  static APP_LINK_URL = Constants.DEV_MODE
    ? 'https://shortcut-test.app.link'
    : 'https://shortcut.app.link';

  static MAIN_WEBSITE_URL = 'https://www.getshortcut.co';
  static APP_STORE_URL = 'https://apps.apple.com/us/app/shortcut/id919815360';
  static GOOGLE_PLAY_URL =
    'https://play.google.com/store/apps/details?id=co.getshortcut.vibes';
  static DEV_SLACK_REDIRECT_URI = 'https://dev-app.getshortcut.co/setup-slack'; // 'http://localhost:8100/setup-slack' //

  static STRIPE_REDIRECT_URI = Constants.DEV_MODE
    ? 'https://dev.shortcutpros.com/#/registerStripe_Pro'
    : 'https://www.shortcutpros.com/#/registerStripe_Pro';
  static STRIPE_CONNECT_CLIENT_ID = Constants.DEV_MODE
    ? 'ca_D4cfFqfjQ6cpKEGGIUYjc0zKIs7c91v9'
    : 'ca_D4cfxC5QMG3mQroxGknzfvpv3BwA5zUE';

  // Events
  static SHOW_APPT_REVIEW = 'SHOW_APPT_REVIEW';
  static SHOW_EVENT_REVIEW = 'SHOW_EVENT_REVIEW';
  static GO_TO_ENTER_PHONE_NUMBER = 'GO_TO_ENTER_PHONE_NUMBER';
  static GO_TO_ENTER_VERIFICATION_CODE = 'GO_TO_ENTER_VERIFICATION_CODE';
  static GO_TO_SIGN_IN = 'GO_TO_SIGN_IN';
  static GO_TO_SIGN_UP = 'GO_TO_SIGN_UP';
  static GO_TO_ADD_PROF_PIC = 'GO_TO_ADD_PROF_PIC';
  static UPDATE_USER_EVENT = 'app:updateUser';
  static USER_FETCHED_EVENT = 'USER_FETCHED_EVENT';
  static REFRESH_MAIN_VIEW_EVENT = 'app:refreshMainView';
  static REFRESH_SIDE_MENU_EVENT = 'app:refreshSideMenu';
  static REGISTER_PUSH_EVENT = 'app:registerPush';
  static CHECK_FOR_REVIEW = 'app:checkForReview';
  static APP_DID_BECOME_ACTIVE = 'app:appDidBecomeActive';
  static BRANCH_LINK_CLICKED = 'app:branchLinkClicked';
  static SHOW_DOWNLOAD_POPUP_EVENT = 'mobile:showDownloadPopup';
  static LOCATION_PERMISSION_GRANTED = 'app:locationPermissionGranted';
  static BARBER_PUSH_CLICKED = 'app:barberPushClicked';
  static REFRESH_MAIN_VIEW_FORCE_BOOKING = 'app:forceBooking';
  static REFRESH_MAIN_VIEW_FORCE_APPOINTMENTS = 'app:forceAppts';
  static PROF_PIC_UPDATED = 'app:profPicUpdated';
  static SHOW_REFER_A_FRIEND = 'SHOW_REFER_A_FRIEND';
  static LOGOUT = 'LOGOUT';
  static CHAT_MESSAGE_SENT = 'CHAT_MESSAGE_SENT';
  static BOOKED = 'BOOKED';
  static JOINED_WAITLIST = 'JOINED_WAITLIST';
  static GIFT_CARD_PURCHASED = 'GIFT_CARD_PURCHASED';
  static REFRESH_APP = 'REFRESH_APP';
  static APP_FAILED_LOAD_USER = 'APP_FAILED_LOAD_USER';
  static ENTER_EVENT_CODE = 'APP: enter event code';
  static OPEN_NEW_CHAT = 'APP: open new chat';
  static MINIMIZE_ALL_CHATS = 'APP: minimize all chats';
  static UPDATE_CHAT_ROOM_INFOS = 'APP: Update chat room infos';
  static UPDATED_ROOM_INFO = 'APP: Updated single room info';
  static CHECK_UNREAD_MESSAGES = 'App: Check has unread messages';
  static APPT_REVIEW_COMPLETED = 'APPT_REVIEW_COMPLETED';
  static EMPTY_NAME_EMAIL_EVENT = 'EMPTY_NAME_EMAIL_EVENT';
  static LIVE_MESSAGE_CREATE = 'Live message: create';
  static LIVE_MESSAGE_UPDATE = 'Live message: update';
  static VERIFY_PHONE_COMPLETE = 'VERIFY_PHONE_COMPLETE';
  static BUSINESS_FETCHED_EVENT = 'BUSINESS_FETCHED_EVENT';

  // Keys
  static NO_EMAIL_OR_NAME_KEY = 'user_needs_email_or_name';
  static WELCOME_EMAIL_FAILED = 'welcome_email_failed';
  static USER_SIGNED_UP = 'user_signed_up';
  static NO_APPT_FOR_REVIEW = 'no_appt_for_review';
  static NO_PAYMENT = 'no_payment';
  static PROMO_CODE = 'promo_code';
  static SAVED_SERVICE_TYPE_LIST = 'saved_service_type_list';
  static APP_RATED = 'app_rated';
  static INVITE_SENT = 'invite_sent';
  static HAS_SEEN_CHAT_NOTIFICATION_PROMPT =
    'HAS_SEEN_CHAT_NOTIFICATION_PROMPT';
  static HAS_SEEN_BOOKED_NOTIFICATION_PROMPT =
    'HAS_SEEN_BOOKED_NOTIFICATION_PROMPT';
  static HAS_SEEN_WAITLIST_NOTIFICATION_PROMPT =
    'HAS_SEEN_WAITLIST_NOTIFICATION_PROMPT';
  static BRANCH_PARAMS_KEY = 'BRANCH_PARAMS_KEY';
  static ADDRESS_IS_FROM_GPS = 'ADDRESS_IS_FROM_GPS';
  static SAVED_MESSAGES = 'SAVED_MESSAGES';
  static CHAT_MANAGER_SETTINGS = 'CHAT_MANAGER_SETTINGS';
  static SEEN_POPUPS_LIST = 'SEEN_POPUPS_LIST';

  // Deeplinks
  static HANDLE_DEEP_LINKS = 'Handle deep links';
  static DEEPLINK_BARBER = 'deeplink: barber';
  static DEEPLINK_PROMO = 'deeplink: promo';
  static DEEPLINK_EVENT = 'deeplink: event';
  static DEEPLINK_ROOM = 'deeplink: room';
  static DEEPLINK_CHATSUPPORT = 'deeplink: chat support';
  static DEEPLINK_INVITE = 'deeplink: invite friend';
  static DEEPLINK_GIFT_CARD = 'deeplink: gift-card';
  static DEEPLINK_OPEN_GIFT_CARD = 'deeplink: open-gift-card';

  static SET_CURRENT_MENU_ROOT = 'set current menu root';
}
