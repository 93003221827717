import { Barber } from "./barber";
import { SelectedServiceType } from "./selected-service-type-list";
import { Address } from "./address";

import { Timezone } from "../services/timezones";

export enum AppointmentStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Reviewed = 'Reviewed',
    Cancelled = 'Cancelled by Client',
    CancelledAdmin = 'Cancelled by Admin',
    Unapproved = 'Unapproved'
}

export interface Appointment {

    id: string
    startTime: Date
    endTime: Date
    status: AppointmentStatus
    address: Address
    barber: Barber
    review: AppointmentReview
    customer: AppointmentCustomer
    costs: AppointmentCosts
    timezone: Timezone
    refunded: boolean
    cancelled: boolean
    serviceTypeList: SelectedServiceType[]
    specialInstructions?: string
    promoCode?: string

}


export interface AppointmentReview {
    notes: string
    overallRating: number
}

export interface AppointmentCustomer {
    cardBrand: string
    last4: string
}

export interface AppointmentCustomer {
    cardBrand: string
    last4: string
}

export interface AppointmentCosts {
    charge: number
    tax: number
    beforeCouponCost: number
    promoAmt: number
    creditAmt: number
    refundAmt?: number
    tip: number
    travelUpcharge: number
    convenienceFee: number
    currency: string
    businessComp?: number
}