import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavController, LoadingController, ModalController } from '@ionic/angular';

import { Constants } from "../../models/constants";

import { UserService } from '../../services/user-service';
import { Alert } from "../../services/alert";
import { PushInstallationService } from "../../services/push-installation-service";
import { AnalyticsService } from '../../services/analytics-service';
import { ObservableService } from 'src/app/services/observable-service';

declare const gapi: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
})
export class SignUpPage implements OnInit {

  signupFields: { fullName?: string, email?: string, password?: string } = {};
  preventMainRefresh?: boolean
  signUpFromEvent: boolean
  navHistory = ""

  googleAuth: any;

  constructor(
    private navCtrl: NavController,
    public userService: UserService,
    public alert: Alert,
    private loadingCtrl: LoadingController,
    public observables: ObservableService,
    public pushInstallationService: PushInstallationService,
    private analytics: AnalyticsService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.googleInit();
  }

  googleInit() {
    gapi.load('auth2', () => {
      this.googleAuth = gapi.auth2.init({
        client_id: '358390183023-vl348t7t2mb0mmn23krpdhnts9lmjv41.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachGoogleSignIn(document.getElementById('google'));
    });
  }

  attachGoogleSignIn(element: any) {
    this.googleAuth.attachClickHandler(element, {},
      async (googleUser) => {

        let profile = googleUser.getBasicProfile();

        const idToken = googleUser.getAuthResponse().id_token
        const id = profile.getId()
        const firstName = profile.getGivenName()
        const lastName = profile.getFamilyName()
        const email = profile.getEmail()

        let loading = await this.loadingCtrl.create();
        loading.present();

        this.userService.signInWithGoogle(
          loading,
          idToken,
          id,
          firstName,
          lastName,
          email,
          (userExisted: boolean) => {
            this.analytics.setUserID(this.userService.user.id)
            if (!userExisted) {
              if (this.navHistory === "EventDirect" || this.navHistory === "Event") {
                this.analytics.track(AnalyticsService.SignedUpEvent)
                this.userService.update({ eventSignUp: true }, () => { }, (errorMsg: string) => { })
              } else {
                this.analytics.track(AnalyticsService.SignedUp)
              }
              this.analytics.track("SignedUp_" + this.navHistory)
            }
            loading.dismiss();
            this.signupSuccess(userExisted);
          }, (msg: string) => {
            loading.dismiss();
            if (msg === Constants.NO_EMAIL_OR_NAME_KEY) {
              this.goToEmptyNameEmailScreen();
            } else {
              this.alert.show('Error', msg || 'Something went wrong. Please try again.', 'Ok');
            }
          }
        )
      }, (error) => {
        console.log(error)
        this.alert.show('Oops', 'Something went wrong. Please try again')
      });
  }

  openPage(url: string) {
    window.open(url, "_blank");
  }

  onSignup(form: NgForm) {

    if (!form.valid) {
      this.signupError("Invalid entries.");
    }

    this.signup_Username(
      this.signupFields.fullName,
      this.signupFields.email,
      this.signupFields.password);
  }

  onSignup_Facebook() {
    this.signup_Facebook();
  }

  async gotoLogin() {
    await this.modalCtrl.dismiss()
    this.observables.publishGoToLogin(this.preventMainRefresh)
  }

  gotoTerms() {
    this.openPage('https://getshortcut.co/terms')
  }

  gotoPrivacy() {
    this.openPage('https://getshortcut.co/privacy')
  }

  closePressed() {
    this.navCtrl.pop()
  }


  //
  // -- Private Methods --
  //

  private async signup_Username(
    fullName: string,
    username: string,
    password: string) {

    let loading = await this.loadingCtrl.create()
    loading.present()

    if (this.userService.user && this.userService.user.isGuest) {
      this.userService.signup_Guest(fullName.trim(), username.trim(), password, () => {

        this.analytics.setUserID(this.userService.user.id)
        if (this.navHistory === "EventDirect" || this.navHistory === "Event") {
          this.analytics.track(AnalyticsService.SignedUpEvent)
          this.userService.update({ eventSignUp: true }, () => { }, (errorMsg: string) => { })
        } else {
          this.analytics.track(AnalyticsService.SignedUp)
        }
        this.analytics.track("SignedUp_" + this.navHistory)
        loading.dismiss()
        this.signupSuccess()
      }, (errorMsg: string) => {
        loading.dismiss()
        this.signupError(errorMsg)
      })

      return
    }

    this.userService.signup_Username(fullName.trim(), username.toLowerCase().trim(), password,
      () => {

        this.analytics.setUserID(this.userService.user.id)
        if (this.navHistory === "EventDirect" || this.navHistory === "Event") {
          this.analytics.track(AnalyticsService.SignedUpEvent)
          this.userService.update({ eventSignUp: true }, () => { }, (errorMsg: string) => { })
        } else {
          this.analytics.track(AnalyticsService.SignedUp)
        }
        this.analytics.track("SignedUp_" + this.navHistory)

        this.userService.sendWelcomeEmail(() => {
          loading.dismiss()
          this.signupSuccess()
        }, (errorMsg) => {
          loading.dismiss();
          this.alert.show('Email Failed', 'Please enter your email again.', 'Ok', () => {
            this.goToEmptyNameEmailScreen();
          });
        });
      },
      (msg: string) => {
        loading.dismiss()
        this.signupError(msg)
      });
  }

  private async signup_Facebook() {
    let loading = await this.loadingCtrl.create();
    loading.present();

    if (this.userService.user && this.userService.user.isGuest) {
      this.userService.linkFacebook(() => {

        this.analytics.setUserID(this.userService.user.id)
        if (this.navHistory === "EventDirect" || this.navHistory === "Event") {
          this.analytics.track(AnalyticsService.SignedUpEvent)
          this.userService.update({ eventSignUp: true }, () => { }, (errorMsg: string) => { })
        } else {
          this.analytics.track(AnalyticsService.SignedUp)
        }
        this.analytics.track("SignedUp_" + this.navHistory)
        loading.dismiss();
        this.signupSuccess();

      }, (msg: string) => {
        loading.dismiss();
        if (msg === Constants.NO_EMAIL_OR_NAME_KEY) {
          this.goToEmptyNameEmailScreen();
        } else {
          this.alert.show('Error', msg || 'Something went wrong. Please try again.', 'Ok');
        }
      })
      return
    }

    this.userService.login_Facebook(
      () => {
        this.analytics.setUserID(this.userService.user.id)
        if (this.navHistory === "EventDirect" || this.navHistory === "Event") {
          this.analytics.track(AnalyticsService.SignedUpEvent)
          this.userService.update({ eventSignUp: true }, () => { }, (errorMsg: string) => { })
        } else {
          this.analytics.track(AnalyticsService.SignedUp)
        }
        this.analytics.track("SignedUp_" + this.navHistory)
        loading.dismiss();
        this.signupSuccess();
      },
      (msg: string) => {
        loading.dismiss();
        if (msg === Constants.NO_EMAIL_OR_NAME_KEY) {
          this.goToEmptyNameEmailScreen();
        } else if (msg === Constants.WELCOME_EMAIL_FAILED) {
          this.alert.show('Email Failed', 'Please enter your email again.', 'Ok', () => {
            this.goToEmptyNameEmailScreen();
          });
        } else {
          this.alert.show('Error', msg || 'Something went wrong. Please try again.', 'Ok');
        }
      });
  }

  private async signupSuccess(userExisted: boolean = false) {
    this.observables.publishRegisterPush()

    await this.modalCtrl.dismiss()
    this.observables.publishRefreshSideMenu()

    if (!this.preventMainRefresh) {
      this.observables.publishRefreshMainView()
    } else if (userExisted) {
      // Nothing to do here, just let the modal dismiss
    } else if (this.signUpFromEvent) {
      // Skip prof pic if signing up from event
      this.observables.publishGoToEnterPhoneNumber()
    } else {
      this.observables.publishGoToAddProfPic()
    }
  }

  private signupError(msg: string) {
    this.alert.show('Error', msg);
  }

  private async goToEmptyNameEmailScreen() {
    await this.modalCtrl.dismiss()
    this.observables.publishGoToEmptyNameEmail()
  }

}
