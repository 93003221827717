<ion-header>

  <ion-toolbar color="marine">

    <div>
      <ion-searchbar #searchBar [(ngModel)]="autocomplete.query" (ionChange)="updateSearch()" class="SC-searchbar"
        placeholder="Enter address"></ion-searchbar>
    </div>

  </ion-toolbar>
</ion-header>


<ion-content class="ion-no-padding ion-padding-bottom ion-padding-horizontal">

  <div class="placeholder" *ngIf="!autocomplete.query">
    <div class="new-client-room">
      <ion-icon name='map'></ion-icon>
      <h3>Please select your address.</h3>
      <p (click)="getLocation()">Use my location</p>
    </div>
  </div>

  <ion-list>
    <ion-item *ngFor="let item of autocompleteItems" tappable (click)="chooseItem(item)" class="ion-text-wrap">
      <ion-label>
        {{ item.description }}
      </ion-label>
    </ion-item>
  </ion-list>

</ion-content>