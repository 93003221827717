import * as Parse from 'parse';
import { Constants } from "../models/constants";

export class ParseInitService {

  static initParse() {
    if (Constants.DEV_MODE) {
      // dev
      const jsKey = Constants.DEV_PARSE_JS_KEY;
      const serverURL = Constants.DEV_PARSE_SERVER;
      (Parse as any).serverURL = serverURL;
      Parse.initialize(jsKey);
    } else {
      // prod
      const jsKey = Constants.PARSE_JS_KEY;
      const serverURL = Constants.PARSE_SERVER;
      (Parse as any).serverURL = serverURL;
      Parse.initialize(jsKey);
    }
  }

}
