<div class="room-appt-info-container" *ngIf="hasRoomApptInfo()">
  <div class="room-appt-info" [ngClass]="room.roomApptInfo.confirmed ? 'confirmed-appt-info' : 'pending-appt-info'" (click)="clickRoomApptInfo()">
    <span *ngIf="!room.roomApptInfo.confirmed && room.roomApptInfo.countdownTimer">
      <ion-icon name="alarm"></ion-icon> ({{room.roomApptInfo.countdownTimer.countdownString}})
    </span>
    {{room.roomApptInfo.message}}
    <ion-icon slot="end" name="chevron-forward-outline" *ngIf="!room.roomApptInfo.confirmed"></ion-icon>
  </div>
</div>


<ion-content class="ion-content" #chat_content (click)="tapContent()">

  <div class="placeholder" *ngIf="room && !messages && !errorMsg">
    <ion-spinner></ion-spinner>
  </div>

  <div class="placeholder" *ngIf="room && messages && !messages.length && !errorMsg">

    <div *ngIf="room.type === 'client-barber'" class="new-client-room">
      <span *ngIf="userService.isBusinessAccount()">
        <img class="right-img-closer" [src]="room.client.img || placeholderImg"/>
				<img class="left-img-closer" [src]="room.barber.pic || placeholderImg"/>
        <h3>Start a chat with {{room.client.firstName}} and {{room.barber.firstName}} to book an appointment.</h3>
      </span>
      <span *ngIf="!userService.isBusinessAccount()">
        <div *ngIf="room.barber.businessID">
          <img class="right-img" src="assets/img/shortcut-icon.png"/>
          <img class="center-img" [src]="room.barber.businessPic || placeholderImg"/>
          <img class="left-img" [src]="room.barber.pic || placeholderImg"/>
          <h3>Start a chat with {{room.barber.firstName}}, {{room.barber.businessName}} and Shortcut staff to book an appointment.</h3>
        </div>
        <div *ngIf="!room.barber.businessID">
          <img class="right-img-closer" src="assets/img/shortcut-icon.png"/>
          <img class="left-img-closer" [src]="room.barber.pic || placeholderImg"/>
          <h3>Start a chat with {{room.barber.firstName}} and Shortcut staff to book an appointment.</h3>
        </div>
      </span>
    </div>
    <div *ngIf="room.type == 'client-admin' || room.type == 'business-admin'" class="new-client-room">
      <img class="center-img" src="assets/img/shortcut-icon.png"/>
      <h3>Have a question? Our staff will respond as soon as we can.</h3>
    </div>
    <div *ngIf="room.type == 'client-concierge'" class="new-client-room">
      <img class="center-img" src="assets/img/concierge_bell.png"/>
      <h3>We’ll hook you up with the right Pro.</h3>
    </div>
    <div *ngIf="room.type == 'business-barber'" class="new-client-room">
      <img class="center-img" [src]="room.barber.pic || placeholderImg"/>
      <h3>Start a new chat with {{room.barber.firstName}}. <br>Tag @shortcut for help.</h3>
    </div>
    <div *ngIf="room.type == 'business-client'" class="new-client-room">
      <img class="center-img" [src]="room.client.img || placeholderImg"/>
      <h3>Start a new chat with {{room.client.firstName}}. <br>Tag @shortcut for help.</h3>
    </div>
  </div>

  <ion-refresher slot="fixed" (ionRefresh)="pullMessages($event)" *ngIf="room && messages && messages.length">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div *ngIf="(messages && messages.length)" class="message-wrap" [ngStyle]="{'padding-top': hasRoomApptInfo() ? '36px' : '0px'}">

    <div *ngFor="let t of timestampedMessages">

      <div class="timestamp">
        {{t.timestamp}} {{t.messages.length ? prettyTime(t.messages[0].createdAt) : ''}}
      </div>

      <div *ngFor="let m of t.messages" class="message" [class.left]="m.sender.id != activeSenderID" [class.right]="m.sender.id == activeSenderID">
        <div *ngIf="m.sender.id !== activeSenderID">
          <img class="user-img" [title]="m.sender.fullName" *ngIf="(m.msgType === 'text' || m.msgType === 'photo')" [src]="m.sender.userImg || placeholderImg"/>
          <img class="mini-logo-img" *ngIf="(m.msgType === 'text' || m.msgType === 'photo') && m.sender.miniLogoImg" [src]="m.sender.miniLogoImg"/>
        </div>
        <div class="msg-detail">
          <div class="msg-info" *ngIf="room && room.showNames && m.sender.id != activeSenderID">
            <p>{{m.sender.fullName}}</p>
          </div>
          <div class="msg-content-text" [title]="getMessageTooltip(m)" *ngIf="m.msgType == 'text'">
            <span class="line-breaker" (click)="showMsgOptions(m)"
            [innerHTML]="urlify(m.text || '(No Content)') | safe"></span>
          </div>
          <div class="msg-content-text msg-photo" *ngIf="m.msgType == 'photo'" (click)="openPhoto(m.photoURL)">
            <ion-icon class="photo-icon" item-left name="play"></ion-icon>
            <span>View photo</span>
          </div>
          <!-- APPT REQUEST -->
          <div class="msg-content-appt-request" *ngIf="m.msgType == 'appt-request' && m.apptRequestInfo">
            <div class="msg-header" [ngClass]="m.apptRequestInfo.reserved ? 'reserved-header' : (isExpired(m.apptRequestInfo) ? 'expired-header' : '')">
              <img class="logo" src="assets/img/shortcut-icon.png"/>
              <p>
                {{pendingApptHeader(m.apptRequestInfo)}}
              </p>
            </div>
            <div *ngIf="m.apptRequestInfo.id == latestApptRequestID" class="details">
              <div class="barber-img">
                <img [src]="room ? room.barber.pic : placeholderImg">
              </div>
              <div class="services">
                {{getServiceLongText(m.apptRequestInfo.serviceTypeList)}}
              </div>
              <div class="detail-item" *ngIf="room.type === 'business-barber'">
                <h3>Client</h3>
                <p>{{m.apptRequestInfo.clientFullName}}</p>
                <hr>
              </div>
              <div class="detail-item">
                <h3>Schedule</h3>
                <p>{{prettyTimeAdjusted(m.apptRequestInfo.startTime, {offset: m.apptRequestInfo.timezoneOffset, abbr: undefined})}} {{dayMonthDateYear(m.apptRequestInfo.startTime, m.apptRequestInfo.timezoneOffset)}}</p>
              </div>
              <div *ngIf="m.apptRequestInfo.reserved || !isExpired(m.apptRequestInfo)">
                <hr>
                <div class="detail-item">
                  <h3>Address</h3>
                  <p>{{m.apptRequestInfo.address | address}}</p>
                </div>
                <hr>
                <div class="detail-item">
                  <h3>Price</h3>
                  <p>{{formatCost(m.apptRequestInfo.costs.finalCost, m.apptRequestInfo.costs.currency)}} total charge</p>
                </div>
              </div>
              <div class="action-items" *ngIf="getApptAction(m.apptRequestInfo)" (click)="performApptAction(m.apptRequestInfo)">
                {{getApptAction(m.apptRequestInfo)}}
                <ion-icon *ngIf="" name="chevron-forward-outline" color="green"></ion-icon>
              </div>
              <div class="action-items" *ngIf="m.apptRequestInfo.apptID && room && room.roomApptInfo && room.roomApptInfo.apptObj && m.apptRequestInfo.apptID == room.roomApptInfo.apptObj.id" (click)="viewApptDetails()">
                View Details
                <ion-icon name="chevron-forward-outline" color="green"></ion-icon>
              </div>
            </div>
            <div *ngIf="m.apptRequestInfo.id != latestApptRequestID" class="details">
              <div class="detail-item">
                <p>{{prettyTimeAdjusted(m.apptRequestInfo.startTime, {offset: m.apptRequestInfo.timezoneOffset, abbr: undefined})}} {{dayMonthDateYear(m.apptRequestInfo.startTime, m.apptRequestInfo.timezoneOffset)}}</p>
              </div>
            </div>
            <div class="msg-content-timer" *ngIf="isPendingApptRequest(m.apptRequestInfo)">
              <ion-icon name="alarm"></ion-icon> Expires in: {{m.apptRequestInfo.countdownTimer ? m.apptRequestInfo.countdownTimer.countdownString : ''}}
            </div>
          </div>
          <!-- RESERVED APPT -->
          <div class="msg-content-appt-request" *ngIf="m.msgType == 'reserved-appt' && m.reservedApptInfo">
            <div class="msg-header" [ngClass]="m.reservedApptInfo.expired ? 'expired-header' : ''">
              <img class="logo" src="assets/img/shortcut-icon.png"/>
              <p>
                {{reservedApptHeader(m.reservedApptInfo)}}
              </p>
            </div>
            <div class="details">
              <div class="barber-img">
                <img [src]="room ? room.barber.pic : placeholderImg">
              </div>
              <div class="services">
                {{getServiceLongText(m.reservedApptInfo.serviceTypeList)}}
              </div>
              <div class="detail-item" *ngIf="room.type === 'business-barber'">
                <h3>Client</h3>
                <p>{{m.reservedApptInfo.clientFullName}}</p>
                <hr>
              </div>
              <div class="detail-item">
                <h3>Schedule</h3>
                <p>{{prettyTimeAdjusted(m.reservedApptInfo.startTime, {offset: m.reservedApptInfo.timezoneOffset, abbr: undefined})}} {{dayMonthDateYear(m.reservedApptInfo.startTime, m.reservedApptInfo.timezoneOffset)}}</p>
              </div>
              <div *ngIf="!m.reservedApptInfo.expired">
                <hr>
                <div class="detail-item">
                  <h3>Address</h3>
                  <p>{{m.reservedApptInfo.address | address}}</p>
                </div>
                <hr>
                <div class="detail-item">
                  <h3>Price</h3>
                  <p>{{formatCost(m.reservedApptInfo.costs.finalCost, m.reservedApptInfo.costs.currency)}} total charge</p>
                </div>
              </div>
              <div class="action-items" *ngIf="m.reservedApptInfo.id &&
              room.roomApptInfo && room.roomApptInfo.apptObj && m.reservedApptInfo.id == room.roomApptInfo.apptObj.id" (click)="viewApptDetails()">
                View Details
                <ion-icon name="chevron-forward-outline" color="green"></ion-icon>
              </div>
            </div>
          </div>
          <!-- INVOICE -->
          <div class="msg-content-invoice" *ngIf="m.msgType == 'invoice-request' && m.invoiceRequestInfo">
            <div class="msg-header" [ngClass]="m.invoiceRequestInfo.paid ? 'reserved-header' : (isExpired(m.invoiceRequestInfo) ? 'expired-header' : '')">
              <img class="logo" src="assets/img/shortcut-icon.png"/>
              <p>
                {{m.invoiceRequestInfo.paid ? 'Paid' : (isExpired(m.invoiceRequestInfo) ? 'Expired' : 'Pending')}} Invoice
              </p>
            </div>
            <div *ngIf="m.invoiceRequestInfo.id == latestInvoiceID" class="details">
              <div class="barber-img">
                <img [src]="room ? room.barber.pic : placeholderImg">
              </div>
              <div class="services">
                {{getServiceLongText(m.invoiceRequestInfo.serviceTypeList)}}
              </div>
              <div class="detail-item" *ngIf="room.type === 'business-barber'">
                <h3>Client</h3>
                <p>{{m.invoiceRequestInfo.clientFullName}}</p>
                <hr>
              </div>
              <div class="detail-item">
                <h3>Schedule</h3>
                <p>{{dayMonthDateYear(m.invoiceRequestInfo.startTime)}}</p>
              </div>
              <div *ngIf="m.invoiceRequestInfo.paid || !isExpired(m.invoiceRequestInfo)">
                <hr>
                <div class="detail-item">
                  <h3>Address</h3>
                  <p>{{m.invoiceRequestInfo.address | address}}</p>
                </div>
                <hr>
                <div class="detail-item">
                  <h3>Price</h3>
                  <p>{{formatCost(m.invoiceRequestInfo.costs.finalCost, m.invoiceRequestInfo.costs.currency)}} total charge</p>
                </div>
              </div>
              <div class="action-items" *ngIf="getInvoiceAction(m.invoiceRequestInfo)" (click)="performInvoiceAction(m.invoiceRequestInfo)">
                <ion-icon name="checkmark" color="green"></ion-icon>
                {{getInvoiceAction(m.invoiceRequestInfo)}}
              </div>
            </div>
            <div *ngIf="m.invoiceRequestInfo.id != latestInvoiceID" class="details">
              <div class="detail-item">
                <p>{{dayMonthDateYear(m.invoiceRequestInfo.startTime)}}</p>
              </div>
            </div>
            <div class="msg-content-timer" *ngIf="isPendingInvoice(m.invoiceRequestInfo)">
              <ion-icon name="alarm"></ion-icon> Expires in: {{m.invoiceRequestInfo.countdownTimer ? m.invoiceRequestInfo.countdownTimer.countdownString : ''}}
            </div>
          </div>
          <!-- PRODUCT SALE REQUEST -->
          <div class="msg-content-appt-request" *ngIf="m.msgType == 'product-sale-request' && m.productSaleRequestInfo">
            <div class="msg-header"
              [ngClass]="m.productSaleRequestInfo.apptCancelled || isExpired(m.productSaleRequestInfo) ? 'expired-header' : ''">
              <img class="logo" src="assets/img/icon_shortcut-logo.png" />
              <p>
                {{productSaleHeader(m.productSaleRequestInfo)}}
              </p>
            </div>
            <div class="details">
              <div class="product-info">
                <div class="product-quantity">
                  {{getProductQuantityText(m.productSaleRequestInfo.productQuantityList)}}
                </div>
                <div *ngFor="let productQuantity of m.productSaleRequestInfo.productQuantityList" class="product-img">
                  <img [src]="productImage(m.productSaleRequestInfo, productQuantity.id)">
                </div>
              </div>
              <div *ngIf="m.productSaleRequestInfo.purchased || !isExpired(m.productSaleRequestInfo)">
                <hr>
                <div class="detail-item">
                  <h3>Delivery</h3>
                  <p>{{m.productSaleRequestInfo.address | address}}</p>
                </div>
                <hr>
                <div class="detail-item">
                  <h3>Price</h3>
                  <p>{{formatCost(m.productSaleRequestInfo.costs.subtotal, m.productSaleRequestInfo.costs.currency)}} subtotal</p>
                </div>
              </div>
              <div *ngIf="!m.productSaleRequestInfo.purchased" class="action-items"
                (click)="performProductSaleAction(m.productSaleRequestInfo)">
                Pay now
                <ion-icon name="chevron-forward-outline" color="green"></ion-icon>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div *ngFor="let m of sendingMessages" class="message right sending">
      <div class="msg-detail">
        <div class="msg-content-text" *ngIf="m.msgType == 'text'">
          <p class="msg-inner-text" [innerHTML]="urlify(m.text || '(No Content)') | safe"></p>
        </div>
        <div class="msg-content-text msg-photo" *ngIf="m.msgType == 'photo'">
          <ion-icon class="photo-icon" item-left name="image"></ion-icon>
          <span>Sending photo...</span>
        </div>
      </div>
    </div>

  </div>

  <div class="error-note" *ngIf="errorMsg">Error: {{errorMsg}}</div>

</ion-content>
<ion-footer slot="fixed" class="ion-no-border" [style.height]="expandTextArea ? '220px' : '55px'">
  <div class="input-wrap">
    <ion-button *ngIf="canSendImage() && !expandTextArea" fill="clear" slot="icon-only" (click)="sendPhoto()">
      <ion-icon name="camera"></ion-icon>
    </ion-button>
    <ion-textarea
      (ionChange)="updateTextAreaModel()"
      #chat_input
      placeholder="Type a message here"
      [(ngModel)]="editor.msg"
      (keydown.enter)="onEnterKeydown($event)"
      (keyup.enter)="sendMsg()"
      (focusin)="onFocus()"
      (focusout)="onFocusOut()"
      (input)="adjustTextarea($event)"
      (click)="adjustTextarea($event)"
      spellcheck=“true”
      autocorrect=“on”
      autocomplete=“true”
      autocapitalize="on"
    ></ion-textarea>
    <ion-button slot="end" fill="clear" (click)="sendMsg()">
      <ion-icon name="send"></ion-icon>
    </ion-button>
  </div>
</ion-footer>