import { CloudCode } from './cloudcode';

import { Message, Room } from '../models/message'

export class MessageTasks {

  static currentRoomID: string = ''

  static pullMessages(
    roomID: string,
    batchBeforeDate: Date,
    successCallback: (messages: Message[]) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('pullMessages_Batches', 
      {
        batchBeforeDate: batchBeforeDate,
        roomID: roomID,
      },
      (messages: Message[]) => {
        successCallback(messages);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static markHasRead(
    messageID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('markHasRead', 
      {
        messageID: messageID
      },
      () => {
        successCallback();
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }
  
}