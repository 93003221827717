import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';

import { Barber } from '../../models/barber';
import { SelectedServiceTypeList } from '../../models/selected-service-type-list';

import { Cost } from '../../services/appt-creation-service';
import { ApptCreator } from "../../services/appt-creation-service";
import { Alert } from "../../services/alert";

@Component({
  selector: 'app-cost-breakdown',
  templateUrl: './cost-breakdown.page.html',
  styleUrls: ['./cost-breakdown.page.scss'],
})
export class CostBreakdownPage implements OnInit {

  cost: Cost;
  selectedServiceTypeList: SelectedServiceTypeList;
  promoCode?: string;

  constructor(
    private navCtrl: NavController,
    private apptCreator: ApptCreator,
    private alert: Alert,
    private router: Router,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
  }

}
