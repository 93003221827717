import { Component, OnInit, Input } from '@angular/core';

import { Util } from '../../services/util';
import { EventHistoryCosts } from '../../services/order-history-tasks';

@Component({
  selector: 'event-receipt',
  templateUrl: './event-receipt.component.html',
  styleUrls: ['./event-receipt.component.scss'],
})
export class EventReceiptComponent implements OnInit {

  @Input() costs: EventHistoryCosts;
	@Input() serviceTitle: string;
	@Input() cancelled: boolean;

  constructor() { }

  ngOnInit() {}

  formatCost(num: number) {
		return Util.formatCost(num, this.costs.currency)
	}

	formatPercentage(p: number) {
		return Util.formatPercentage(p)
	}

}
