import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '...', loadChildren: () => import('./pages/main-loading/main-loading.module').then(m => m.MainLoadingPageModule) },
  { path: 'welcome', loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialPageModule) },
  { path: 'pro/:barberLink', loadChildren: () => import('./pages/barber-profile/barber-profile.module').then(m => m.BarberProfilePageModule) },
  { path: 'appointments', loadChildren: () => import('./pages/upcoming-appt-list/upcoming-appt-list.module').then(m => m.UpcomingApptListPageModule) },
  { path: 'add-profile-pic', loadChildren: () => import('./pages/add-profile-pic/add-profile-pic.module').then(m => m.AddProfilePicPageModule) },
  { path: 'explore', loadChildren: () => import('./pages/main-screen/main-screen.module').then(m => m.MainScreenPageModule) },
  { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule) },
  { path: 'sign-up', loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'payment', loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule) },
  { path: 'refer-friend', loadChildren: () => import('./pages/refer-friend/refer-friend.module').then(m => m.ReferFriendPageModule) },
  { path: 'events', loadChildren: () => import('./pages/events/events.module').then(m => m.EventsPageModule) },
  { path: 'event/:mobileEventCode', loadChildren: () => import('./pages/event-series/event-series.module').then(m => m.EventSeriesPageModule) },
  { path: 'event-details/:eventID', loadChildren: () => import('./pages/event-main/event-main.module').then(m => m.EventMainPageModule) },
  { path: 'confirm-event/:mobileEventCode', loadChildren: () => import('./pages/confirm-event/confirm-event.module').then(m => m.ConfirmEventPageModule) },
  { path: 'empty-name-email', loadChildren: () => import('./pages/empty-name-email/empty-name-email.module').then(m => m.EmptyNameEmailPageModule) },
  { path: 'confirm-booking', loadChildren: () => import('./pages/confirm-booking/confirm-booking.module').then(m => m.ConfirmBookingPageModule) },
  { path: 'confirm-invoice', loadChildren: () => import('./pages/confirm-invoice/confirm-invoice.module').then(m => m.ConfirmInvoicePageModule) },
  { path: 'enter-phone-number', loadChildren: () => import('./pages/enter-phone-number/enter-phone-number.module').then(m => m.EnterPhoneNumberPageModule) },
  { path: 'enter-verify-code', loadChildren: () => import('./pages/enter-verify-code/enter-verify-code.module').then(m => m.EnterVerifyCodePageModule) },
  { path: 'add-payment', loadChildren: () => import('./pages/add-payment/add-payment.module').then(m => m.AddPaymentPageModule) },
  { path: 'setup-slack', loadChildren: () => import('./pages/setup-slack/setup-slack.module').then(m => m.SetupSlackPageModule) },
  // { path: 'gift-cards', loadChildren: './pages/gift-cards/gift-cards.module#GiftCardsPageModule' },
  // { path: 'confirm-gift-card', loadChildren: './pages/confirm-gift-card/confirm-gift-card.module#ConfirmGiftCardPageModule' },
  { path: 'team', loadChildren: () => import('./pages/all-pros/all-pros.module').then(m => m.AllProsPageModule) },
  { path: 'register-new-pro', loadChildren: () => import('./pages/register-new-pro/register-new-pro.module').then(m => m.RegisterNewProPageModule) },
  { path: 'register-concierge', loadChildren: () => import('./pages/register-concierge/register-concierge.module').then(m => m.RegisterConciergePageModule) },
  { path: 'product-sales', loadChildren: () => import('./pages/product-sales/product-sales.module').then(m => m.ProductSalesPageModule) },
  { path: 'add-business-product', loadChildren: () => import('./pages/add-business-product/add-business-product.module').then(m => m.AddBusinessProductPageModule) },
  { path: 'business', loadChildren: () => import('./pages/business/business.module').then(m => m.BusinessPageModule) },
  { path: 'locations', loadChildren: () => import('./pages/business-locations/business-locations.module').then(m => m.BusinessLocationsPageModule) },
  { path: 'analytics', loadChildren: () => import('./pages/business-analytics/business-analytics.module').then(m => m.BusinessAnalyticsPageModule) },
  { path: 'payments', loadChildren: () => import('./pages/business-payments/business-payments.module').then(m => m.BusinessPaymentsPageModule) },
  { path: 'business-change-login', loadChildren: () => import('./pages/business-change-login/business-change-login.module').then(m => m.BusinessChangeLoginPageModule) },
  {
    path: 'select-pro-type',
    loadChildren: () => import('./pages/select-pro-type/select-pro-type.module').then( m => m.SelectProTypePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
