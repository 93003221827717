import { Component, OnInit, Input } from '@angular/core';

import { Appointment, AppointmentStatus, AppointmentCosts, AppointmentCustomer } from '../../models/appointment';
import { SelectedServiceType } from '../../models/selected-service-type-list';

import { Util } from '../../services/util';
import { UserService } from '../../services/user-service';

export interface ReceiptData {
	cancelled: boolean
	serviceTypeList: SelectedServiceType[]
	costs: AppointmentCosts,
	customer: AppointmentCustomer
}

@Component({
  selector: 'receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {

  @Input() set appt(value: Appointment) {
		this.receiptData = {
			cancelled: value.status == AppointmentStatus.Cancelled || value.status == AppointmentStatus.CancelledAdmin,
			serviceTypeList: value.serviceTypeList,
			costs: value.costs,
			customer: value.customer
		}
  }
  
  receiptData: ReceiptData;

  constructor(
  	public userService: UserService) { }

  ngOnInit() {}

  formatCost(num: number) {
		return Util.formatCost(num, this.receiptData.costs.currency)
	}

	formatPercentage(p: number) {
		return Util.formatPercentage(p)
	}

}
