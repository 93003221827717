import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController, LoadingController, ModalController } from '@ionic/angular';

import { Constants } from "../../models/constants";

import { Alert } from "../../services/alert";
import { UserService } from "../../services/user-service";
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/services/observable-service';

@Component({
  selector: 'app-enter-phone-number',
  templateUrl: './enter-phone-number.page.html',
  styleUrls: ['./enter-phone-number.page.scss'],
})
export class EnterPhoneNumberPage implements OnInit {

  phone?: string;
  didRecentlySendNumber?: boolean;

  constructor(
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private alert: Alert,
    private userService: UserService,
    private observables: ObservableService,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  logout() {
    this.observables.publishLogOut()
    this.modalCtrl.dismiss()
  }

  async onSendCode() {
    if (this.phone) {
      let loading = await this.loadingCtrl.create();
      loading.present();
      this.userService.sendPhoneVerification(this.phone, async () => {
        loading.dismiss();
        if (this.didRecentlySendNumber) {
          this.alert.show("Success!", "Please wait 2-3 minutes to receive the verification text. We are experiencing high traffic.", "Ok", async () => {
            await this.modalCtrl.dismiss({ didRecentlySendNumber: true })
            this.observables.publishGoToEnterVerificationCode()
          }, undefined, undefined, false)
        } else {
          await this.modalCtrl.dismiss({ didRecentlySendNumber: true })
          this.observables.publishGoToEnterVerificationCode()
        }
      }, (errorMsg) => {
        loading.dismiss();
        this.alert.show('Error', errorMsg, 'Ok');
      });
    } else {
      this.alert.show('Error', 'Please enter a phone number.', 'Ok');
    }
  }

}
