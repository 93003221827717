import { Injectable, ApplicationRef } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class DeviceService {

  isMobile: boolean = false

  constructor(
		private ref: ApplicationRef,
	  	private platform: Platform) {
  	
  	this.getPlatform()
  }

  getPlatform() {
  	this.isMobile = (this.platform.width() <= 481)
  	this.ref.tick()
  }
}
