import { Injectable } from '@angular/core';
import { Address } from "./../models/address";
import { Barber } from "./../models/barber";
// import { MainScreenSelectedDay } from "../../models/main-screen-selected-day";
import { MainScreenSelectedWeek } from "./../models/main-screen-selected-week";
import { SelectedServiceTypeList, SelectedServiceType } from "./../models/selected-service-type-list";
import { AppointmentTime } from "./../models/appointment-time";
import { CloudCode } from "./cloudcode";
import { ApptService, ClientConfigResponse } from "./appt-service";
import { UserService, Customer } from "./user-service";
import { Storage } from '@ionic/storage';
import { Constants } from "./../models/constants";

export interface ApptCreationData {
  address: Address;
  mainScreenSelectedWeek_number: number;
  selectedServiceTypeListItems: SelectedServiceType[];
  apptTime_Date: Date;
  apptTime_Timezone: any;
  availID: string;
  barber: Barber;
  cost: Cost;
  coupon?: string;
  barberProfileNavHistory?: string;
  customAddressData: CustomAddressData;
}

export interface Cost {
  beforeCoupon: number;
  afterCoupon: number;
  creditAmt: number;
  taxAmt: number;
  promoAmt: number;
  afterCredit: number;
  finalCost: number;
  travelUpcharge?: number;
  convenienceFee?: number;
  currency: string
}

export interface CustomAddressData {
  address: Address
  timezoneOffset: number
  timezoneAbbreviation: string
}

@Injectable({ providedIn: 'root' })
export class ApptCreator {

  get mainScreenSelectedServiceTypeList(): SelectedServiceTypeList {
    return this._mainScreenSelectedServiceTypeList;
  }
  set mainScreenSelectedServiceTypeList(serviceTypeList: SelectedServiceTypeList) {
    this._mainScreenSelectedServiceTypeList = serviceTypeList;

    // Make sure the selectedServiceTypeList is always in sync with mainScreenServiceTypeList when it's updated, 
    //  but the barber profile will reference the selectedServiceTypeList, independent of the mainScreen one.

    // This ensures that the mainScreen list will not get updated (or persisted) anywhere besides the main screen.

    this.selectedServiceTypeList = serviceTypeList;
    this.persistSelectedServiceTypeList(serviceTypeList);
  }

  private _mainScreenSelectedServiceTypeList: SelectedServiceTypeList;
  
  address: Address;
  mainScreenSelectedWeek = new MainScreenSelectedWeek();
  selectedServiceTypeList: SelectedServiceTypeList;
  apptTime: AppointmentTime;
  availID?: string;
  barber?: Barber;
  cost?: Cost;
  coupon?: string;
  specialInstructions?: string;
  barberProfileNavHistory?: string;
  customAddressData?: CustomAddressData;
  apptRequestID?: string;
  type?: string;
  expiryDate?: Date; // For chat bookings, to set the timer.
  isReschedule?: boolean;

  homeProTypes: string[]
  homeProTypeDetails: any

  constructor(
    private storage: Storage,
    private userService: UserService) {
    this.resetApptDetails();
  }

  loadFromData(apptCreationData: ApptCreationData) {

    this.resetApptDetails()

    this.address = apptCreationData.address
    this.mainScreenSelectedWeek.weekNumber = apptCreationData.mainScreenSelectedWeek_number

    this.selectedServiceTypeList = new SelectedServiceTypeList()
    this.selectedServiceTypeList.items = apptCreationData.selectedServiceTypeListItems
    this.selectedServiceTypeList.refreshText()
    
    this.apptTime.date = apptCreationData.apptTime_Date
    this.apptTime.timezone = apptCreationData.apptTime_Timezone
    this.availID = apptCreationData.availID
    this.barber = apptCreationData.barber
    this.cost = apptCreationData.cost
    this.coupon = apptCreationData.coupon
    this.barberProfileNavHistory = apptCreationData.barberProfileNavHistory
    this.customAddressData = apptCreationData.customAddressData
  }

  resetApptDetails() {

    let blankAddress = {
      street: '',
      city: '',
      state: '',
      zip: '',
      coordinates: {
        latitude: 0,
        longitude: 0
      }
    };

    this.address = blankAddress;

    this.mainScreenSelectedServiceTypeList = new SelectedServiceTypeList();
    // this.storage.get(Constants.SAVED_SERVICE_TYPE_LIST).then((val) => {
    //   if (val) {
    //     this.mainScreenSelectedServiceTypeList.items = val;
    //     this.mainScreenSelectedServiceTypeList.refreshText();
    //   }
    // });
    this.apptTime = new AppointmentTime();
  }

  private persistSelectedServiceTypeList(serviceTypeList: SelectedServiceTypeList) {
    this.storage.set(Constants.SAVED_SERVICE_TYPE_LIST, serviceTypeList.items);
  }

  requestApptTime(
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      serviceTypeList: this.selectedServiceTypeList.items,
      address: this.address,
      startTime: this.apptTime.date,
      barberID: this.barber.id,
      device: this.userService.getDevice(),
      barberProfileNavHistory: this.barberProfileNavHistory,
      includeOutOfRadiusAvails: true,
    }

    CloudCode.run('requestAppt_v4', params, 
      (rsp: any) => {
        this.calculateCost((cost, serviceTypeList) => {
          this.availID = rsp.id;
          this.selectedServiceTypeList.items = serviceTypeList;
          this.cost = cost;
          if (rsp.useCustomAddress) {
            this.customAddressData = {
              address: rsp.address,
              timezoneAbbreviation: rsp.timezoneAbbreviation,
              timezoneOffset: rsp.timezoneOffset
            }
          } else {
            this.customAddressData = null
          }
          successCallback();
        }, (errorMsg) => {
          errorCallback(errorMsg);
        });
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  calculateCost(
    successCallback: (cost: Cost, serviceTypeList: SelectedServiceType[]) => void,
    errorCallback: (msg: string) => void
  ) {

    if (this.apptRequestID) {
      this.calculateCost_ApptRequest(successCallback, errorCallback)
      return
    }

    let address = this.address
    if (this.customAddressData) {
      address = this.customAddressData.address
    }

    let params: any = {
      serviceTypeList: this.selectedServiceTypeList.items,
      v3: true,
      apptDate: this.apptTime.date,
      barberID: this.barber.id,
      address: address
    }

    if (this.coupon) {
      params.coupon = this.coupon;
    }
    if (this.availID) {
      params.availID = this.availID
    }

    CloudCode.run('calculateCost', params, 
      (rsp: any) => {

        this.handleCostSuccess(rsp, successCallback)
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  private calculateCost_ApptRequest(
    successCallback: (cost: Cost, serviceTypeList: SelectedServiceType[]) => void,
    errorCallback: (msg: string) => void
  ) {

    let params: any = {
      apptRequestID: this.apptRequestID,
    }

    if (this.coupon) {
      params.coupon = this.coupon;
    }

    CloudCode.run('calculateCost_ApptRequest', params, 
      (rsp: any) => {
        
        this.handleCostSuccess(rsp, successCallback)
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  private handleCostSuccess(
    rsp: any, 
    successCallback: (cost: Cost, serviceTypeList: SelectedServiceType[]) => void,) {

    let cost: Cost = rsp
    let selectedServiceTypes: SelectedServiceType[] = rsp.serviceTypeList;

    successCallback(cost, selectedServiceTypes);
  }

  rejectAppt() {

    let params: any = {
      availID: this.availID,
      type: 'v3',
      searchTime: this.apptTime.date,
      barberProfileNavHistory: this.barberProfileNavHistory
    }

    if (this.coupon) {
      params.promo = this.coupon;
    }

    CloudCode.run('rejectAppt', params, 
      (rsp: any) => {
        console.log('Rejection success.');
      }, 
      (msg: string) => {
        console.log(`Rejection failed: ${msg}`);
      });
  }

  reserveAppt(
    customer: Customer,
    conciergeClientFullName: string,
    conciergeClientEmail: string,
    conciergeClientPhone: string,
    successCallback: (apptId: string) => void,
    errorCallback: (msg: string) => void
  ) {

    let address = this.address
    let timezoneOffset = this.apptTime.timezone.offset
    let timezoneAbbr = this.apptTime.timezone.abbr
    if (this.customAddressData) {
      address = this.customAddressData.address
      timezoneOffset = this.customAddressData.timezoneOffset
      timezoneAbbr = this.customAddressData.timezoneAbbreviation
    }

    let params: any = {
      serviceTypeList: this.selectedServiceTypeList.items,
      startTime: this.apptTime.date,
      availID: this.availID,
      timezoneOffset: timezoneOffset,
      timezoneAbbreviation: timezoneAbbr,
      address: address,
      customerID: customer.id,
      customer: customer, // Only for Business Concierge bookings.
      type: this.type,
      device: this.userService.getDevice(),
      barberProfileNavHistory: this.barberProfileNavHistory,
    }

    if (this.specialInstructions) {
      params.specialInstructions = this.specialInstructions;
    }

    if (this.coupon) {
      params.coupon = this.coupon;
    }

    if (conciergeClientFullName) {
      params.conciergeClientFullName = conciergeClientFullName
      params.conciergeClientEmail = conciergeClientEmail
      params.conciergeClientPhone = conciergeClientPhone
    }

    CloudCode.run('reserveAppt', params, 
      (rsp: any) => {
        if (rsp.id) {
          this.userService.fetch(() => {})
          successCallback(rsp.id);
        } else {
          errorCallback('Something went wrong.');
        }
      }, 
      (msg: string) => {
        // Error from server. Fetch appointments to see if appointment creation succeeded anyway.
        ApptService.pullClientConfig((clientConfig: ClientConfigResponse) => {

          let upcomingAppts = clientConfig.upcomingAppts

          for (var i = 0; i < upcomingAppts.length; i++) {
            let a = upcomingAppts[i]
            if (a.startTime.getTime() == this.apptTime.date.getTime()) {
              // If the server hit an error, but the client has an appt at this same time,
              //  then it is 99% likely it was successfully reserved.
              this.userService.fetch(() => {})
              successCallback(a.id)
              return
            }
          }

          errorCallback(msg)

        }, (msg: string) => {
          errorCallback(msg);
        })

      });
  }

  rescheduleAppt(
    paymentId: string,
    apptID: string,
    successCallback: (apptId: string) => void,
    errorCallback: (msg: string) => void
  ) {

    let params: any = {
      newStartTime: this.apptTime.date,
      availID: this.availID,
      timezoneOffset: this.apptTime.timezone.offset,
      timezoneAbbreviation: this.apptTime.timezone.abbr,
      customerID: paymentId,
      type: 'v3 reschedule',
      device: this.userService.getDevice(),
      barberProfileNavHistory: this.barberProfileNavHistory,
      apptID: apptID
    }

    if (this.specialInstructions) {
      params.specialInstructions = this.specialInstructions;
    }

    CloudCode.run('rescheduleAppt', params, 
      (rsp: any) => {
        if (rsp.id) {
          successCallback(rsp.id);
        } else {
          errorCallback('Something went wrong.');
        }
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  completeAppt(
    apptID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {

    let params: any = {
      apptID: apptID
    }

    CloudCode.run('completeAppt', params, 
      (rsp: any) => {
        successCallback();
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

}
