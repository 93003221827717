import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

import { SelectedServiceTypeList } from '../../models/selected-service-type-list';
import { ServiceType } from '../../models/service-type';
import { EventServiceOffering } from '../../models/public-event-info';

import { Util } from '../../services/util';
import { Alert } from '../../services/alert';
import { EventService } from '../../services/event-service';
import { HomeService } from '../../services/home-service';
import { PopupPage } from '../popup/popup.page';

export interface ServiceTab {
  title: string
}

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.page.html',
  styleUrls: ['./services-list.page.scss'],
})
export class ServicesListPage implements OnInit {

  title: string
  serviceNumbers = Util.toRange(5)
  serviceQuantities: any = {}
  totalNumServices: number = 0
  serviceTypeText: string
  showingMainServices = true
  isModal = true

  barberServiceIDs?: string[]
  barberPrices: object
  barberCurrency: string

  eventID?: string
  eventTabTitle?: string
  eventServiceOfferings?: EventServiceOffering[]
  eventCurrency: string

  tabs: ServiceTab[] = []
  selectedTab: number = 0

  private proType: string = 'Hair'

  private serviceTypes: ServiceType[]
  public visibleServiceTypes: ServiceType[]
  private selectedServiceTypeList: SelectedServiceTypeList

  constructor(
    private alert: Alert,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private eventService: EventService,
    private homeService: HomeService,
    private loadingCtrl: LoadingController,
    private router: Router,
    private changeRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {

    this.showMainServices(() => {
      this.updateServiceTypeText()
      this.clearServiceQuantities()
      this.changeRef.detectChanges()
    })
  }

  ngAfterViewInit() {
  }

  ionViewWillLeave() {
  }

  async onContinue() {

    if (this.eventID) {

      // Event service

      let selectedID: string;
      for (let idKey in this.serviceQuantities) {
        if (this.serviceQuantities[idKey] > 0) {
          selectedID = idKey
        }
      }

      if (!selectedID) {
        this.alert.show('Oops!', 'You must select a service to continue.')
        return
      }

      let selectedService: EventServiceOffering = this.eventServiceOfferings.filter(serviceOffering => {
        return serviceOffering.id === selectedID
      })[0]

      this.modalCtrl.dismiss({
        selectedService
      })

      return
    }


    // Home service

    let serviceIDs: string[] = []
    for (let id in this.serviceQuantities) {
      let quantity = this.serviceQuantities[id]
      if (quantity && quantity > 0) {
        serviceIDs.push(id)
      }
    }

    if (serviceIDs.length == 0) {
      this.alert.show('Oops', 'Please select at least 1 service to continue.')
      return
    }

    let loading = await this.loadingCtrl.create()
    loading.present()

    this.homeService.pullAddOnsForTheseHomeServices(serviceIDs, (serviceTypes: ServiceType[]) => {

      loading.dismiss()
      this.showingMainServices = false
      this.title = "Add ons"
      this.visibleServiceTypes = serviceTypes
      this.clearVisibleServiceQuantities() // Add add on id's to service quantities array
      this.changeRef.detectChanges()

    }, (errorMsg: string) => {
      loading.dismiss()
      this.alert.show('Oops', errorMsg)
    })

  }

  onSave() {
    this.modalCtrl.dismiss({
      newSelectedServiceList: this.createSelectedServiceTypeList()
    });
  }

  resetServices() {
    if (this.isModal) {
      this.modalCtrl.dismiss({});
    } else {
      this.navCtrl.pop()
    }
  }


  backButtonPressed() {

    if (this.eventID) {
      this.modalCtrl.dismiss()
      return
    }

    if (this.showingMainServices) {
      this.resetServices()
    } else {

      this.clearVisibleServiceQuantities() // Reset add on servies to 0
      this.updateServiceTypeText()

      this.showMainServices(() => {
        this.changeRef.detectChanges()
      })

    }
  }

  formatServiceCost(price: number, currency: string) {
    if (!price) {
      return '';
    }
    return Util.formatCost(price, currency);
  }

  async infoPressed(serviceType: ServiceType) {

    const data = {
      id: '0',
      header: serviceType.title,
      subHeader: '',
      subSubHeader: `This Pro offers ${serviceType.title.toLowerCase()} as a service. Please send a chat to learn more about options and pricing.`,
      steps: [],
    }

    let popupModal = await this.modalCtrl.create({
      component: PopupPage,
      componentProps: {
        showAcceptDecline: true,
        popup: data,
        acceptButtonTitle: 'Send chat'
      },
      cssClass: 'auto-height',
    })

    popupModal.onDidDismiss().then((val: any) => {
      if (val.data && val.data.accepted) {
        setTimeout(() => {
          this.modalCtrl.dismiss({
            goToChat: true
          })
        }, 50)
      }
    });

    popupModal.present();
  }

  updateServiceTypeText() {
    if (this.eventID) {
      this.serviceTypeText = ""
      return
    }

    this.selectedServiceTypeList = this.createSelectedServiceTypeList()

    if (this.selectedServiceTypeList.items.length == 0) {
      this.serviceTypeText = "None selected"
    } else {
      this.serviceTypeText = this.selectedServiceTypeList.text
    }
  }

  serviceIsOffered(serviceID: string) {
    return !this.barberServiceIDs || Util.contains(this.barberServiceIDs, serviceID)
  }

  incrementServiceCount(serviceType: ServiceType) {

    if (this.eventID) {
      this.clearServiceQuantities()
    }

    if (this.serviceQuantities[serviceType.id] == 4) {
      return
    }

    if (serviceType.addOnOnly && this.addOnServicesAtMax()) {
      this.alert.show('Sorry', 'You may only select one Add On per Hair Service.')
      return
    }

    this.serviceQuantities[serviceType.id]++
    this.totalNumServices++
    this.updateServiceTypeText()
    this.changeRef.detectChanges()

  }

  decrementServiceCount(serviceType: ServiceType) {

    if (this.serviceQuantities[serviceType.id] == 0) {
      return
    }

    this.serviceQuantities[serviceType.id]--
    this.totalNumServices--
    this.updateServiceTypeText()
    this.changeRef.detectChanges()
  }






  // Helpers

  setTabs(categories: string[]) {

    this.tabs = [{ title: 'All' }]

    for (var i = 0; i < categories.length; i++) {
      this.tabs.push({
        title: categories[i]
      })
    }
    if (this.tabs.length) {
      this.selectedTab = 0
    }
  }

  tabSelected(t: ServiceTab, index: number) {
    this.selectedTab = index
    this.visibleServiceTypes = this.selectedTab === 0 ? this.serviceTypes : this.serviceTypes.filter(serviceType => serviceType.category == t.title && !serviceType.addOnOnly)
    this.changeRef.detectChanges()
  }

  private async showMainServices(
    completionCallback: () => void
  ) {

    this.showingMainServices = true
    if (this.eventID) {

      this.title = 'Pick your service'

      let loading = await this.loadingCtrl.create()
      loading.present()

      this.eventService.pullServicesForEvent(this.eventID, (serviceTypes: ServiceType[]) => {

        loading.dismiss()
        this.serviceTypes = serviceTypes
        this.visibleServiceTypes = this.serviceTypes
        completionCallback()

      }, (errorMsg: string) => {
        loading.dismiss()
        this.alert.show('Oops', errorMsg)
        completionCallback()
      })

    } else {
      this.title = this.proType + " Services"

      let loading = await this.loadingCtrl.create()
      loading.present()

      this.homeService.pullActiveHomeServices_Categories(this.proType, (serviceTypes: ServiceType[], categories: string[]) => {
        loading.dismiss()

        this.setTabs(categories)
        this.serviceTypes = serviceTypes
        this.visibleServiceTypes = this.selectedTab === 0 ? this.serviceTypes : this.serviceTypes.filter(serviceType => serviceType.category == this.tabs[this.selectedTab].title && !serviceType.addOnOnly)
        completionCallback()
      }, (errorMsg: string) => {
        loading.dismiss()
        this.alert.show('Oops', errorMsg)
        completionCallback()
      })
    }

  }

  private clearServiceQuantities() {

    for (let serviceType of this.serviceTypes) {

      // Option 1: retain the previous service quantities..
      // let quantity = this.selectedServiceTypeList.quantityForId(serviceType.id)
      // this.serviceQuantities[serviceType.id] = quantity

      // Option 2: clear the service quantities
      this.serviceQuantities[serviceType.id] = 0
    }
  }

  private clearVisibleServiceQuantities() {

    for (let serviceType of this.visibleServiceTypes) {
      this.serviceQuantities[serviceType.id] = 0
    }

  }

  private createSelectedServiceTypeList() {

    var selectedServiceTypeList = new SelectedServiceTypeList()

    for (let id in this.serviceQuantities) {
      let quantity = this.serviceQuantities[id]
      let title = 'Service'
      let isChatOnlyService = false
      if (quantity > 0) {
        for (let serviceType of this.serviceTypes) {
          if (serviceType.id == id) {
            title = serviceType.title
            isChatOnlyService = serviceType.isChatOnlyService
          }
        }

        if (title == 'Service') {
          // Didn't find service type title in main services.
          // Loop through current add on services stored in visibleServiceTypes
          for (let serviceType of this.visibleServiceTypes) {
            if (serviceType.id == id) {
              title = serviceType.title
            }
          }
        }
      }
      selectedServiceTypeList.addNewItem(id, title, quantity, isChatOnlyService)
    }

    return selectedServiceTypeList;
  }

  private addOnServicesAtMax() {
    let services = this.serviceTypes

    let addOnServices = this.visibleServiceTypes.filter(serviceType => serviceType.addOnOnly);

    var mainServiceCount = 0
    var addOnCount = 0

    for (let mainService of services) {
      mainServiceCount += this.serviceQuantities[mainService.id]
    }
    for (let addOnService of addOnServices) {
      addOnCount += this.serviceQuantities[addOnService.id]
    }

    return addOnCount == mainServiceCount;
  }

}
