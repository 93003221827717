import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '../services/util';


@Pipe({
    name: 'numberDate',
})
export class NumberDatePipe implements PipeTransform {
    transform(value: Date) {
        return Util.mmddyyDate(value);
    }
}