<ion-content>

	<div class="logo">
		<img src="assets/img/shortcut-icon.png" alt="Shortcut">
	</div>

	<p class="SCWEB-header-text">Create your <br>Shortcut account</p>

	<p id="description-text">
		Already have an account?
		<span id="signup-text-button" (click)="gotoLogin()">Sign In</span>
	</p>

	<form #signupForm="ngForm" id="sign-up-form" novalidate>
		<ion-list>
			<ion-item lines="none" class="name-item">
				<ion-input id="full-name-input" [(ngModel)]="signupFields.fullName" name="fullName" type="text" #fullName="ngModel"
				 spellcheck="false" autocapitalize="true" required placeholder="Full name">
				</ion-input>
			</ion-item>

			<ion-item lines="none" class="email-item">
				<ion-input [(ngModel)]="signupFields.email" name="email" type="email" #email="ngModel" spellcheck="false"
				 autocapitalize="off" required placeholder="Email">
				</ion-input>
			</ion-item>

			<ion-item lines="none" class="password-item">
				<ion-input [(ngModel)]="signupFields.password" name="password" type="password" #password="ngModel" required placeholder="Password" (keyup.enter)="onSignup(signupForm)">
				</ion-input>
			</ion-item>
		</ion-list>

		<ion-button class="SC-long-button" color="marine" (click)="onSignup(signupForm)" type="submit" block>
			Sign up
		</ion-button>

		<hr class="SC-or-horizontal-rule">
		
		<ion-button class="SC-long-button" color="facebook" (click)="onSignup_Facebook()" block>
			<div class="auth-button-content">
				<ion-img src="assets/img/facebook.png" class="auth-button-icon"></ion-img>
				<span class="auth-button-text">Sign in with Facebook</span>
			</div>
		</ion-button>

		<ion-button class="SC-long-button" color="white" id="google" block>
			<div class="auth-button-content">
				<ion-img src="assets/img/google.png" class="auth-button-icon"></ion-img>
				<span class="auth-button-text">Sign in with Google</span>
			</div>
		</ion-button>

		<ion-row class="SC-bottom-button-row">
			<p class="SC-light-bottom-button">View our <span (click)="gotoTerms()">terms of service</span> and <span (click)="gotoPrivacy()">privacy policy</span>.</p>
		</ion-row>

	</form>
</ion-content>
