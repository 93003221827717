import { Component, OnInit, Input } from '@angular/core';

import { SelectedServiceTypeList } from '../../models/selected-service-type-list';

import { Util } from '../../services/util';
import { Cost } from '../../services/appt-creation-service';

@Component({
	selector: 'cost-breakdown',
	templateUrl: './cost-breakdown.component.html',
	styleUrls: ['./cost-breakdown.component.scss'],
})
export class CostBreakdownComponent implements OnInit {

	@Input() cost: Cost;
	@Input() selectedServiceTypeList: SelectedServiceTypeList;
	@Input() promoCode?: string;

	constructor() { }

	formatCost(num: number) {
		return Util.formatCost(num, this.cost.currency)
	}

	ngOnInit() {
		console.log(this.cost)
	}

}
