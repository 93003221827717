import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavController, LoadingController, ModalController } from '@ionic/angular';

import { UserService } from './../../services/user-service';
import { Constants } from "./../../models/constants";
import { Alert } from "./../../services/alert";
import { AnalyticsService } from './../../services/analytics-service';

import { ObservableService } from 'src/app/services/observable-service';

declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  preventMainRefresh?: boolean
  userParamsUpdateOnLogin?: Object

  errorStatus?: string
  loginFields: { email?: string, password?: string } = {};

  googleAuth: any;

  constructor(
    private observables: ObservableService,
    private navCtrl: NavController,
    public userService: UserService,
    private alert: Alert,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService,
    private changeRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
  ) {}

  openPage(url: string) {
    window.open(url, "_blank");
  }

  ngOnInit() {
    console.log(this.userParamsUpdateOnLogin)
  }

  ngAfterViewInit() {
    this.googleInit();
  }

  googleInit() {
    gapi.load('auth2', () => {
      this.googleAuth = gapi.auth2.init({
        client_id: '358390183023-vl348t7t2mb0mmn23krpdhnts9lmjv41.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachGoogleSignIn(document.getElementById('google'));
    });
  }

  attachGoogleSignIn(element: any) {
    this.googleAuth.attachClickHandler(element, {},
      async (googleUser) => {

        let profile = googleUser.getBasicProfile();

        const idToken = googleUser.getAuthResponse().id_token
        const id = profile.getId()
        const firstName = profile.getGivenName()
        const lastName = profile.getFamilyName()
        const email = profile.getEmail()

        let loading = await this.loadingCtrl.create();
        loading.present();

        this.userService.signInWithGoogle(
          loading,
          idToken,
          id,
          firstName,
          lastName,
          email,
          (userExisted: boolean) => {
            if (!userExisted) {
              this.analytics.track(AnalyticsService.SignedUp)
              this.analytics.track("SignedUp_Login")
            }
            this.analytics.setUserID(this.userService.user.id)
            loading.dismiss();
            this.loginSuccess()
          },
          (msg: string) => {
            loading.dismiss();
            if (msg === Constants.NO_EMAIL_OR_NAME_KEY) {
              this.goToEmptyNameEmailScreen();
            } else {
              this.alert.show('Error', msg, 'Ok');
            }
          }
        )

      }, (error) => {
        console.log(error)
        this.alert.show('Oops', 'Something went wrong. Please try again')
      });
  }

  onLogin(form: NgForm) {

    if (!form.valid) {
      this.loginError("Invalid entries.");
      return
    }

    this.login_Username(this.loginFields.email.toLowerCase().trim(), this.loginFields.password);
  }

  onLogin_Facebook() {
    this.login_Facebook();
  }

  async gotoSignup() {
    await this.modalCtrl.dismiss()
    const preventMainRefresh = false
    const source = ''
    this.observables.publishGoToSignUp(preventMainRefresh, source)
  }

  onForgotPassword() {
    if (this.loginFields.email) {
      this.userService.resetPassword(this.loginFields.email, () => {
        this.alert.show('Password Reset Sent', "You'll receive an email shortly where you can reset your password.")
      }, (errorMsg) => {
        this.alert.show('Error', 'There was an issue with sending your reset instructions. Please ensure the email you entered is correct.');
      });
    } else {
      this.alert.show('Forgot Password', 'Please enter an email and press \'Forgot Password?\' for reset instructions.')
    }
  }

  gotoTerms() {
    this.openPage('https://getshortcut.co/terms')
  }

  gotoPrivacy() {
    this.openPage('https://getshortcut.co/privacy')
  }

  closePressed() {
    this.navCtrl.pop()
  }

  //
  // -- Private Methods --
  //

  private async login_Username(
    username: string,
    password: string) {

    let loading = await this.loadingCtrl.create();
    loading.present();

    this.userService.login_Username(
      username,
      password,
      () => {

        this.analytics.setUserID(this.userService.user.id)

        loading.dismiss()
        this.loginSuccess()
      },
      (msg: string) => {
        loading.dismiss()
        this.loginError(msg)
        this.changeRef.detectChanges();
      });
  }

  private async login_Facebook() {

    let loading = await this.loadingCtrl.create();
    loading.present();
    this.userService.login_Facebook(
      (userExisted: boolean) => {
        if (!userExisted) {
          this.analytics.track(AnalyticsService.SignedUp)
          this.analytics.track("SignedUp_Login")
        }
        this.analytics.setUserID(this.userService.user.id)
        loading.dismiss();
        this.loginSuccess()
      },
      (msg: string) => {
        loading.dismiss();
        if (msg === Constants.NO_EMAIL_OR_NAME_KEY) {
          this.goToEmptyNameEmailScreen();
        } else if (msg === Constants.WELCOME_EMAIL_FAILED) {
          this.alert.show('Email Failed', 'Please enter your email again.', 'Ok', () => {
            this.goToEmptyNameEmailScreen();
          });
        } else if (msg === Constants.USER_SIGNED_UP) {
          this.observables.publishRefreshMainView()
        } else {
          this.alert.show('Error', msg, 'Ok');
        }
      });
  }

  private loginSuccess() {

    this.userService.update(this.userParamsUpdateOnLogin, async () => {

      this.observables.publishRegisterPush()
      this.observables.publishRefreshSideMenu()

      await this.modalCtrl.dismiss()

      if (!this.preventMainRefresh) {
        this.observables.publishRefreshMainView()
      }

    }, (errMsg: string) => {console.error("could not update")})

  }

  private loginError(msg: string) {
    this.errorStatus = msg;
  }

  private async goToEmptyNameEmailScreen() {
    await this.modalCtrl.dismiss()
    this.observables.publishGoToEmptyNameEmail()
  }

}
