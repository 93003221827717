import { Subscription, timer } from "rxjs";


export interface CountdownTimerDelegate {
    countdownEnded(data?: any): void;
    updateTimer(): void;
}

export class CountdownTimer {

    countdownString: string;
    running: boolean = false;
    startingMin: number;
    startingSec: number;
    secLeft: number;
    minLeft: number;
    endTime: number; // In seconds from 1970
    delegate?: CountdownTimerDelegate;
    timer = timer(0, 1000);
    subscription: Subscription;
    data?: any;

    constructor(startingMin: number, startingSec: number, data?: any) {
        this.data = data
        this.startingMin = startingMin;
        this.startingSec = startingSec;
        this.minLeft = startingMin;
        this.secLeft = startingSec;
        this.refreshCountdown();
    };

    static getTimerMinSec(expiryDate: Date): any {
        const
          nowSeconds = Math.round((new Date()).getTime() / 1000),
          expirySeconds = Math.round(expiryDate.getTime() / 1000),
          secondsDifference = expirySeconds - nowSeconds,
          minLeft = Math.floor(secondsDifference / 60),
          secLeft = Math.round(secondsDifference % 60);

        return {
            minLeft: minLeft,
            secLeft: secLeft
        }
    }

    refreshCountdown() {
        let secString = this.secLeft < 10 ? `0${Math.floor(this.secLeft)}` : `${Math.floor(this.secLeft)}`;
        this.countdownString = `${this.minLeft > 60 ? Math.floor(this.minLeft / 60) + ':' : ''}${Math.floor(this.minLeft) % 60}:${secString}`;
        if (this.delegate) {
            this.delegate.updateTimer()
        }
    }

    start() {
        if (!this.running) {
            this.running = true;
            if (this.minLeft === this.startingMin) {
                if (this.secLeft === 0) {
                    this.minLeft = this.startingMin - 1;
                    this.secLeft = 59;
                } else {
                    this.secLeft -= 1;
                }

                this.refreshCountdown();
            }

            this.endTime = ((new Date()).getTime() / 1000) + this.secLeft + (this.minLeft * 60);
            this.subscription = this.timer.subscribe(() => {
                this.updateTimeLeft();
            });
        }
    };

    pause() {
        this.running = false;
        this.subscription.unsubscribe();
    };

    reset() {
        this.pause();
        this.minLeft = this.startingMin;
        this.secLeft = this.startingSec;
        this.refreshCountdown();
    };

    updateTimeLeft() {
        let timeDiff = this.endTime - ((new Date()).getTime() / 1000);
        this.minLeft = timeDiff / 60;
        this.secLeft = timeDiff % 60;
        if (this.minLeft <= 0 && this.secLeft <= 0) {
            this.minLeft = 0;
            this.secLeft = 0;
            this.pause();
            if (this.delegate) {
                this.delegate.countdownEnded(this.data);
            }
        }
        this.refreshCountdown();
    };
}