<ion-content>
  <p class="SCWEB-header-text">Additional Tip</p>

  <div id="tip-container">
    <!-- Tip choices -->
    <ion-card *ngFor="let tipChoice of tipChoices" class="tip-card SC-clickable" (click)="tipChoicePressed(tipChoice)"
      [ngClass]="tipChoice.id == selectedTipChoice.id ? 'highlighted-tip-card' : ''">
      <ion-card-content>
        <ion-icon name="create" *ngIf="tipChoice.showIcon"></ion-icon>
        <p>{{ tipChoice.displayString }}</p>
      </ion-card-content>
    </ion-card>
  </div>

  <ion-button color="marine" (click)="sendTipPressed()" id="submit-button" class="SC-long-button">Send
    tip</ion-button>
</ion-content>