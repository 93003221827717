<!-- service charges -->
<div>
  <p>{{formatServicesLabel()}}</p>
  <p class="right">{{formatCost(costs.subtotal)}}</p>
</div>

<!-- tax -->
<p>Tax</p>
<p class="right">{{formatCost(costs.tax)}}</p>

<!-- convenience fee -->
<div *ngIf="costs.convenienceFee">
	<p>Convenience fee</p>
	<p class="right">{{formatCost(costs.convenienceFee)}}</p>
</div>

<hr>

<div *ngIf="costs.creditAmt">
  <p>Account credit</p>
  <p class="right">{{formatCost(costs.creditAmt)}}</p>
</div>

<!-- charge -->
<p>Total charge {{ refunded ? "(refunded)" : "" }}</p>
<p class="right">{{formatCost(costs.total)}}</p>