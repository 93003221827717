<div class="inner-content">
  <div class="logo">
    <img [src]="info.barberPic" alt="Shortcut">
  </div>
  <div *ngIf="info.type === 'appt-request' && info.isReschedule">
    <p class="title-text">{{ info.barberFirstName }} asked to reschedule.</p>
    <p class="description-text">Please confirm your new time.</p>
  </div>
  <div *ngIf="info.type === 'appt-request' && !info.isReschedule">
    <p class="title-text">{{ info.barberFirstName }} sent you an appointment!</p>
    <p class="description-text">Please confirm your booking for<br>{{ formatDate() }}.</p>
  </div>
  <div *ngIf="info.type === 'product-sale-request'">
    <p class="title-text">{{ info.barberFirstName }} sent you a product for purchase.</p>
  </div>
  <ion-row class="SC-bottom-button-row">
    <ion-col>
      <ion-button color="marine" class="SC-floating-button submit-button" (click)="openChatPressed()">Open chat</ion-button>
      <p class="decline-button" (click)="ignorePressed()">Ignore</p>
    </ion-col>
  </ion-row>
</div>