<ion-header class="ion-no-border">

	<div scrollX="true" class="tab-scroller" *ngIf="!eventID && showingMainServices">
		<ion-card class="tab-card" *ngFor="let tab of tabs; let i = index" (click)="tabSelected(tab, i)">
			<ion-card-content>
				<p class="tab-title" [ngClass]="i == selectedTab ? 'selected-tab' : ''">
					{{ tab.title }}
				</p>
			</ion-card-content>
		</ion-card>
		<div class="right-button">
			<ion-button slot="end" fill="clear" (click)="resetServices()">Reset</ion-button>
		</div>
	</div>

	<div *ngIf="!showingMainServices" class="tab-scroller">
		<ion-card class="tab-card">
			<ion-card-content>
				<p class="tab-title selected-tab">
					Add Ons
				</p>
			</ion-card-content>
		</ion-card>
	</div>

	<div *ngIf="eventTabTitle" class="tab-scroller">
		<ion-card class="tab-card">
			<ion-card-content>
				<p class="tab-title selected-tab">
					{{eventTabTitle}}
				</p>
			</ion-card-content>
		</ion-card>
	</div>
</ion-header>

<ion-content [ngClass]="showingMainServices ? 'showing-main inner-content' : 'inner-content'">
	<div *ngFor="let s of visibleServiceTypes; let i = index">

		<div class='service-container'>
			<div class="img-container">
				<ion-img [src]="s.mainGif" class="service-img"
					[ngClass]="serviceIsOffered(s.id) ? '' : 'disabled-service-img'"></ion-img>
			</div>
			<div class="title-container">
				<h2 class="service-title">{{ s.title }}
					<span class="service-price" *ngIf="barberPrices && barberPrices[s.id]">{{formatServiceCost(barberPrices[s.id].price, barberCurrency)}}</span>
					<span class="service-price" *ngIf="eventID && s.price">{{formatServiceCost(s.price, eventCurrency)}}</span>
				</h2>
				<p *ngIf="selectedTab === 0 && showingMainServices" class="service-category">{{s.category}}</p>
				<p class="service-description">{{ s.description }}</p>
			</div>
			<div class="buttons-container">
				<span *ngIf="serviceIsOffered(s.id) && !(s.isChatOnlyService && barberPrices)" class="service-buttons">
					<button color="marine" class="service-quantity-button subtract" (click)="decrementServiceCount(s)"
						[disabled]="serviceQuantities[s.id] == 0"
						[ngClass]="serviceQuantities[s.id] == 0 ? 'disabled-service-button' : ''">
						–
					</button>
					<button color="marine" class="service-quantity-button add" (click)="incrementServiceCount(s)"
						[disabled]="serviceQuantities[s.id] == 4"
						[ngClass]="serviceQuantities[s.id] > 0 ? 'selected-service-button' : serviceQuantities[s.id] == 4 ? 'disabled-service-button' : ''">
						<span *ngIf="serviceQuantities[s.id] > 0"
							style="font-size: 17px; font-weight: bold; color: white;">{{serviceQuantities[s.id]}}</span>
						<span *ngIf="serviceQuantities[s.id] === 0">+</span>
					</button>
				</span>
				<span *ngIf="serviceIsOffered(s.id) && s.isChatOnlyService && barberPrices">
					<div class="info-button" (click)="infoPressed(s)">
						<ion-icon name="information-circle-outline" color="marine"></ion-icon>
					</div>
				</span>
				<span *ngIf="!serviceIsOffered(s.id)">
					<p class="not-available-text">Unavailable by Pro</p>
				</span>
			</div>

		</div>

		<!-- hide the last one -->
		<hr [hidden]="i == visibleServiceTypes.length - 1">

	</div>

	<br>

	<p class="no-add-ons" *ngIf="showingMainServices && visibleServiceTypes && visibleServiceTypes.length == 0">No services available for your selection.</p>

	<p class="no-add-ons" *ngIf="!showingMainServices && visibleServiceTypes && visibleServiceTypes.length == 0">No add-ons available for your selection.</p>

</ion-content>

<ion-footer slot="fixed" class="SC-floating-buttons SC-floating-buttons-less-tall">

	<ion-button *ngIf="showingMainServices" color="marine" (click)="onContinue()"
		class="SC-long-button">
		Continue
	</ion-button>

	<ion-button *ngIf="!showingMainServices" color="marine" (click)="onSave()"
		class="SC-long-button">
		Finish
	</ion-button>
</ion-footer>