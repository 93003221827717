import { Injectable } from '@angular/core';
import { ToastController } from "@ionic/angular";

@Injectable({ providedIn: 'root' })
export class VibeToast {

  constructor(
    private toastCtrl: ToastController,
  ) {}

  async show(
    msg: string,
    dismissAction?: () => void
  ) {
    let toast = await this.toastCtrl.create({
      message: msg,
      position: 'bottom',
      buttons: [
        {
          text: 'Got it',
          role: 'cancel',
        }
      ],
      color: 'dark',
      duration: 3000
    });
    if (dismissAction) {
      toast.onDidDismiss().then(dismissAction);
    }
    toast.present();
  }

  async showPromoToast(code: string) {
    const msg = `Promo '${code}' will be applied at checkout.`

    let toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      buttons: [
        {
          text: 'Got it',
          role: 'cancel',
        }
      ],
      color: 'dark',
      duration: 3000
    });
    
    toast.present();
  }

  async toastWithTapAction(message: string, tapAction: () => void) {

    // DIfferent kind of toast so we can handle the tap.
    const toast = await this.toastCtrl.create({
      message,
      position: 'top',
      duration: 4200,
      buttons: [
        {
          side: 'end',
          text: 'Go',
          cssClass: 'toast-action-button',
          handler: () => {
            tapAction()
          }
        }
      ]
    })

    toast.present()

  }

}
