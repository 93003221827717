import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';

import { Barber } from "../../models/barber";

import { MoreReviewsTasks, BarberProfileReview } from "../../services/more-reviews-tasks";
import { Util } from "../../services/util";
import { Alert } from "../../services/alert";
import { Router } from '@angular/router';

@Component({
  selector: 'app-more-reviews',
  templateUrl: './more-reviews.page.html',
  styleUrls: ['./more-reviews.page.scss'],
})
export class MoreReviewsPage implements OnInit {

  placeholderImg = "assets/img/profile-placeholder.png";
  reviews?: BarberProfileReview[]

  barber: Barber

  constructor(
    private navCtrl: NavController,
    private alert: Alert,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    if (!this.barber) {
      this.close()
      return
    }
  }

  ngAfterViewInit() {
    this.pullReviews()
  }

  close() {
    this.modalCtrl.dismiss()
  }

  private pullReviews() {

    let curatedReviews = this.barber.curatedReviews || []
    let curatedReviewIDs = curatedReviews.map((review: BarberProfileReview) => { return review.reviewID } )

    MoreReviewsTasks.pullMoreReviews(
      this.barber.id,
      curatedReviewIDs,
      (reviews: BarberProfileReview[]) => {
        this.reviews = reviews
        this.changeRef.detectChanges()
      },
      (msg: string) => {

        if (!Util.isNavigationError(msg)) {
          this.error(msg);
        }

      })
  }

  private error(msg: string) {
    this.alert.show('Error', msg);
  }



  // Helpers

  mmddyyDate(date: Date) {
    return Util.mmddyyDate(new Date(date))
  }

  toRange(n: number) {
    return Util.toRange(n)
  }

}
