import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Globalization } from '@ionic-native/globalization/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Storage } from '@ionic/storage';

import { Constants } from "./../models/constants";
import { ImageData } from '../models/image-data';
import { Address } from "../models/address";

import { App } from './app';
import { Util } from './util';
import { CloudCode } from "./cloudcode";
import { ProfPicService } from './prof-pic-service';

import * as Parse from 'parse';
import { ObservableService } from './observable-service';
import { Alert } from './alert';
import { ChatManager } from './chat-manager';
declare var FB: any;

export interface Customer {
  id: string;
  cardBrand: string;
  last4?: string;
}

export interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  conciergeName?: string;
  email: string;
  profPicURL?: string;
  profPicThumbURL?: string;
  allCustomers: Customer[];
  customer?: Customer;
  business?: any;
  favoriteBarberIDs: string[];
  birthdate: Date;
  recentAppt: any;
  isoPhoneNumber?: string;
  nationalPhoneNumber?: string;
  referralCode?: string;
  address?: Address;
  id?: string;
  type?: string;
  adminChatRoomID?: string;
  conciergeChatRoomID?: string;
  branchParams?: any;
  currentProType?: string;
  isGuest: boolean;
  numCompletedAppts: number;
  numUpcomingAppts: number;
  hasCancelledAppt: boolean;
  eventSignUp: boolean;
  tempPhoneNumber?: string;
  lang?: string;
  credit?: number;
  userCreditCurrency: string;
  currency: string;
  approvedCovidGuidelinesDate?: Date;
}

export interface Business {
  id: string;
  name: string;
  shortName?: string;
  ownerFullName?: string;
  email: string;
  link: string;
  pic: string;
  nationalPhoneNumber: string;
  countryCode: string;
  currency: string;

  businessTotalCommission: number;
  businessCommissionMap?: any;
  businessProductCommissionMap?: any;
  numLocations?: number;

  enableProductConfiguration?: boolean;
  enableProChatProductSales?: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserService {

  user: User
  business?: Business

  isBusiness: boolean = false

  constructor(
    private profPicService: ProfPicService,
    private platform: Platform,
    private storage: Storage,
    private globalization: Globalization,
    private observables: ObservableService,
    private alert: Alert,
    private chatManager: ChatManager,
  ) {
  }

  unloadFromParse() {

    let u = Parse.User.current();

    let profPicURL = '', profPicThumbURL = '';
    if (u.get('profPic')) {
      profPicURL = u.get('profPic').url()
    }
    if (u.get('profPicThumbnail')) {
      profPicThumbURL = u.get('profPicThumbnail').url()
    }
    let credit;
    let userCredit = u.get('userCredit')
    if (userCredit) {
      credit = userCredit.get('credit')
    }

    this.user = {
      firstName: u.get('firstName'),
      lastName: u.get('lastName'),
      fullName: u.get('fullName'),
      conciergeName: u.get('conciergeName'),
      email: u.get('email'),
      profPicURL: profPicURL,
      profPicThumbURL: profPicThumbURL,
      allCustomers: u.get('allCustomers') || [],
      customer: u.get('customer'),
      business: u.get('business'),
      favoriteBarberIDs: u.get('favoriteBarbers') || [],
      birthdate: u.get('birthdate'),
      recentAppt: u.get('recentAppt'),
      isoPhoneNumber: u.get('isoPhoneNumber') || "",
      nationalPhoneNumber: u.get('nationalPhoneNumber') || "",
      referralCode: u.get('referralCode'),
      address: u.get('address'),
      id: u.id,
      type: u.get('type'),
      adminChatRoomID: u.get('adminChatRoomID'),
      conciergeChatRoomID: u.get('conciergeChatRoomID'),
      branchParams: u.get('branchParams'),
      currentProType: u.get('currentProType') || 'Hair',
      isGuest: u.get('isGuest') || false,
      numCompletedAppts: u.get('numCompletedAppts') || 0,
      numUpcomingAppts: u.get('numUpcomingAppts') || 0,
      hasCancelledAppt: u.get('hasCancelledAppt') || false,
      eventSignUp: u.get('eventSignUp') || false,
      tempPhoneNumber: u.get('tempPhoneNumber'),
      lang: u.get('lang'),
      credit: credit,
      approvedCovidGuidelinesDate: u.get('approvedCovidGuidelinesDate'),
      userCreditCurrency: u.get('userCreditCurrency'),
      currency: u.get('currency'),
    }

    this.isBusiness = u.get('type') == 'business'

    console.log('current user: ')
    console.log(this.user)
  }

  unloadBusiness(business: any) {

    if (!business) {
      return
    }

    this.business = {
      id: business.id,
      name: business.get('name'),
      shortName: business.get('shortName'),
      ownerFullName: business.get('ownerFullName'),
      email: business.get('email'),
      link: business.get('link'),
      pic: business.get('pic'),
      nationalPhoneNumber: business.get('nationalPhoneNumber'),
      countryCode: business.get('countryCode'),
      currency: business.get('currency'),

      businessTotalCommission: business.get('businessTotalCommission'),
      businessCommissionMap: business.get('businessCommissionMap'),
      businessProductCommissionMap: business.get('businessProductCommissionMap'),
      numLocations: business.get('numLocations'),

      enableProductConfiguration: business.get('enableProductConfiguration'),
      enableProChatProductSales: business.get('enableProChatProductSales'),
    }

    console.log('business settings:')
    console.log(this.business)
    this.observables.publishBusinessFetched()
  }

  isLoggedIn(): boolean {

    if (Parse.User.current()) {
      return true;
    }

    return false;
  }


  // -- CRUD methods --

  update(
    params: any,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    console.log('Updating user!')
    console.log(`Params: ${JSON.stringify(params)}`)

    if (Util.isEmptyObject(params)) {
      successCallback();
      return;
    }

    Parse.User.current().save(params).then(() => {
      this.unloadFromParse();
      successCallback();
    }).catch((e: any) => {
      errorCallback(e.message);
    })
  }

  fetch(
    successCallback: () => void,
    errorCallback?: (msg: string) => void
  ) {

    Parse.User.current().fetch().then(() => {

      let type = Parse.User.current().get('type');

      if (type === 'business' && Parse.User.current().get('business')) {

        this.isBusiness = true

        let businessID = (Parse.User as any).current().get('business').id

        let businessQuery = new (Parse.Query as any)('Business')
        businessQuery.get(businessID).then((business: any) => {
            this.unloadFromParse();
            this.unloadBusiness(business);
            successCallback();
          },
          (e: any) => {
            App.saveVibesError('Fetch business', {}, e.message);
            errorCallback(e.message);
          }
        );

      } else {

        let userCredit = Parse.User.current().get('userCredit')
        if (userCredit) {
          userCredit.fetch().then(() => {
            this.observables.publishUserFetched()
            this.unloadFromParse();
            successCallback();
          }).catch((e: any) => {
            if (errorCallback) {
              errorCallback(e.message);
            }
          })
        } else {
          this.observables.publishUserFetched()
          this.unloadFromParse();
          successCallback();
        }
      }

    }).catch((error: any) => {
      App.saveVibesError('Fetch user', {}, error.message);
      if (errorCallback) {
        errorCallback(error.message);
      }
    })
  }


  // -- Login and Signup methods --

  logout() {
    this.user = null;
    this.business = null;
    this.isBusiness = false;
    this.chatManager.logOut();
    Parse.User.logOut()
    this.storage.set(Constants.SAVED_MESSAGES, null)

    try {
      FB.logout()
    } catch (err) {
      console.error('Error logging out from FB')
      console.error(err)
    }
  }

  login_Username(
    username: string,
    password: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    Parse.User.logIn(username, password).then((user: any) => {

      const type = Parse.User.current().get('type')

      if (type !== 'business') {
        this.unloadFromParse()
        this.checkForBranchAttribution()
        successCallback();
        return;
      }

      this.isBusiness = true
      let businessID = (Parse.User as any).current().get('business').id

      let businessQuery = new (Parse.Query as any)('Business')
      businessQuery.get(businessID).then((business: any) => {
          this.unloadFromParse();
          this.unloadBusiness(business);
          successCallback();
        },
        (e: any) => {
          App.saveVibesError('Fetch business', {}, e.message);
          errorCallback(e.message);
        }
      );

    }).catch((e: any) => {
      errorCallback(e.message);
    })
  }

  resetPassword(
    email: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    Parse.User.requestPasswordReset(email).then(() => {
      successCallback()
    }).catch((error: any) => {
      errorCallback(error.message)
    })
  }

  ensureAccountExists(
    successCallback?: () => void,
    errorCallback?: (msg: string) => void) {

    if (this.isLoggedIn()) {
      successCallback()
      return
    }

    this.generateGuest(undefined, successCallback, errorCallback)
  }

  generateGuest(
    optUserParams?: Object,
    successCallback?: () => void,
    errorCallback?: (msg: string) => void
  ) {

    let guestUsername = this.randomString(18)
    let params = {
      isGuest: true,
      device: this.getDevice(),
      webUserAgent: this.getUserAgent(),
      platforms: this.platform.platforms(),
    }

    Parse.User.signUp(guestUsername, guestUsername, params).then(() => {
      this.unloadFromParse()
      this.checkForBranchAttribution()

      if (optUserParams) {
        this.update(optUserParams, () => {
          successCallback()
        }, (errMsg: string) => {
          successCallback()
        })
      } else {
        successCallback()
      }

      // this.globalization.getPreferredLanguage()
      //   .then(res => {
      //     this.update({ lang: res.value }, () => { }, (errMsg: string) => { })
      //   })
      //   .catch(e => console.log(e));

      this.update({ lastWebSignin: new Date() }, () => { }, (errorMsg: string) => { })

    }).catch((error: any) => {
      errorCallback(error.message);
    })
  }

  getDevice() {

    var device = 'web'

    if (document.location.hostname.indexOf('app.getshortcut.co') !== -1) {

      // Browser or mobile web.
      console.log(document.location)

      device = 'web';

      // console.log(window.self)
      // console.log(window.top)
      // console.log(window.frameElement)
      // console.log(window.parent)

      // if (window.self !== window.top) {
      //   device = 'browser-phone-wrapper'
      // } else {
      //   device = 'mobile-web'
      // }
    }

    return device;
  }

  isBusinessConcierge(): boolean {
    return this.isConcierge() && this.user.business;
  }

  isBusinessAccount(): boolean {
    return this.isBusiness || this.isBusinessConcierge();
  }

  isConcierge() {
    return this.user && this.user.type == 'concierge'
  }

  getBusinessCommissionOptions() {

    if (!this.business) {
      return []
    }

    let map = this.business.businessCommissionMap || {}
    let ids = Object.keys(map)

    var a = []

    for (let id of ids) {
      a.push({
        id: id,
        name: map[id].name,
        commission: map[id].commission,
      })
    }

    return a;
  }

  getBusinessProductCommissionOptions() {

    if (!this.business) {
      return []
    }

    let map = this.business.businessProductCommissionMap || {}
    let ids = Object.keys(map)

    var a = []

    for (let id of ids) {
      a.push({
        id: id,
        name: map[id].name,
        commission: map[id].commission,
      })
    }

    return a;
  }

  getUserAgent() {

    var userAgent = ''

    if (navigator) {

      // Browser or mobile web.
      console.log(navigator.userAgent)

      userAgent = navigator.userAgent;

      // console.log(window.self)
      // console.log(window.top)
      // console.log(window.frameElement)
      // console.log(window.parent)

      // if (window.self !== window.top) {
      //   device = 'browser-phone-wrapper'
      // } else {
      //   device = 'mobile-web'
      // }
    }

    return userAgent;
  }

  signup_Guest(
    fullName: string,
    email: string,
    password: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    if (!Util.validateFullName(fullName)) {
      errorCallback("Please enter your full name.")
      return
    } else if (!Util.validateEmail(email)) {
      errorCallback("Invalid email address.")
      return
    }

    let firstName = Util.getFirstName(fullName)
    let lastName = Util.getLastName(fullName)

    let params = {
      firstName: firstName,
      lastName: lastName,
      fullName: `${firstName} ${lastName}`,
      email: email.toLowerCase(),
      username: email.toLowerCase(),
      device: this.getDevice(),
      webUserAgent: this.getUserAgent(),
      password: password,
      isGuest: false,
    }

    this.update(params, () => {
      successCallback()
    }, (errorMsg: string) => {
      errorCallback(errorMsg)
    })
  }

  signup_Username(
    fullName: string,
    email: string,
    password: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    if (!Util.validateFullName(fullName)) {
      errorCallback("Please enter your full name.")
      return
    } else if (!Util.validateEmail(email)) {
      errorCallback("Invalid email address.")
      return
    }

    let firstName = Util.getFirstName(fullName)
    let lastName = Util.getLastName(fullName)

    let params = {
      firstName: firstName,
      lastName: lastName,
      fullName: `${firstName} ${lastName}`,
      email: email.toLowerCase(),
      device: this.getDevice()
    }

    Parse.User.signUp(email, password, params).then(() => {
      this.globalization.getPreferredLanguage()
        .then(res => {
          this.update({ lang: res.value }, () => { }, (errMsg: string) => { })
        })
        .catch(e => console.log(e));

      this.update({ lastWebSignin: new Date() }, () => { }, (errorMsg: string) => { })

      this.unloadFromParse()
      this.checkForBranchAttribution()
      successCallback()
    }).catch((error: any) => {
      errorCallback(error.message);
    })
  }

  login_Facebook(
    successCallback: (userExisted: boolean) => void,
    errorCallback: (msg: string) => void) {

    FB.getLoginStatus((statusRes: any) => {
      if (statusRes.status == "connected") {
        this.completeFBLoginWithAuthData(statusRes, successCallback, errorCallback)
      } else {
        FB.login((res: any) => {
          if (res && res.status === 'connected') {
            // Logged into your webpage and Facebook.
            this.globalization.getPreferredLanguage()
              .then(res => {
                this.update({ lang: res.value }, () => { }, (errMsg: string) => { })
              })
              .catch(e => {
                console.log('getPreferredLanguage fail')
                console.log(e)
              });

            this.completeFBLoginWithAuthData(res, successCallback, errorCallback)
          } else {
            // The person is not logged into your webpage or we are unable to tell.
            errorCallback('Unable to login to facebook.');
          }
        }, { scope: 'public_profile,email' })
      }
    })
  }

  linkFacebook(
    successCallback: (userExisted: boolean) => void,
    errorCallback: (msg: string) => void) {

    FB.getLoginStatus((statusRes: any) => {
      if (statusRes.status == "connected") {
        this.completeFBLinkWithAuthData(statusRes, successCallback, errorCallback)
      } else {
        FB.login((res: any) => {
          if (res && res.status === 'connected') {
            // Logged into your webpage and Facebook.
            this.completeFBLinkWithAuthData(res, successCallback, errorCallback)
          } else {
            // The person is not logged into your webpage or we are unable to tell.
            errorCallback('Unable to login to Facebook.');
          }
        }, { scope: 'public_profile,email' })
      }
    });
  }

  private async completeFBLinkWithAuthData(
    data: any,
    successCallback: (userExisted: boolean) => void,
    errorCallback: (msg: string) => void
  ) {
    //Need to convert expiresIn format from FB to date
    let expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + data.authResponse.expiresIn);
    let expirationDateString = expirationDate.toISOString();

    let facebookAuthData = {
      "id": data.authResponse.userID,
      "access_token": data.authResponse.accessToken,
      "expiration_date": expirationDateString
    };

    try {
      await Parse.FacebookUtils.link(Parse.User.current(), facebookAuthData);
      this.update({ isGuest: false }, () => { }, (errorMsg: string) => { })
      this.checkForBranchAttribution()
      this.getFacebookUserInformation(() => {
        if (this.user.firstName && this.user.lastName && this.user.email) {
          this.sendWelcomeEmail(() => {
            successCallback(false)
          }, (errorMsg) => {
            errorCallback(Constants.WELCOME_EMAIL_FAILED);
          });
        } else {
          errorCallback(Constants.NO_EMAIL_OR_NAME_KEY);
        }
      }, (msg) => {
        console.log('Get fb info fail.')
        console.log('==*==*==*==*==*')
        console.log('Get facebook info error: ')
        console.log(msg)
        console.log('==*==*==*==*==*')
        errorCallback(msg);
      });
    } catch (error) {
      console.log('FB link failed.')
      console.log('==*==*==*==*==*')
      console.log('Parse fb login error: ')
      console.log(error)
      console.log('==*==*==*==*==*')
      errorCallback(error.message || "User cancelled the Facebook login or did not fully authorize.");
    }
  }

  private async completeFBLoginWithAuthData(
    data: any,
    successCallback: (userExisted: boolean) => void,
    errorCallback: (msg: string) => void
  ) {
    //Need to convert expiresIn format from FB to date
    let expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + data.authResponse.expiresIn);
    let expirationDateString = expirationDate.toISOString();

    let facebookAuthData = {
      "id": data.authResponse.userID,
      "access_token": data.authResponse.accessToken,
      "expiration_date": expirationDateString
    };

    try {
      const user: any = await Parse.FacebookUtils.logIn(facebookAuthData);
      this.checkForBranchAttribution()

      if (!user.existed()) {
        console.log('user DNE')
        this.getFacebookUserInformation(() => {
          if (this.user.firstName && this.user.lastName && this.user.email) {
            this.sendWelcomeEmail(() => {
              console.log('FACEBOOK: Welcome email success. Completed');
              successCallback(user.existed())
            }, (errorMsg) => {
              console.log('FACEBOOK: Welcome email failed');
              errorCallback(Constants.WELCOME_EMAIL_FAILED);
            });
          } else {
            console.log('FACEBOOK: No name or email');
            errorCallback(Constants.NO_EMAIL_OR_NAME_KEY);
          }
        }, (msg) => {
          console.log('FACEBOOK: Get fb info failed');
          console.log(msg);
          errorCallback(msg);
        });
      } else {
        console.log('FACEBOOK: User existed. Completing.');
        this.unloadFromParse()
        successCallback(user.existed());
      }
    } catch (error) {
      errorCallback(error.message || "User cancelled the Facebook login or did not fully authorize.");
    }

  }

  getFacebookUserInformation(
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {

    FB.api('/me/?fields=id,email,first_name,last_name', (result: any) => {
      if (!result || result.error) {
        if (result) {
          console.log(result.error);
        }
        errorCallback('Fetching Facebook info failed.');
      } else {
        console.log('FACEBOOK: Facebook user api success');
        console.log(result);
        let newUserData: any = {
          device: this.getDevice(),
          webUserAgent: this.getUserAgent(),
          isGuest: false
        };
        if (result.first_name && result.last_name) {
          newUserData.firstName = result.first_name;
          newUserData.lastName = result.last_name;
          newUserData.fullName = `${result.first_name} ${result.last_name}`;
        }
        if (result.email) {
          newUserData.email = result.email;
        }
        if (result.likes) {
          newUserData.fb_user_likes = result.likes.data
        }
        if (result.gender) {
          newUserData.fb_gender = result.gender
        }
        let fbID: string = result.id;
        let picURLString = `https://graph.facebook.com/${fbID}/picture?width=300&height=300&return_ssl_resources=1`

        this.profPicService.downloadAndUploadProfPic(picURLString,
          (data: ImageData) => {
            this.saveProfPicToParse(data, () => { }, (errorMsg: string) => { })
          }, (errorMsg: string) => { })

        this.update(newUserData,
          () => {
            console.log('FACEBOOK: Update user success. Completed.');
            successCallback();
          },
          (msg: string) => {
            console.log('FACEBOOK: Update user failed.');
            console.log(msg);
            errorCallback(msg);
          });
      }
    })

  }

  async loginGoogle(
    idToken: string,
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    successCallback: (userExisted: boolean) => void,
    errorCallback: (msg: string) => void
  ) {
    const authData = { authData: { id, id_token: idToken } }

    try {
      const user = await Parse.User.logInWith('google', authData)
      this.globalization.getPreferredLanguage()
        .then(res => {
          this.update({ lang: res.value }, () => { }, (errMsg: string) => { })
        })
        .catch(e => {
          console.log('getPreferredLanguage fail')
          console.log(e)
        });

      this.checkForBranchAttribution()

      const userExisted = user.existed()

      if (!userExisted) {
        let newUserData: any = {
          device: this.getDevice(),
          isGuest: false
        };
        if (firstName && lastName) {
          newUserData.firstName = firstName;
          newUserData.lastName = lastName;
          newUserData.fullName = `${firstName} ${lastName}`;
        }
        if (email) {
          newUserData.email = email;
        }

        this.update(newUserData,
          () => {
            if (this.user.firstName && this.user.lastName && this.user.email) {
              successCallback(user.existed())
            } else {
              errorCallback(Constants.NO_EMAIL_OR_NAME_KEY);
            }
          },
          (msg: string) => {
            console.log(msg);
            errorCallback(msg);
          });
      } else {
        this.unloadFromParse()
        successCallback(userExisted)
      }

    } catch (error) {
      errorCallback(error)
    }
  }

  async linkGoogle(
    newUser: boolean,
    idToken: string,
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    successCallback: (userExisted: boolean) => void,
    errorCallback: (msg: string) => void
  ) {
    const authData = { authData: { id, id_token: idToken } }

    try {
      const user = await Parse.User.current().linkWith('google', authData)
      this.checkForBranchAttribution()
      let newUserData: any = {
        device: this.getDevice(),
        isGuest: false
      };

      if (newUser) {
        if (firstName && lastName) {
          newUserData.firstName = firstName;
          newUserData.lastName = lastName;
          newUserData.fullName = `${firstName} ${lastName}`;
        }
        if (email) {
          newUserData.email = email;
        }
      }

      this.update(newUserData,
        () => {
          if (this.user.firstName && this.user.lastName && this.user.email) {
            successCallback(!newUser)
          } else {
            errorCallback(Constants.NO_EMAIL_OR_NAME_KEY);
          }
        },
        (msg: string) => {
          console.log(msg);
          errorCallback(msg);
        });

    } catch (error) {
      errorCallback(error)
    }
  }

  signInWithGoogle(
    loading: HTMLIonLoadingElement,
    idToken: string,
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    successCallback: (userExisted: boolean) => void,
    errorCallback: (msg: string) => void
  ) {
    const authSource = 'google'
    this.userEmailAuthExists(email, authSource, (userExists: boolean, authDataForSourceExists: boolean) => {
      if (userExists && authDataForSourceExists) {
        // User already has an oauth account with this source, proceed to oauth login
        this.loginGoogle(idToken, id, firstName, lastName, email, successCallback, errorCallback)
      } else if (userExists) {
        // User with this email already exists, prompt for login with password and then link the oauth account
        loading.dismiss()
        this.alert.showPrompt(
          undefined,
          'An account with this email already exists. Please sign into your existing account to link Google sign in.',
          'password',
          'Account password',
          'password',
          'Sign in',
          (data) => {
            loading.present()
            const { password } = data
            this.login_Username(email, password, () => {
              const isNewUser = false
              this.linkGoogle(isNewUser, idToken, id, firstName, lastName, email, successCallback, errorCallback)
            }, errorCallback)
          },
          'Cancel',
          () => errorCallback('User cancelled.'))
      } else {
        // User does not exist, proceed with linking if guest account or login if not
        if (this.user && this.user.isGuest) {
          const isNewUser = true
          this.linkGoogle(isNewUser, idToken, id, firstName, lastName, email, successCallback, errorCallback)
        } else {
          this.loginGoogle(idToken, id, firstName, lastName, email, successCallback, errorCallback)
        }
      }
    }, errorCallback)
  }

  userEmailAuthExists(
    email: string,
    authSource: string,
    successCallback: (userExists, authDataForSourceExists) => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      email,
      authSource,
    }

    CloudCode.run('userEmailAuthExists', params,
      (rsp: any) => {
        successCallback(rsp.userExists, rsp.authDataExists);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }



  // User actions

  sendWelcomeEmail(
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params: any = {
      email: this.user.email
    }

    if (this.user.firstName) {
      params.firstName = this.user.firstName;
    }
    if (this.user.lastName) {
      params.lastName = this.user.lastName;
    }

    CloudCode.run('sendWelcomeMessage', params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  saveProfPicToParse(
    data: ImageData,
    successCallback: () => void,
    errorCallback: (error: string) => void
  ) {

    console.log(data)

    let parseFile = new Parse.File("prof_pic.jpeg", { base64: data.pic });
    let thumbParseFile = new Parse.File("thumb_prof_pic.jpeg", { base64: data.thumbnail })

    this.update({
      profPic: parseFile,
      profPicThumbnail: thumbParseFile
    }, () => {
      this.unloadFromParse();
      successCallback();
    }, (msg: string) => {
      errorCallback('Photo save failed: ' + msg);
    });
  }

  sendPhoneVerification(
    phone: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      phoneNumber: phone
    }

    CloudCode.run('enterPhoneNumber_v3', params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  verifyPhone(
    code: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      verifyCode: code
    }

    CloudCode.run('verifyPhoneNumber_v3', params,
      (rsp: any) => {
        console.log('Verify phone success. Calling fetch on user service.');
        this.fetch(() => {
          successCallback();
        }, (errorMsg) => {
          errorCallback(errorMsg);
        });
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  getReferralCode(
    successCallback: (code: string) => void,
    errorCallback: (msg: string) => void) {

    if (this.user.referralCode) {
      successCallback(this.user.referralCode);
      return;
    }

    CloudCode.run('createReferralCoupon', null,
      (rsp: any) => {
        this.fetch(() => {
          successCallback(this.user.referralCode);
        }, (errorMsg) => {
          errorCallback(errorMsg);
        });
      },
      (msg: string) => {
        errorCallback(msg);
      });

  }

  storeTempPhoneNumber(
    phone: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      phoneNumber: phone
    }

    CloudCode.run('storeTempPhoneNumber', params,
      (rsp: any) => {
        this.fetch(() => {
          successCallback();
        }, (errorMsg) => {
          errorCallback(errorMsg);
        });
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  private checkForBranchAttribution() {
    if (this.user && !this.user.branchParams) {
      this.storage.get(Constants.BRANCH_PARAMS_KEY).then((data) => {
        if (data) {
          const campaignTitle = data['~campaign'];
          let branchParams: any = {}

          for (let key in data) {
            if (!data.hasOwnProperty(key)) continue

            let newKey = key.replace('$', '')
            branchParams[newKey] = data[key]
          }

          this.update({
            branchParams: branchParams,
            branchCampaign: campaignTitle
          }, () => { }, (errorMsg) => { });
        }
      });
    }
  }



  // Helpers

  randomString(length: number): string {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

}
