<div class="inner-content">
  <form #emptyNameEmailForm="ngForm" id="empty-name-email-form" novalidate>
    <ion-item lines="none">
      <ion-input [(ngModel)]="fields.email" name="email" type="email" #email="ngModel" spellcheck="false"
        autocapitalize="off" required placeholder="Email">
      </ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-input [(ngModel)]="fields.firstName" name="firstName" type="text" #firstName="ngModel" required placeholder="First name">
      </ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-input [(ngModel)]="fields.lastName" name="lastName" type="text" #lastName="ngModel" required placeholder="Last name">
      </ion-input>
    </ion-item>

    <ion-button class="SC-long-button" color="marine" (click)="onSubmit(emptyNameEmailForm)" type="submit" block>
      Submit
    </ion-button>
  </form>
</div>