import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { Badge } from "@ionic-native/badge/ngx";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Push, PushOptions } from '@ionic-native/push/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { File } from '@ionic-native/file/ngx';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { Globalization } from '@ionic-native/globalization/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { UserService } from './services/user-service';
import { ChatManager } from './services/chat-manager';
import { DeviceService } from './services/device-service';
import { MetaTagService } from './services/meta-tag-service';
import { ObservableService } from './services/observable-service';
import { SharingService } from './services/sharing-service';

import { ApptReviewPageModule } from './pages/appt-review/appt-review.module'
import { EnterAddressPageModule } from './pages/enter-address/enter-address.module'
import { ServicesListPageModule } from './pages/services-list/services-list.module'
import { PaymentService } from './services/payment-service';
import { AppRateService } from './services/app-rate-service';
import { ReceiptPageModule } from './pages/receipt/receipt.module';
import { EventReceiptPageModule } from './pages/event-receipt/event-receipt.module';
import { InvoiceReceiptPageModule } from './pages/invoice-receipt/invoice-receipt.module';
import { LoginPageModule } from './pages/login/login.module';
import { SignUpPageModule } from './pages/sign-up/sign-up.module';
import { AddProfilePicPageModule } from './pages/add-profile-pic/add-profile-pic.module';
import { EnterPhoneNumberPageModule } from './pages/enter-phone-number/enter-phone-number.module';
import { EnterVerifyCodePageModule } from './pages/enter-verify-code/enter-verify-code.module';
import { MoreReviewsPageModule } from './pages/more-reviews/more-reviews.module';
import { EmptyNameEmailPageModule } from './pages/empty-name-email/empty-name-email.module';
import { AddSpecialInstructionsPageModule } from './pages/add-special-instructions/add-special-instructions.module';
import { RoomsPageModule } from './pages/rooms/rooms.module';

import { ChatContentModule } from './components/chat-content.module'
import { WeekSelectorPageModule } from './pages/week-selector/week-selector.module';
import { CostBreakdownPageModule } from './pages/cost-breakdown/cost-breakdown.module';
import { AdditionalTipPageModule } from './pages/additional-tip/additional-tip.module';
import { PopupPageModule } from './pages/popup/popup.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ApptRequestAlertPageModule } from './pages/appt-request-alert/appt-request-alert.module';
import { SelectProTypePageModule } from './pages/select-pro-type/select-pro-type.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
        mode: 'md'
    }),
    AppRoutingModule,
    HttpModule,
    BrowserAnimationsModule,
    ApptReviewPageModule,
    ApptRequestAlertPageModule,
    EnterAddressPageModule,
    ServicesListPageModule,
    SelectProTypePageModule,
    PopupPageModule,
    ReceiptPageModule,
    EventReceiptPageModule,
    InvoiceReceiptPageModule,
    LoginPageModule,
    SignUpPageModule,
    AddProfilePicPageModule,
    EnterPhoneNumberPageModule,
    EnterVerifyCodePageModule,
    MoreReviewsPageModule,
    EmptyNameEmailPageModule,
    AddSpecialInstructionsPageModule,
    RoomsPageModule,
    ChatContentModule,
    WeekSelectorPageModule,
    CostBreakdownPageModule,
    AdditionalTipPageModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NoopAnimationsModule,
  ],
  providers: [

    UserService,
    ChatManager,
    DeviceService,
    ObservableService,
    SharingService,

    // Plugins
    ScreenOrientation,
    Deeplinks,
    Badge,
    InAppBrowser,
    Push,
    AppVersion,
    Camera,
    FileTransfer,
    AppRate,
    Device,
    Geolocation,
    NativeGeocoder,
    AppAvailability,
    File,
    SocialSharing,
    Globalization,
    PaymentService,
    AppRateService,
    CallNumber,
    SMS,
    EmailComposer,
    Clipboard,
    MetaTagService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
