import * as Parse from 'parse';

import { Appointment } from '../models/appointment';
import { Barber } from '../models/barber';
import { Review } from '../models/review';
import { BarberService } from './barber-service';

import { Constants } from "../models/constants";
import { CloudCode } from './cloudcode';
import { EventTimeslotInfo, RecentlyViewedEvent } from './event-service';

export interface ClientConfigResponse {
  homeProTypes: string[]
  homeProTypeDetails: any
  upcomingAppts: Appointment[]
  upcomingEventTimeslots: EventTimeslotInfo[]
  referralBonusAmount: string
  referralDiscountPercentage: string
}

export class ApptService {

  static pullClientConfig(
    successCallback: (response: ClientConfigResponse) => void,
    errorCallback: (msg: string) => void
  ) {

    CloudCode.run('pullClientConfig', {}, 
      (response: ClientConfigResponse) => {
        successCallback(response);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });

  }

  static pullClientUpcomingAndHistory(
    successCallback: (response: any) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('pullClientUpcomingAndHistory', {}, 
      (response: any) => {
        successCallback(response);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static pullBusinessUpcomingAndHistory(
    successCallback: (response: any) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('pullBusinessUpcomingAndHistory', {}, 
      (response: any) => {
        successCallback(response);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static fetchApptForReview(
    successCallback: (appt: Appointment) => void,
    errorCallback: (msg: string) => void
  ) {
    CloudCode.run('pullApptToReview', {}, 
      (appt: Appointment) => {
        successCallback(appt);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static pullRecentlyViewedEvents(
    successCallback: (rsp: RecentlyViewedEvent[]) => void,
    errorCallback: (msg: string) => void
  ) {

    CloudCode.run('pullRecentlyViewedEvents', {},
      (rsp: RecentlyViewedEvent[]) => {
        if (rsp) {
          successCallback(rsp);
        } else {
          errorCallback('No event found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

}