import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '../services/util';


@Pipe({
    name: 'eventSeriesDate',
})
export class EventSeriesDatePipe implements PipeTransform {
    transform(value: Date) {
        return Util.stringFromDateWithFormat(value, 'ddd MMM DD');
    }
}