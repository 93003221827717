import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Appointment } from '../models/appointment';
import { EventTimeslot } from './review-service';

@Injectable()
export class ObservableService {

  private testSource = new Subject()
  test = this.testSource.asObservable()
  publishTest() {
    this.testSource.next()
  }

  // GENERAL

  private goToLoginSource = new Subject()
  goToLogin = this.goToLoginSource.asObservable()
  publishGoToLogin(preventMainRefresh: boolean, userParamsUpdateOnLogin?: Object) {
    this.goToLoginSource.next({ preventMainRefresh, userParamsUpdateOnLogin })
  }

  private goToSignUpSource = new Subject()
  goToSignUp = this.goToSignUpSource.asObservable()
  publishGoToSignUp(preventMainRefresh: boolean, source: string) {
    this.goToSignUpSource.next({ preventMainRefresh, source })
  }

  private goToAddProfPicSource = new Subject()
  goToAddProfPic = this.goToAddProfPicSource.asObservable()
  publishGoToAddProfPic() {
    this.goToAddProfPicSource.next()
  }

  private goToEnterPhoneNumberSource = new Subject()
  goToEnterPhoneNumber = this.goToEnterPhoneNumberSource.asObservable()
  publishGoToEnterPhoneNumber() {
    this.goToEnterPhoneNumberSource.next()
  }

  private openBusinessProChatSelectorSource = new Subject()
  openBusinessProChatSelector = this.openBusinessProChatSelectorSource.asObservable()
  publishOpenBusinessProChatSelector() {
    this.openBusinessProChatSelectorSource.next()
  }

  private goToEnterVerificationCodeSource = new Subject()
  goToEnterVerificationCode = this.goToEnterVerificationCodeSource.asObservable()
  publishGoToEnterVerificationCode() {
    this.goToEnterVerificationCodeSource.next()
  }

  private goToEmptyNameEmailSource = new Subject()
  goToEmptyNameEmail = this.goToEmptyNameEmailSource.asObservable()
  publishGoToEmptyNameEmail() {
    this.goToEmptyNameEmailSource.next()
  }

  private openNewChatSource = new Subject()
  openNewChat = this.openNewChatSource.asObservable()
  publishOpenNewChat(roomID: string) {
    this.openNewChatSource.next(roomID)
  }

  private openNewAdminChatSource = new Subject()
  openNewAdminChat = this.openNewAdminChatSource.asObservable()
  publishOpenNewAdminChat() {
    this.openNewAdminChatSource.next()
  }

  private minimizeAllChatsSource = new Subject()
  minimizeAllChats = this.minimizeAllChatsSource.asObservable()
  publishMinimizeAllChats() {
    this.minimizeAllChatsSource.next()
  }

  private appBecameActiveSource = new Subject()
  appBecameActive = this.appBecameActiveSource.asObservable()
  publishAppBecameActive() {
    this.appBecameActiveSource.next()
  }

  private refreshSideMenuSource = new Subject()
  refreshSideMenu = this.refreshSideMenuSource.asObservable()
  publishRefreshSideMenu() {
    this.refreshSideMenuSource.next()
  }

  private barberPushClickedSource = new Subject()
  barberPushClicked = this.barberPushClickedSource.asObservable()
  publishBarberPushClicked() {
    this.barberPushClickedSource.next()
  }

  private refreshMainViewSource = new Subject()
  refreshMainView = this.refreshMainViewSource.asObservable()
  publishRefreshMainView() {
    this.refreshMainViewSource.next()
  }

  private refreshMainViewForceBookingSource = new Subject()
  refreshMainViewForceBooking = this.refreshMainViewForceBookingSource.asObservable()
  publishRefreshMainViewForceBooking() {
    this.refreshMainViewForceBookingSource.next()
  }

  private refreshMainViewForceAppointmentsSource = new Subject()
  refreshMainViewForceAppointments = this.refreshMainViewForceAppointmentsSource.asObservable()
  publishRefreshMainViewForceAppointments() {
    this.refreshMainViewForceAppointmentsSource.next()
  }

  private registerPushSource = new Subject()
  registerPush = this.registerPushSource.asObservable()
  publishRegisterPush() {
    this.registerPushSource.next()
  }

  private refreshRecentlyViewedEventsSource = new Subject()
  refreshRecentlyViewedEvents = this.refreshRecentlyViewedEventsSource.asObservable()
  publishRefreshRecentlyViewedEvents() {
    this.refreshRecentlyViewedEventsSource.next()
  }

  private profPicUpdatedSource = new Subject()
  profPicUpdated = this.profPicUpdatedSource.asObservable()
  publishProfPicUpdated() {
    this.profPicUpdatedSource.next()
  }

  private locationPermissionGrantedSource = new Subject()
  locationPermissionGranted = this.locationPermissionGrantedSource.asObservable()
  publishLocationPermissionGranted() {
    this.locationPermissionGrantedSource.next()
  }

  private showReferFriendSource = new Subject()
  showReferFriend = this.showReferFriendSource.asObservable()
  publishShowReferFriend() {
    this.showReferFriendSource.next()
  }

  private showDownloadPopupSource = new Subject()
  showDownloadPopup = this.showDownloadPopupSource.asObservable()
  publishShowDownloadPopup() {
    this.showDownloadPopupSource.next()
  }

  private logOutSource = new Subject()
  logOut = this.logOutSource.asObservable()
  publishLogOut() {
    this.logOutSource.next()
  }

  private verifyPhoneCompleteSource = new Subject()
  verifyPhoneComplete = this.verifyPhoneCompleteSource.asObservable()
  publishVerifyPhoneComplete() {
    this.verifyPhoneCompleteSource.next()
  }

  private refreshAppSource = new Subject()
  refreshApp = this.refreshAppSource.asObservable()
  publishRefreshApp() {
    this.refreshAppSource.next()
  }

  private branchLinkClickedSource = new Subject()
  branchLinkClicked = this.branchLinkClickedSource.asObservable()
  publishBranchLinkClicked() {
    this.branchLinkClickedSource.next()
  }

  private checkForReviewSource = new Subject()
  checkForReview = this.checkForReviewSource.asObservable()
  publishCheckForReview() {
    this.checkForReviewSource.next()
  }

  private showApptReviewSource = new Subject()
  showApptReview = this.showApptReviewSource.asObservable()
  publishShowApptReview(appt: Appointment) {
    this.showApptReviewSource.next(appt)
  }

  private showEventReviewSource = new Subject()
  showEventReview = this.showEventReviewSource.asObservable()
  publishShowEventReview(eventInfo: EventTimeslot) {
    this.showEventReviewSource.next(eventInfo)
  }

  private setCurrentMenuRootSource = new Subject()
  setCurrentMenuRoot = this.setCurrentMenuRootSource.asObservable()
  publishSetCurrentMenuRoot(currentRoot: string) {
    this.setCurrentMenuRootSource.next(currentRoot)
  }

  private updateAllChatRoomInfoSource = new Subject()
  updateAllChatRoomInfo = this.updateAllChatRoomInfoSource.asObservable()
  publishUpdateAllChatRoomInfo() {
    this.updateAllChatRoomInfoSource.next()
  }

  private updateChatRoomInfoSource = new Subject()
  updateChatRoomInfo = this.updateChatRoomInfoSource.asObservable()
  publishUpdateChatRoomInfo(roomID: string) {
    this.updateChatRoomInfoSource.next(roomID)
  }

  private checkUnreadMessagesSource = new Subject()
  checkUnreadMessages = this.checkUnreadMessagesSource.asObservable()
  publishCheckUnreadMessages() {
    this.checkUnreadMessagesSource.next()
  }

  private liveMessageCreateSource = new Subject()
  liveMessageCreate = this.liveMessageCreateSource.asObservable()
  publishLiveMessageCreate(msg: string) {
    this.liveMessageCreateSource.next(msg)
  }

  private liveMessageUpdateSource = new Subject()
  liveMessageUpdate = this.liveMessageUpdateSource.asObservable()
  publishLiveMessageUpdate(msg: string) {
    this.liveMessageUpdateSource.next(msg)
  }

  private apptReviewCompletedSource = new Subject()
  apptReviewCompleted = this.apptReviewCompletedSource.asObservable()
  publishApptReviewCompleted() {
    this.apptReviewCompletedSource.next()
  }

  private chatToggledOpenSource = new Subject()
  chatToggledOpen = this.chatToggledOpenSource.asObservable()
  publishChatToggledOpen(roomID: string) {
    this.chatToggledOpenSource.next(roomID)
  }

  // USER

  private updateUserSource = new Subject()
  updateUser = this.updateUserSource.asObservable()
  publishUpdateUser() {
    this.updateUserSource.next()
  }

  private userFetchedSource = new Subject()
  userFetched = this.userFetchedSource.asObservable()
  publishUserFetched() {
    this.userFetchedSource.next()
  }

  private businessFetchedSource = new Subject()
  businessFetched = this.businessFetchedSource.asObservable()
  publishBusinessFetched() {
    this.businessFetchedSource.next()
  }

  private loadUserFailedSource = new Subject()
  loadUserFailed = this.loadUserFailedSource.asObservable()
  publishLoadUserFailed() {
    this.loadUserFailedSource.next()
  }

  // ACTIONS

  private enterEventCodeSource = new Subject()
  enterEventCode = this.enterEventCodeSource.asObservable()
  publishEnterEventCode(code: string) {
    this.enterEventCodeSource.next(code)
  }

  private chatMessageSentSource = new Subject()
  chatMessageSent = this.chatMessageSentSource.asObservable()
  publishChatMessageSent() {
    this.chatMessageSentSource.next()
  }

  private joinedWaitlistSource = new Subject()
  joinedWaitlist = this.joinedWaitlistSource.asObservable()
  publishJoinedWaitlist() {
    this.joinedWaitlistSource.next()
  }

  private giftCardPurchasedSource = new Subject()
  giftCardPurchased = this.giftCardPurchasedSource.asObservable()
  publishGiftCardPurchased(code: string) {
    this.giftCardPurchasedSource.next(code)
  }

  private bookedSource = new Subject()
  booked = this.bookedSource.asObservable()
  publishBooked() {
    this.bookedSource.next()
  }

  // DEEPLINKS

  private handleDeeplinksSource = new Subject()
  handleDeeplinks = this.handleDeeplinksSource.asObservable()
  publishHandleDeeplinks() {
    this.handleDeeplinksSource.next()
  }

  private deeplinkBarberSource = new Subject()
  deeplinkBarber = this.deeplinkBarberSource.asObservable()
  publishDeeplinkBarber() {
    this.deeplinkBarberSource.next()
  }

  private deeplinkPromoSource = new Subject()
  deeplinkPromo = this.deeplinkPromoSource.asObservable()
  publishDeeplinkPromo() {
    this.deeplinkPromoSource.next()
  }

  private deeplinkEventSource = new Subject()
  deeplinkEvent = this.deeplinkEventSource.asObservable()
  publishDeeplinkEvent() {
    this.deeplinkEventSource.next()
  }

  private deeplinkChatRoomSource = new Subject()
  deeplinkChatRoom = this.deeplinkChatRoomSource.asObservable()
  publishDeeplinkChatRoom() {
    this.deeplinkChatRoomSource.next()
  }

  private deeplinkReferFriendSource = new Subject()
  deeplinkReferFriend = this.deeplinkReferFriendSource.asObservable()
  publishDeeplinkReferFriend() {
    this.deeplinkReferFriendSource.next()
  }

  private deeplinkRedeemGiftCardSource = new Subject()
  deeplinkRedeemGiftCard = this.deeplinkRedeemGiftCardSource.asObservable()
  publishDeeplinkRedeemGiftCard() {
    this.deeplinkRedeemGiftCardSource.next()
  }

  private deeplinkGiftCardsSource = new Subject()
  deeplinkGiftCards = this.deeplinkGiftCardsSource.asObservable()
  publishDeeplinkGiftCards() {
    this.deeplinkGiftCardsSource.next()
  }

  private deeplinkChatSupportSource = new Subject()
  deeplinkChatSupport = this.deeplinkChatSupportSource.asObservable()
  publishDeeplinkChatSupport() {
    this.deeplinkChatSupportSource.next()
  }

}