import { Injectable } from '@angular/core';
// import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Constants } from './../models/constants';
import { UserService } from './user-service';

declare var ga: any;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  static ViewedIntroScreen = "ViewedIntroScreen"
  static ExplorePressed = "ExplorePressed"
  static ViewedProProfile = "ViewedProProfile"
  static ViewedProProfile_FirstBooking = "ViewedProProfile_FirstBooking"
  static SignedUp = "SignedUp"
  static VerifiedPhone = "VerifiedPhone"
  static ConfirmPressed = "ConfirmPressed"
  static ConfirmPressed_FirstBooking = "ConfirmPressed_FirstBooking"
  static ViewedConfirmBooking = "ViewedConfirmBooking"
  static ViewedConfirmBooking_FirstBooking = "ViewedConfirmBooking_FirstBooking"
  static BookingComplete = "BookingComplete"
  static BookingComplete_FirstBooking = "BookingComplete_FirstBooking"
  static EnteredAddress = "EnteredAddress"
  static EnteredAddress_FirstBooking = "EnteredAddress_FirstBooking"
  static EnteredAddress_GPS = "EnteredAddress_GPS"
  static EnteredAddress_GPS_FirstBooking = "EnteredAddress_GPS_FirstBooking"
  static EnteredAddress_MainScreen_FirstBooking = "EnteredAddress_MainScreen_FirstBooking"
  static EnteredAddress_MainScreen = "EnteredAddress_MainScreen"
  static EnteredAddress_BarberProfile = "EnteredAddress_BarberProfile"
  static EnteredAddress_BarberProfile_FirstBooking = "EnteredAddress_BarberProfile_FirstBooking"
  static EnteredAddress_Account = "EnteredAddress_Account"
  static EnteredAddress_Account_FirstBooking = "EnteredAddress_Account_FirstBooking"
  static EnteredAddress_UpcomingAppt = "EnteredAddress_UpcomingAppt"
  static EnteredAddress_UpcomingAppt_FirstBooking = "EnteredAddress_UpcomingAppt_FirstBooking"
  static EnteredAddress_ConfirmBooking = "EnteredAddress_ConfirmBooking"
  static EnteredAddress_ConfirmBooking_FirstBooking = "EnteredAddress_ConfirmBooking_FirstBooking"
  static ViewReferralScreen = "ViewReferralScreen"
  static OpenReferralShareDialog = "OpenReferralShareDialog"
  static ViewMoreReviews = "ViewMoreReviews"
  static ViewMoreReviews_FirstBooking = "ViewMoreReviews_FirstBooking"
  static ViewProProfilePhotos = "ViewProProfilePhotos"
  static ViewProProfilePhotos_FirstBooking = "ViewProProfilePhotos_FirstBooking"
  static OpenProChat = "OpenProChat"
  static OpenProChat_FirstBooking = "OpenProChat_FirstBooking"
  static OpenConciergeChat = "OpenConciergeChat"
  static OpenConciergeChat_FirstBooking = "OpenConciergeChat_FirstBooking"
  static PressConciergeBanner = "PressConciergeBanner"
  static PressConciergeBanner_FirstBooking = "PressConciergeBanner_FirstBooking"
  static SendConciergeChat = "SendConciergeChat"
  static SendConciergeChat_FirstBooking = "SendConciergeChat_FirstBooking"
  static SendProChat = "SendProChat"
  static SendProChat_FirstBooking = "SendProChat_FirstBooking"
  static RequestAppt = "RequestAppt"
  static RequestAppt_FirstBooking = "RequestAppt_FirstBooking"
  static ViewRequestApptConfirm = "ViewRequestApptConfirm"
  static ViewRequestApptConfirm_FirstBooking = "ViewRequestApptConfirm_FirstBooking"
  static ViewChatApptConfirm = "ViewChatApptConfirm"
  static ViewChatApptConfirm_FirstBooking = "ViewChatApptConfirm_FirstBooking"

  // Event orders
  static SignedUpEvent = "SignedUpEvent"
  static VerifiedPhoneEvent = "VerifiedPhoneEvent"
  static BookingCompleteEvent = "BookingCompleteEvent"
  static BookingCompleteEvent_FirstBooking = "BookingCompleteEvent_FirstBooking"

  constructor(
    // private firebase: FirebaseX,
    private userService: UserService
  ) { }

  initializeAnalytics() {
    if (Constants.DEV_MODE) {
      return;
    }
  }

  disableAnalytics() {
    if (!Constants.BROWSER_MODE) {
      // this.firebase.setAnalyticsCollectionEnabled(false)
    }
  }

  track(eventName: string, data?: any) {

    if (Constants.DEV_MODE) {
      return;
    }

    const analyticsData = data || {}

    // Call generic EnteredAddress analytics on any other entered address analytics call
    if (
      eventName == AnalyticsService.EnteredAddress_GPS ||
      eventName == AnalyticsService.EnteredAddress_MainScreen ||
      eventName == AnalyticsService.EnteredAddress_BarberProfile ||
      eventName == AnalyticsService.EnteredAddress_Account ||
      eventName == AnalyticsService.EnteredAddress_UpcomingAppt ||
      eventName == AnalyticsService.EnteredAddress_ConfirmBooking
    ) {
      this.track(AnalyticsService.EnteredAddress, data)
    }

    if (
      this.userService.user
      && this.userService.user.numCompletedAppts == 0
      && this.userService.user.numUpcomingAppts == 0
      && !this.userService.user.hasCancelledAppt
    ) {

      let firstBookingEvent;

      let extraTag = "" // TODO
      if (this.userService.user.eventSignUp) {
        extraTag = "_FromEvent"
      }

      switch (eventName) {
        case AnalyticsService.ViewedProProfile:
          firstBookingEvent = AnalyticsService.ViewedProProfile_FirstBooking + extraTag
          break
        case AnalyticsService.ViewedConfirmBooking:
          firstBookingEvent = AnalyticsService.ViewedConfirmBooking_FirstBooking + extraTag
          break
        case AnalyticsService.BookingComplete:
          firstBookingEvent = AnalyticsService.BookingComplete_FirstBooking + extraTag
          break
        case AnalyticsService.ConfirmPressed:
          firstBookingEvent = AnalyticsService.ConfirmPressed_FirstBooking + extraTag
          break
        case AnalyticsService.EnteredAddress:
          firstBookingEvent = AnalyticsService.EnteredAddress_FirstBooking + extraTag
          break
        case AnalyticsService.EnteredAddress_GPS:
          firstBookingEvent = AnalyticsService.EnteredAddress_GPS_FirstBooking + extraTag
          break
        case AnalyticsService.EnteredAddress_MainScreen:
          firstBookingEvent = AnalyticsService.EnteredAddress_MainScreen_FirstBooking + extraTag
          break
        case AnalyticsService.EnteredAddress_BarberProfile:
          firstBookingEvent = AnalyticsService.EnteredAddress_BarberProfile_FirstBooking + extraTag
          break
        case AnalyticsService.EnteredAddress_Account:
          firstBookingEvent = AnalyticsService.EnteredAddress_Account_FirstBooking + extraTag
          break
        case AnalyticsService.EnteredAddress_UpcomingAppt:
          firstBookingEvent = AnalyticsService.EnteredAddress_UpcomingAppt_FirstBooking + extraTag
          break
        case AnalyticsService.EnteredAddress_ConfirmBooking:
          firstBookingEvent = AnalyticsService.EnteredAddress_ConfirmBooking_FirstBooking + extraTag
          break
        case AnalyticsService.ViewMoreReviews:
          firstBookingEvent = AnalyticsService.ViewMoreReviews_FirstBooking + extraTag
          break
        case AnalyticsService.ViewProProfilePhotos:
          firstBookingEvent = AnalyticsService.ViewProProfilePhotos_FirstBooking + extraTag
          break
        case AnalyticsService.OpenProChat:
          firstBookingEvent = AnalyticsService.OpenProChat_FirstBooking + extraTag
          break
        case AnalyticsService.SendProChat:
          firstBookingEvent = AnalyticsService.SendProChat_FirstBooking + extraTag
          break
        case AnalyticsService.RequestAppt:
          firstBookingEvent = AnalyticsService.RequestAppt_FirstBooking + extraTag
          break
        case AnalyticsService.ViewRequestApptConfirm:
          firstBookingEvent = AnalyticsService.ViewRequestApptConfirm_FirstBooking + extraTag
          break
        case AnalyticsService.ViewChatApptConfirm:
          firstBookingEvent = AnalyticsService.ViewChatApptConfirm_FirstBooking + extraTag
          break
        case AnalyticsService.BookingCompleteEvent:
          firstBookingEvent = AnalyticsService.BookingCompleteEvent_FirstBooking // Doesn't need extra tag. Already from event.
          break
        case AnalyticsService.OpenConciergeChat:
          firstBookingEvent = AnalyticsService.OpenConciergeChat_FirstBooking + extraTag
          break
        case AnalyticsService.SendConciergeChat:
          firstBookingEvent = AnalyticsService.SendConciergeChat_FirstBooking + extraTag
          break
        case AnalyticsService.PressConciergeBanner:
          firstBookingEvent = AnalyticsService.PressConciergeBanner_FirstBooking + extraTag
          break
      }

      if (firstBookingEvent) {
        if (Constants.BROWSER_MODE) {
          ga('send', 'event', 'MobileWeb', firstBookingEvent);
        } else {
          // this.firebase.logEvent(firstBookingEvent, analyticsData)
          //   .then((res: any) => console.log("Firebase analytics log event response: ", res))
          //   .catch((error: any) => console.error("Firebase analytics log event error: ", error));
        }
      }
    }

    if (Constants.BROWSER_MODE) {
      if (eventName === AnalyticsService.BookingComplete || eventName == AnalyticsService.BookingCompleteEvent) {
        ga('ecommerce:addTransaction', analyticsData);
        ga('ecommerce:send');
      }
      ga('send', 'event', 'MobileWeb', eventName);
    } else {
      // this.firebase.logEvent(eventName, analyticsData)
      //   .then((res: any) => console.log("Firebase analytics log event response: ", res))
      //   .catch((error: any) => console.error("Firebase analytics log event error: ", error));
    }
  }

  setUserID(id: string) {

    if (Constants.DEV_MODE) {
      return;
    }

    if (Constants.BROWSER_MODE) {
      // gtag('set', { 'user_id': id });
      ga('set', 'userId', id); // Set the user ID using signed-in user_id.
    } else {
      // this.firebase.setUserId(id)
      //   .then((res: any) => console.log("Firebase analytics set user id response: ", res))
      //   .catch((error: any) => console.error("Firebase analytics set user id error: ", error));
    }
  }

  setUserProperty(key: string, value: string) {

    if (Constants.DEV_MODE) {
      return;
    }

    if (Constants.BROWSER_MODE) {

    } else {
      // this.firebase.setUserProperty(key, value)
      //   .then((res: any) => console.log("Firebase analytics set user property response: ", res))
      //   .catch((error: any) => console.error("Firebase analytics set user property error: ", error));
    }
  }

}
