
<ion-header>
  <ion-toolbar color="marine" position="top">

    <ion-title>{{showMutedRooms ? 'Unsubscribed' : 'Your'}} Chats</ion-title>

    <!-- <ion-buttons slot="end">
      <ion-button (click)="openOptions()">
        <ion-icon slot="icon-only" name="ellipsis-horizontal" color="white"></ion-icon>
      </ion-button>
    </ion-buttons> -->

  </ion-toolbar>
</ion-header>

<ion-content>

  <div *ngIf="rooms" class="outer-item">

    <div class="item-wrapper">
      <ion-item lines="none" *ngIf="!userService.isBusiness && !userService.isBusinessConcierge() && !hasConciergeChat" (click)="goToConciergeChat()">

        <ion-thumbnail slot="start" style="min-width: 40px; min-height: 40px; line-height: 40px; padding-top: 6px">
          <ion-img src="assets/img/concierge_bell.png"
            style="width: 40px !important; height: 40px !important; line-height: 40px !important"></ion-img>
        </ion-thumbnail>

        <ion-label>
          <span class="name">Concierge</span>
          <p>Bookings and suggestions.</p>
        </ion-label>

        <ion-icon slot="end" name="chevron-forward-outline" color="grey" class="SC-edit-icon"></ion-icon>

      </ion-item>
    </div>

    <div class="item-wrapper">
      <ion-item lines="none" *ngIf="!hasAdminChat" (click)="goToAdminChat()">

        <ion-thumbnail slot="start" style="min-width: 40px; min-height: 40px; line-height: 40px; padding-top: 6px">
          <ion-img src="assets/img/shortcut-icon.png"
            style="width: 40px !important; height: 40px !important; line-height: 40px !important"></ion-img>
        </ion-thumbnail>

        <ion-label>
          <span class="name">Get help</span>
          <p>Questions and support.</p>
        </ion-label>

        <ion-icon slot="end" name="chevron-forward-outline" color="grey" class="SC-edit-icon"></ion-icon>

      </ion-item>
    </div>

    <div class="item-wrapper" *ngFor="let r of rooms" (click)="goToChat(r.id)">

      <div class="rooms-chat-dot" *ngIf="r.lastMsg && r.lastMsg.isUnread"></div>

      <ion-item lines="none">

        <ion-thumbnail slot="start" style="min-width: 40px; min-height: 40px; line-height: 40px">
          <ion-img [src]="r.img || placeholderImg"
            style="width: 40px !important; height: 40px !important; line-height: 40px !important"></ion-img>
        </ion-thumbnail>

        <ion-label>
          <span class="name">{{r.name}}</span>
          <p>{{r.lastMsg ? r.lastMsg.blurb : ''}}</p>
          <p slot="end">{{r.lastMsg ? timestamp(r.lastMsg.createdAt) : ''}}</p>
        </ion-label>

        <ion-icon slot="end" name="chevron-forward-outline" color="grey" class="SC-edit-icon"></ion-icon>

      </ion-item>
    </div>

  </div>

  <!-- <div *ngIf="rooms && !rooms.length">
		<p class="no-convos">Nothing here.</p>
	</div> -->

  <div *ngIf="!rooms">
    <div class="no-convos">
      <ion-icon name="chatbubbles"></ion-icon>
      <p>Loading conversations...</p>
    </div>
  </div>

</ion-content>