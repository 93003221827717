import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ApptRequestAlertPageRoutingModule } from './appt-request-alert-routing.module';

import { ApptRequestAlertPage } from './appt-request-alert.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ApptRequestAlertPageRoutingModule
  ],
  declarations: [ApptRequestAlertPage]
})
export class ApptRequestAlertPageModule {}
