import { Util } from "../services/util";
import { Timezone } from "../services/timezones";

export class AppointmentTime {

    timezone?: Timezone;
    private _date: Date;

    constructor(date?: Date, timezone?: Timezone) {
        this.date = date || new Date();
        this.timezone = timezone
    }
    
    get date(): Date {
        let now = new Date();
        return (this._date < now) ? now : this._date;
    }
    
    set date(newDate: Date) {
        this._date = newDate;
    }

    getLongDateString() {
        return Util.longDateString(this._date, this.timezone);
    }
}
