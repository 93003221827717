import { NgModule } from '@angular/core';
import { NumberDatePipe } from './number-date';
import { TimeRangePipe } from './time-range';
import { TimeslotTimePipe } from './timeslot-time';
import { TimeRangeLongPipe } from './time-range-long';
import { EventSeriesDatePipe } from './event-series-date';
import { LongDateStringPipe } from './long-date-string';
import { LongDateRangeStringPipe } from './long-date-range-string';
import { AddressPipe } from './address';
import { AddressShortPipe } from './address-short';
import { CityStatePipe } from './city-state';
import { SafePipe } from './safe';
import { ServiceCategoriesPipe } from './service-categories';
// import * as Ng2SearchPipeModule from 'ng2-search-filter';

@NgModule({
  declarations: [
    NumberDatePipe,
    TimeRangePipe,
    TimeslotTimePipe,
    TimeRangeLongPipe,
    EventSeriesDatePipe,
    LongDateStringPipe,
    LongDateRangeStringPipe,
    AddressPipe,
    AddressShortPipe,
    CityStatePipe,
    SafePipe,
    ServiceCategoriesPipe,
    // Ng2SearchPipeModule,
  ],
  imports: [
    
  ],
  exports: [
    NumberDatePipe,
    TimeRangePipe,
    TimeslotTimePipe,
    TimeRangeLongPipe,
    EventSeriesDatePipe,
    LongDateStringPipe,
    LongDateRangeStringPipe,
    AddressPipe,
    AddressShortPipe,
    CityStatePipe,
    SafePipe,
    ServiceCategoriesPipe,
    // Ng2SearchPipeModule,
  ]
})
export class PipesModule { }