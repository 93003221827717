import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Popup } from '../../models/popup';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.page.html',
  styleUrls: ['./popup.page.scss'],
})
export class PopupPage implements OnInit {

  popup: Popup
  showAcceptDecline: boolean
  isCovidGuideline: boolean
  acceptButtonTitle: string = "Accept"
  declineButtonTitle: string = "Decline"

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  mainPressed() {
    this.modalCtrl.dismiss({ accepted: true })
  }

  declinePressed() {
    this.modalCtrl.dismiss()
  }

  linkPressed(url) {
    window.open(url, '_blank')
  }

}
