import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Appointment } from 'src/app/models/appointment';
import { AlertController, ModalController } from '@ionic/angular';
import { Alert } from 'src/app/services/alert';
import { Util } from 'src/app/services/util';
import { EventHistoryItem } from 'src/app/services/order-history-tasks';

interface TipChoice {
  id: string;
  displayString: string;
  value: number;
  isCustom?: boolean;
  showIcon?: boolean;
}

@Component({
  selector: 'app-additional-tip',
  templateUrl: './additional-tip.page.html',
  styleUrls: ['./additional-tip.page.scss'],
})
export class AdditionalTipPage implements OnInit {

  historyItem: any
  tipChoices: TipChoice[]
  selectedTipChoice: TipChoice

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private changeRef: ChangeDetectorRef,
    private alert: Alert,
  ) { }

  ngOnInit() {
    if (this.historyItem.type === 'appt') {
      this.loadTipChoices(this.historyItem.info)
    } else {
      this.loadTipChoices_Event(this.historyItem.info)
    }
  }

  tipChoicePressed(tipChoice: TipChoice) {

    if (tipChoice.isCustom) {
      this.showCustomTipSelector(tipChoice)
      this.changeRef.detectChanges()
      return
    }

    this.selectedTipChoice = tipChoice
    this.changeRef.detectChanges()

  }

  sendTipPressed() {
    if (this.selectedTipChoice.value == 0) {
      this.alert.show("Oops!", "You haven't added any tip.")
      return
    }

    this.modalCtrl.dismiss({ tip: this.selectedTipChoice.value })
  }

  private loadTipChoices(appt: Appointment) {

    const { currency } = appt.costs

    let fifteenPercentCost = Util.formatCost(appt.costs.charge * 0.15, currency)
    let twentyPercentCost = Util.formatCost(appt.costs.charge * 0.2, currency)
    let twentyFivePercentCost = Util.formatCost(appt.costs.charge * 0.25, currency)

    this.tipChoices = [{
      id: '0',
      displayString: `15% (${fifteenPercentCost})`,
      value: appt.costs.charge * 0.15,
    }, {
      id: '1',
      displayString: `20% (${twentyPercentCost})`,
      value: appt.costs.charge * 0.20,
    }, {
      id: '2',
      displayString: `25% (${twentyFivePercentCost})`,
      value: appt.costs.charge * 0.25,
    }, {
      id: '3',
      displayString: "Custom tip",
      value: 0,
      isCustom: true
    }]

    this.selectedTipChoice = this.tipChoices[0]
  }

  private loadTipChoices_Event(event: EventHistoryItem) {

    const { currency } = event.costs

    this.tipChoices = [{
      id: '0',
      displayString: Util.formatCost(5, currency, 0),
      value: 5,
    }, {
      id: '1',
      displayString: Util.formatCost(10, currency, 0),
      value: 10,
    }, {
      id: '2',
      displayString: Util.formatCost(15, currency, 0),
      value: 15,
    }, {
      id: '3',
      displayString: "Custom tip",
      value: 0,
      isCustom: true
    }]

    this.selectedTipChoice = this.tipChoices[0]
  }

  private async showCustomTipSelector(tipChoice: TipChoice) {

    let alert = await this.alertCtrl.create({
      inputs: [
        {
          name: 'value',
          placeholder: `Enter tip in ${this.historyItem.info.costs.currency}`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Save',
          handler: (data: any) => {
            let value = data.value
            tipChoice.value = value
            tipChoice.displayString = Util.formatCost(value, this.historyItem.info.costs.currency)
            tipChoice.showIcon = true
            this.selectedTipChoice = tipChoice
          }
        }
      ]
    });
    alert.present();
  }

}
