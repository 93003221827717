import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '../services/util';
import { Address } from '../models/address';


@Pipe({
    name: 'address',
})
export class AddressPipe implements PipeTransform {
    transform(value: Address) {
        return Util.formatAddress(value);
    }
}