import { Injectable, ApplicationRef, ViewChild } from '@angular/core';
import { LoadingController, ActionSheetController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import * as Parse from 'parse';

import { Constants } from '../models/constants';
import { Room } from '../models/message';

import { BusinessBarberObj, BusinessTasks } from './business-tasks'
import { RoomTasks } from './room-tasks'
import { Alert } from './alert'
import { ObservableService } from './observable-service';

export interface Chat {
	roomID: string
	room: Room
	windowOpened: boolean
	numUnreadMessages: number
}

interface SavedChat {
	roomID: string
	windowOpened: boolean
}

enum BusinessRoomType {
	ClientBarber = 'client-barber',
    BusinessAdmin = 'business-admin',
    BusinessAllPros = 'business-all-pros',
    BusinessBarber = 'business-barber',
    BusinessClient = 'business-client',
}


@Injectable({ providedIn: 'root' })
export class ChatManager {

	activeChatLimit = 1;
	activeChats: Chat[] = [];

	hasUnreadMessages: boolean = false;

	liveQuerySubscription?: any;

	chatReceivedSound: any;

	// For creating new client-barber room.
	activeLiveProList: BusinessBarberObj[];
	selectedActiveLiveProID: string;
	clientIDForNewProChat: string;

	constructor(
		private alert: Alert,
		private loadingCtrl: LoadingController,
		private actionSheetCtrl: ActionSheetController,
	  	private storage: Storage,
		private ref: ApplicationRef,
		private observables: ObservableService,
	) {
		this.reloadFromSettings();
		this.setupSound();
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		if (this.liveQuerySubscription) {
			this.liveQuerySubscription.unsubscribe();
		}
	}

	logOut() {
		if (this.liveQuerySubscription) {
			this.liveQuerySubscription.unsubscribe();
		}
		this.activeChats = [];
	}

	private setupSound() {
	    this.chatReceivedSound = document.createElement('audio');
	    this.chatReceivedSound.src = 'assets/sounds/chat-received.mp3';
	    this.chatReceivedSound.setAttribute('preload', 'auto');
	    this.chatReceivedSound.setAttribute('controls', 'none');
	    this.chatReceivedSound.volume = 0.5;
	    this.chatReceivedSound.style.display = 'none';
	    document.body.appendChild(this.chatReceivedSound);
	}

	private playChatReceivedSound() {
	    this.chatReceivedSound.play();
	}

	setupLiveQuery() {

	  	const query = new (Parse.Query as any)('LiveMessage');

	  	query.subscribe().then((subscription: any) => {
	    	this.liveQuerySubscription = subscription

	    	this.liveQuerySubscription.on('error', (msg: any) => { });

	    	this.liveQuerySubscription.on('create', (msg: any) => {
				this.observables.publishLiveMessageCreate(msg)
	    		this.newMessage(msg.get('roomID'));
	    	});

	    	this.liveQuerySubscription.on('update', (msg: any) => {
					this.observables.publishLiveMessageUpdate(msg)
	    	});
	  	});
	}

	private reloadFromSettings() {

		this.storage.get(Constants.CHAT_MANAGER_SETTINGS).then((val) => {
		  	if (val) {

		  		let savedChats = val.savedChats || []

		  		for (var i = 0; i < savedChats.length && i < this.activeChatLimit; i++) {
		  			let c = savedChats[i]

	  				this.loadChat(c.roomID, c.windowOpened, (chat: Chat) => {

						this.activeChats.push(chat)
	  					this.ref.tick()

	  				}, (errMsg: string) => {
	  					console.error('Could not open saved chat rooms.')
	  					console.error(errMsg)
	  					if (errMsg.includes('Object not found.')) {
	  						// Carried over from previous account. Remove stored chats.
	  						this.clearSettings();
	  					}
	  				})
		  		}
		  	}
		});
	}

	private saveSettings() {

		var savedChats: SavedChat[] = []

		for (var i = 0; i < this.activeChats.length; i++) {
			savedChats.push({
				roomID: this.activeChats[i].roomID,
				windowOpened: this.activeChats[i].windowOpened,
			})
		}

		let settings = {
			savedChats: savedChats,
		}

		this.storage.set(Constants.CHAT_MANAGER_SETTINGS, settings)
	}

	clearSettings() {

		let settings = {
			savedChats: [],
		}

		this.storage.set(Constants.CHAT_MANAGER_SETTINGS, settings)
	}

	// getNumUnreadMessages() {

	// 	RoomTasks.getNumUnreadMessages((numUnreadMessages: number) => {
	// 	  this.numUnreadMessages = numUnreadMessages
	// 	  this.ref.tick()
	// 	}, (errorMsg: string) => {
	// 	  console.error(errorMsg)
	// 	})
	// }

	updateRoomInfos() {
		for (var i = 0; i < this.activeChats.length; i++) {
			this.updateRoomInfo(this.activeChats[i])
		}
	}

	private updateRoomInfo(activeChat: Chat) {

		RoomTasks.getRoomInfo(activeChat.roomID, (room: Room) => {

			activeChat.room = room
			this.ref.tick()
			this.observables.publishUpdateChatRoomInfo(room.id)

		}, (errMsg: string) => {
			console.error('Could not update room info')
			console.error(errMsg)
		})
	}

	getHasUnreadMessages() {

		RoomTasks.hasUnreadMessages((hasUnreadMessages: boolean, firstUnreadRoomID?: string) => {

		  this.hasUnreadMessages = hasUnreadMessages
		  if (hasUnreadMessages && firstUnreadRoomID) {
		  	this.newMessage(firstUnreadRoomID)
		  }

		  this.ref.tick()
		}, (errorMsg: string) => {
		  console.error(errorMsg)
		})
	}

	newUnreadMessageForRoom(roomID: string) {

		for (var i = 0; i < this.activeChats.length; i++) {
			if (this.activeChats[i].roomID == roomID) {
				this.activeChats[i].numUnreadMessages++
				break
			}
		}

		this.ref.tick()
	}

	resetUnreadMessagesForRoom(roomID: string) {
		for (var i = 0; i < this.activeChats.length; i++) {
			if (this.activeChats[i].roomID == roomID) {
				this.activeChats[i].numUnreadMessages = 0
				break
			}
		}

		// this.ref.tick()
	}

	private newMessage(roomID: string) {
		let isActive = this.isChatActive(roomID)

		if (isActive) {

			// 	- Already updated. Do nothing.

		} else {

			let hasActiveChats = this.activeChats.length > 0

			if (hasActiveChats) {

				// Inactive, has active chats.
				// 	- Add Rooms unread message dot.
				this.hasUnreadMessages = true
				this.ref.tick()

			} else {
				this.openClosedUnreadChat(roomID)
			}
		}
	}

	private openClosedUnreadChat(roomID: string) {

		// Inactive, no active chats.
		// 	- Open "closed" chat window, with # unread == 1.

		let windowOpened = false

		this.loadChat(roomID, windowOpened, (chat: Chat) => {

			chat.numUnreadMessages = 1

			this.activeChats = [chat]

			this.saveSettings()
			this.ref.tick()
			this.playChatReceivedSound()

		}, (errMsg: string) => {
			this.error(errMsg)
		})
	}

	async openNewChat(roomID: string) {

		if (this.isChatActive(roomID)) {
			this.openChatWindow(roomID)
			return
		}

		let loading = await this.loadingCtrl.create({
			message: 'Opening chatroom...'
		})
		loading.present()

		let windowOpened = true

		this.loadChat(roomID, windowOpened, (chat: Chat) => {

			loading.dismiss()

			if (this.activeChats.length == this.activeChatLimit) {
				// Replace last chat.
				var lastIndex = this.activeChatLimit - 1;
				this.activeChats[lastIndex] = chat
			} else {
				this.activeChats = [chat]
			}

			this.saveSettings()
			this.ref.tick()

		}, (errMsg: string) => {
			loading.dismiss()
			this.error(errMsg)
		})
	}

	private loadChat(
		roomID: string,
		windowOpened: boolean,
		successCallback: (chat: Chat) => void,
		errorCallback: (errMsg: string) => void) {

		RoomTasks.getRoomInfo(roomID, (room: Room) => {

			if (!room) {
				errorCallback('Chatroom could not be loaded.');
				return;
			}

			let chat = {
				roomID: roomID,
				room: room,
				windowOpened: windowOpened,
				numUnreadMessages: 0,
			}

			successCallback(chat)

		}, (errMsg: string) => {
			errorCallback(errMsg)
		})
	}

	toggleChatOpened(roomID: string, event?: any) {

		if (event) {
			event.stopPropagation()
		}

		for (var i = 0; i < this.activeChats.length; i++) {
			if (this.activeChats[i].roomID == roomID) {
				this.activeChats[i].windowOpened = !this.activeChats[i].windowOpened
				break
			}
		}

		this.saveSettings()
		this.ref.tick()
	}

	chatToggledOpen(chat: any, event?: any) {
		if (chat.windowOpened) {
			const roomID = chat.room.id
			this.observables.publishChatToggledOpen(roomID)
		}
	}

	closeChat(roomID: string, event?: any) {

		if (event) {
			event.stopPropagation()
		}

		for (var i = 0; i < this.activeChats.length; i++) {
			if (this.activeChats[i].roomID == roomID) {
				this.activeChats.splice(i, 1);
			}
		}

		this.saveSettings()
		this.ref.tick()
	}

	minimizeAllChats() {

		for (var i = 0; i < this.activeChats.length; i++) {
			this.activeChats[i].windowOpened = false
		}

		// DONT SAVE SETTINGS.
		this.ref.tick()
	}




	private openChatWindow(roomID: string) {

		const activeChat = this.getActiveChatIfExists(roomID);
		if (activeChat) {
			activeChat.windowOpened = true;
		}

		this.saveSettings()
		this.ref.tick()
	}

	private isChatActive(roomID: string) {

		if (this.getActiveChatIfExists(roomID)) {
			return true;
		}

		return false;
	}

	private getActiveChatIfExists(roomID: string): Chat {

		for (var i = 0; i < this.activeChats.length; i++) {
			if (this.activeChats[i].roomID === roomID) {
				return this.activeChats[i];
			}
		}

		return undefined;
	}




	// Business chat options

	async openBusinessChatOptions(roomID: string, event?: any) {

		if (event) {
			event.stopPropagation()
		}

		const activeChat = this.getActiveChatIfExists(roomID);
		if (!activeChat || !activeChat.room) {
			return;
		}

		let buttons = []

		const room = activeChat.room;
		const roomType = activeChat.room.type;

		switch (roomType as BusinessRoomType) {
			case BusinessRoomType.ClientBarber: {
				if (room.client) {
					buttons.push(
						this.openClientChatButton(room.client.id, room.client.fullName)
					)
				}
				if (room.barber) {
					buttons.push(
						this.openProChatButton(room.barber.id, room.barber.fullName)
					)
				}
				if (room.client) {
					buttons.push(
						this.openNewClientBarberChatButton(room.client.id, room.client.fullName)
					)
				}
				break;
			}
			case BusinessRoomType.BusinessAdmin: {
				buttons.push(
					this.noActionAvailableButton()
				)
				break;
			}
			case BusinessRoomType.BusinessAllPros: {
				buttons.push(
					this.noActionAvailableButton()
				)
				break;
			}
			case BusinessRoomType.BusinessBarber: {
				// buttons.push(
				// 	this.viewProProfileButton()
				// )
				break;
			}
			case BusinessRoomType.BusinessClient: {
				if (room.client) {
					buttons.push(
						this.openNewClientBarberChatButton(room.client.id, room.client.fullName)
					)
				}
				break;
			}
		}

		buttons.push({
			text: 'Cancel',
			role: 'cancel',
			handler: () => {
			}
		})

		let actionSheet = await this.actionSheetCtrl.create({
			header: 'Chat options',
			buttons: buttons
		});

		actionSheet.present();
	}

	private noActionAvailableButton() {
		return {
			text: `No actions available: return to chat`,
			handler: () => {
			}
		}
	}

	private openProChatButton(barberID: string, proFullName: string) {
		return {
			text: `Open chat with ${proFullName}`,
			handler: async () => {

				const loading = await this.loadingCtrl.create();
				loading.present();

				RoomTasks.createBusinessBarberRoom(barberID, (roomID: string) => {
					loading.dismiss();
					this.openNewChat(roomID)
				}, (err: string) => {
					loading.dismiss();
					this.error(err);
				})
			}
		}
	}

	private openClientChatButton(clientID: string, clientFullName: string) {
		return {
			text: `Open chat with ${clientFullName}`,
			handler: async () => {

				const loading = await this.loadingCtrl.create();
				loading.present();

				RoomTasks.createBusinessClientRoom(clientID, (roomID: string) => {
					loading.dismiss();
					this.openNewChat(roomID)
				}, (err: string) => {
					loading.dismiss();
					this.error(err);
				})
			}
		}
	}

	private openNewClientBarberChatButton(clientID: string, clientFullName: string) {
		return {
			text: `Open chat with ${clientFullName} and a Pro`,
			handler: () => {
				this.openSelectorForClientBarberChat(clientID)
			}
		}
	}

	private async openSelectorForClientBarberChat(clientID: string) {

		this.clientIDForNewProChat = clientID;

		const loading = await this.loadingCtrl.create();
		loading.present();

		BusinessTasks.pullLiveBusinessPros((liveBusinessPros: BusinessBarberObj[]) => {

			this.activeLiveProList = liveBusinessPros;

			this.ref.tick();
			loading.dismiss();

			// Handled in app.component.ts, which has a
			//  reference to the selector in app.component.html.
			this.observables.publishOpenBusinessProChatSelector()

		}, (err: string) => {
			this.error(err);
			loading.dismiss();
		})
	}

	async selectActiveLivePro() {

		const clientID = this.clientIDForNewProChat;
		if (!clientID) {
			this.error('No client selected');
			return;
		}
		const barberID = this.selectedActiveLiveProID;
		if (!barberID) {
			this.error('No Pro selected');
			return;
		}

		const loading = await this.loadingCtrl.create();
		loading.present();

		RoomTasks.createClientBarberRoom_Business(clientID, barberID, (roomID: string) => {
			loading.dismiss();
			this.openNewChat(roomID)
		}, (err: string) => {
			loading.dismiss();
			this.error(err);
		})
	}





	// Helpers

	private error(msg: string) {
	    this.alert.show('Error', msg)
	}

}