
import { ImageData } from '../models/image-data'

export class ImageScaler {

  private static scaleToSquare(
    uri: string, 
    dimensions: number,
    successCallback: (imgURI: string) => void) {

    // console.log(uri)

    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = uri;

    img.onload = () => {

      console.log('img on load')

      let width = img.width
      let height = img.height

      var aspectRatio = width / height
      var origLeft, origTop, origDims;
      
      if (aspectRatio > 1) {
        origTop = 0
        origLeft = ((width - height) / 2)
        origDims = height
      } else {
        origLeft = 0
        origTop = ((height - width) / 2)
        origDims = width
      }

      var canvas = document.createElement('canvas');
      canvas.width = dimensions;
      canvas.height = dimensions;

      var context = canvas.getContext( '2d' );

      context.drawImage(img, 
        origLeft, origTop, 
        origDims, origDims, 
        0, 0, // place at origin in canvas
        dimensions, dimensions); // with these dimensions

      var dataURL = canvas.toDataURL('image/jpeg')
      // console.log(dataURL)

      successCallback(dataURL);
    }
  }


  static scaleImage(imageData: string,
    successCallback: (data: ImageData) => void,
    errorCallback: (errorMsg: string) => void) {

    console.log('pre-scale')

    this.scaleThumbnail(imageData, (thumbDataString) => {
      this.scaleProfPic(imageData, (mainDataString) => {
        // let thumbBase64Image = 'data:image/jpeg;base64,' + thumbDataString;
        // let base64Image = 'data:image/jpeg;base64,' + mainDataString;

        console.log('scaled both')

        let data: ImageData = {thumbnail: thumbDataString, pic: mainDataString};
        successCallback(data);

      });
    });
  }

  static scaleProfPic(
    imgURI: string, 
    successCallback: (imgURI: string) => void) {

    ImageScaler.scaleToSquare(imgURI, 400, (newImgURI: string) => {
      successCallback(newImgURI);
    });
  }

  static scaleThumbnail(
    imgURI: string, 
    successCallback: (imgURI: string) => void) {

    ImageScaler.scaleToSquare(imgURI, 50, (newImgURI: string) => {
      successCallback(newImgURI);
    });
  }

}