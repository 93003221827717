import { CloudCode } from './cloudcode';
import { Address } from '../models/address';

export interface BusinessLocation {
  id?: string
  name: string
  address: Address
  stripeAccountID?: string
}

export interface BusinessBarberObj {
  id: string;
  active: boolean;
  completedOnboarding: boolean;
  inDemoMode_BusinessProOnly: boolean;
  averageRating: number;
  numRatings: number;
  fullName: string;
  firstName: string;
  lastName: string;
  isoPhoneNumber: string;
  nationalPhoneNumber: string;
  startingPrice: number;
  pic: string;
  link: string;
  serviceIDs: string[];
  // servicePricing: BarberServicePricing;
  // proType: CCProType;
  // currency: Currency;

  // Business and Location info
  businessName: string;
  businessLocationID: string;
  businessLocationName: string;

  // NOTE: excluded SCC values and sub-interfaces.
}

export class BusinessTasks {

  static resetProPassword(
    barberID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('resetProPassword',
      {
        id: barberID
      },
      () => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static deactivatePro(
    barberID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('deactivatePro_Business',
      {
        id: barberID
      },
      () => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static activatePro(
    barberID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('activatePro_Business',
      {
        id: barberID
      },
      () => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static pullLiveBusinessPros(
    successCallback: (liveBusinessPros: BusinessBarberObj[]) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('pullBusinessPros',
      {
        excludeDemoMode: true,
      },
      (liveBusinessPros: BusinessBarberObj[]) => {
        successCallback(liveBusinessPros);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static updateProductInfo(
    productInfo: any,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('updateProductInfo_Business',
      {
        productURL: productInfo.url,
        productPicURL: productInfo.picURL,
        productDescription: productInfo.description,
        productTextMessage: productInfo.textMessage,
      },
      () => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static generateStripeRegisterURL(
    optBusinessLocationID: string,
    successCallback: (url: string) => void,
    errorCallback: (errorMsg: string) => void
  ) {

    CloudCode.run('generateStripeRegisterURL', {
      optBusinessLocationID: optBusinessLocationID,
    },
      (url: string) => {
          successCallback(url)
      },
      (msg: string) => {
          errorCallback(msg)
      });
  }

  static getStripeExpressLoginLink_Business(
    businessLocationID: string,
    successCallback: (url: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('getStripeExpressLoginLink_Business',
      {
        businessLocationID: businessLocationID
      },
      (url: string) => {
        successCallback(url);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static useExistingStripeAccountForBusinessLocation(
    businessLocationID: string,
    stripeAccountID: string,
    successCallback: (url: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('useExistingStripeAccountForBusinessLocation',
      {
        businessLocationID: businessLocationID,
        stripeAccountID: stripeAccountID,
      },
      (url: string) => {
        successCallback(url);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static addBusinessCommissionOption(
    name: string,
    commission: number,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('addBusinessCommissionOption',
      {
        name: name,
        commission: commission,
      },
      () => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static addBusinessProductCommissionOption(
    name: string,
    commission: number,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('addBusinessProductCommissionOption',
      {
        name: name,
        commission: commission,
      },
      () => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static addBusinessLocation(
    name: string,
    address: Address,
    successCallback: (businessLocationID: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('addBusinessLocation',
      {
        name: name,
        address: address,
      },
      (businessLocationID: string) => {
        successCallback(businessLocationID);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static pullBusinessLocations(
    onlyIncludeWithStripeAccounts: boolean,
    successCallback: (locations: BusinessLocation[]) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('pullBusinessLocations',
      {},
      (locations: BusinessLocation[]) => {
        successCallback(locations);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

}