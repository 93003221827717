import { CloudCode } from './cloudcode';
import { Barber } from '../models/barber';

import { Constants } from "../models/constants";

export class BarberService {

    static pullBarberInfo(
        barberLink: string,
        fromEvent: boolean,
        successCallback: (barber: Barber) => void,
        errorCallback: (msg: string) => void
    ) {

        let params = {
            link: barberLink
        }

        let endpoint = 'pullBarberInfo'
        if (fromEvent) {
            endpoint = 'pullBarberInfo_Event'
        }

        CloudCode.run(endpoint, params,
            (rsp: Barber) => {
                successCallback(rsp)
            },
            (msg: string) => {
                errorCallback(msg);
            });

    }

    static pullBarberInfo_ID(
        barberID: string,
        successCallback: (barber: Barber) => void,
        errorCallback: (msg: string) => void
    ) {

        let params = {
            barberID: barberID
        }

        let endpoint = 'pullBarberInfo'

        CloudCode.run(endpoint, params,
            (rsp: Barber) => {
                successCallback(rsp)
            },
            (msg: string) => {
                errorCallback(msg);
            });
    }


    static pullBarberForAppt(
        apptID: string,
        successCallback: (barber: Barber) => void,
        errorCallback: (msg: string) => void
    ) {

        let params = {
            apptID: apptID
        }

        CloudCode.run('pullBarberForAppt', params,
            (rsp: Barber) => {
                successCallback(rsp)
            },
            (msg: string) => {
                errorCallback(msg);
            });

    }

    static pullBarbers(
        barberIDs: string[],
        successCallback: (barbers: Barber[]) => void,
        errorCallback: (msg: string) => void
    ) {

        let params = {
            barberIDs: barberIDs
        }

        CloudCode.run('pullBarbers', params,
            (rsp: Barber[]) => {
                successCallback(rsp)
            },
            (msg: string) => {
                errorCallback(msg);
            });

    }

}
