import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { ImageData } from '../../models/image-data';

import { ProfPicService } from '../../services/prof-pic-service';
import { UserService } from '../../services/user-service';
import { Alert } from "../../services/alert";
import { Constants } from 'src/app/models/constants';
import { ObservableService } from 'src/app/services/observable-service';

@Component({
  selector: 'app-add-profile-pic',
  templateUrl: './add-profile-pic.page.html',
  styleUrls: ['./add-profile-pic.page.scss'],
})
export class AddProfilePicPage implements OnInit {

  hasPhoto = false;
  imgURL = "assets/img/profile-placeholder.png";

  constructor(
    private navCtrl: NavController,
    private alert: Alert,
    private profPicService: ProfPicService,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    public userService: UserService,
    private observables: ObservableService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.userService.user && this.userService.user.profPicURL) {
      this.hasPhoto = true;
      this.imgURL = this.userService.user.profPicURL; // May be populated if signed up from facebook
    }
  }

  onUploadImage() {

    let showActionSheet = true

    this.profPicService.uploadPhoto(showActionSheet, (data: ImageData) => {
        this.userService.saveProfPicToParse(data, () => {
            
            this.hasPhoto = true
            this.imgURL = this.userService.user.profPicURL;
            this.changeRef.detectChanges()

        }, (errorMsg: string) => {
            this.error(errorMsg)
        });

        
    }, (errorMsg) => {
      this.error(errorMsg)
    });
  }

  onContinue() {
    this.goToEnterPhoneNumber()
  }

  onSkip() {
    this.goToEnterPhoneNumber()
  }

  async goToEnterPhoneNumber() {
    await this.modalCtrl.dismiss()
    this.observables.publishGoToEnterPhoneNumber()
  }


  private error(msg: string){
    this.alert.show('Error', msg, 'Ok');
  }

}
