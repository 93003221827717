import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController, ActionSheetController, LoadingController, PopoverController } from '@ionic/angular';

import { Room, Sender } from '../../models/message'

import { UserService } from '../../services/user-service'
import { RoomTasks } from '../../services/room-tasks'
import { Alert } from '../../services/alert';
import { Util } from '../../services/util';
import { ObservableService } from 'src/app/services/observable-service';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.page.html',
  styleUrls: ['./rooms.page.scss'],
})
export class RoomsPage implements OnInit {

  placeholderImg = "assets/img/profile-placeholder.png";

  timestamp = Util.timestamp

  rooms: Room[]

  showMutedRooms = false
  hasAdminChat = false
  hasConciergeChat = false

  // Subscriptions
  private liveMessageCreateSubscription: any;

  constructor(
    private navCtrl: NavController,
    private actionSheetCtrl: ActionSheetController,
    public userService: UserService,
    private alert: Alert,
    private loadingCtrl: LoadingController,
    private changeRef: ChangeDetectorRef,
    private popoverCtrl: PopoverController,
    private observables: ObservableService,
  ) {}

  ngOnInit() {
    this.liveMessageCreateSubscription = this.observables.liveMessageCreate.subscribe(() => {
      this.getRooms()
    })
  }

  ngOnDestroy() {
    this.liveMessageCreateSubscription.unsubscribe()
  }

  ngAfterViewInit() {
    this.getRooms()
  }

  getRooms(event?: any) {

    let func = RoomTasks.pullSubscribedRooms

    func(this.showMutedRooms, (rooms: Room[]) => {

      this.rooms = rooms

      if (!this.hasAdminChat || !this.hasConciergeChat) {
        for (var i = 0; i < rooms.length; i++) {
          if (rooms[i].type == 'client-admin' || rooms[i].type == 'business-admin') {this.hasAdminChat = true}
          if (rooms[i].type == 'client-concierge') {this.hasConciergeChat = true}
        }
      }

      if (event) {
        event.target.complete();
      }
      this.changeRef.detectChanges();


    }, (err: string) => {

      this.error(err)
      if (event) {
        event.target.complete();
      }
    })
  }

  async goToConciergeChat() {
    if (this.userService.user.conciergeChatRoomID) {
      this.goToChat(this.userService.user.conciergeChatRoomID)
      return
    }

    let loading = await this.loadingCtrl.create()
    loading.present()

    RoomTasks.createClientConciergeRoom((roomID: string) => {

      loading.dismiss();
      this.goToChat(roomID)

    }, (err: string) => {

      loading.dismiss();
      this.error(err)

    })
  }

  async goToAdminChat() {
    this.observables.publishOpenNewAdminChat();
  }

  goToChat(roomID: string) {
    console.log('open chat: ' + roomID)
    this.observables.publishOpenNewChat(roomID)
    this.popoverCtrl.dismiss()
  }

  getSenderName(sender: Sender) {

    if (sender.id == this.userService.user.id) {
      return 'You'
    }

    return sender.firstName
  }


  async openOptions() {
    var buttons = []

    if (!this.showMutedRooms) {
      buttons.push({
        text: 'Show unsubscribed rooms',
        handler: () => {
          this.showMutedRooms = true
          this.getRooms()
        }
      })
    } else {
      buttons.push({
        text: 'Show subscribed rooms',
        handler: () => {
          this.showMutedRooms = false
          this.getRooms()
        }
      })
    }

    buttons.push({
       text: 'Cancel',
       role: 'cancel',
       handler: () => {
       }
    })

    let actionSheet = await this.actionSheetCtrl.create({
       header: 'Options',
       buttons: buttons
     });

     actionSheet.present();
  }


  // Helpers

  private error(msg: string) {
    this.alert.show('Error', msg)
  }

}
