import { Injectable } from '@angular/core';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { Storage } from '@ionic/storage';
import { Constants } from "./../models/constants";

@Injectable({ providedIn: 'root' })
export class AppRateService {

  constructor(
    private appRate: AppRate,
    private storage: Storage
  ) {

    if (!this.appRate.preferences) {
      return
    }

    this.appRate.preferences.storeAppURL = {
      android: 'market://details?id=co.getshortcut.vibes',
      ios: 'undefined',
      windows: 'undefined',
      blackberry: 'undefined',
      windows8: 'undefined'
    };

    this.appRate.preferences.callbacks = {
      onButtonClicked: (buttonIndex: any) => {
        if (buttonIndex === 1 || buttonIndex === 2) {
          // User tapped "Rate" or "No thanks" on the prompt. Don't show it again.
          this.storage.set(Constants.APP_RATED, true);
        }
      },
      handleNegativeFeedback: () => {
        console.log('Can handle negative feedback here.');
      },
      onRateDialogShow: (callback: any) => {
        console.log('On rate dialog shown');
      }
    };
  }

  goToRating() {
    this.storage.set(Constants.APP_RATED, true);
		this.appRate.navigateToAppStore();
  }

  prompt() {
    if (Constants.BROWSER_MODE) return

		let immediately = true;
  	this.appRate.promptForRating(immediately);
  }

  promptIfNeeded() {
    if (Constants.BROWSER_MODE) return

    this.storage.get(Constants.APP_RATED).then((val) => {
      if (!val) {
        this.prompt();
      }
    });
  }

}
