import { Util } from "../services/util";

// 
// This model is separate from the appointment-time, because the main screen must have
//  independent behavior of the selected timeslot. 
//

export class MainScreenSelectedWeek {

    private _weekNumber: number;

    text: string;

    constructor(weekNumber?: number) {
        this.weekNumber = weekNumber || -1;
    }
    
    get weekNumber(): number {
        return this._weekNumber
    }
    
    set weekNumber(weekNumber: number) {
        this._weekNumber = weekNumber;
        this.text = this.getMainScreenText()
    }

    private getMainScreenText() {

        if (this.weekNumber == -1) {
            return 'Any time'
        }

        if (this.weekNumber == 0) {
            return 'This week'
        }

        let weekDate = Util.addHours(new Date(), 24 * 7 * this.weekNumber)
        let weekDateString = Util.shortMonthDate(weekDate)
        return 'Week of ' + weekDateString
    }
}
