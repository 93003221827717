<div id="wrapper" *ngIf="receiptData">

  <!-- cancel fee -->
  <div *ngIf="receiptData.cancelled">
    <p>Cancellation fee</p>
    <p class="right">{{formatCost(receiptData.costs.charge - receiptData.costs.tax)}}</p>
  </div>

  <!-- service charges -->
  <div *ngIf="receiptData.serviceTypeList && !receiptData.cancelled">
    <div *ngFor="let s of receiptData.serviceTypeList">
      <p>{{s.title}} (x{{s.quantity}})</p>
      <p class="right">{{formatCost(s.price * s.quantity)}}</p>
    </div>
  </div>

  <!-- tax -->
  <p>Tax</p>
  <p class="right">{{formatCost(receiptData.costs.tax)}}</p>

  <!-- travel upcharge -->
  <div *ngIf="receiptData.costs.travelUpcharge">
    <p>Travel upcharge</p>
    <p class="right">{{formatCost(receiptData.costs.travelUpcharge)}}</p>
  </div>

  <!-- convenience fee -->
  <div *ngIf="receiptData.costs.convenienceFee">
    <p>Convenience fee</p>
    <p class="right">{{formatCost(receiptData.costs.convenienceFee)}}</p>
  </div>

  <!-- promo code -->
  <div *ngIf="receiptData.costs.promoAmt">
    <p>Promo</p>
    <p class="right">-{{formatCost(receiptData.costs.promoAmt)}}</p>
  </div>

  <!-- credit -->
  <div *ngIf="receiptData.costs.creditAmt">
    <p>Credit</p>
    <p class="right">-{{formatCost(receiptData.costs.creditAmt)}}</p>
  </div>

  <!-- credit -->
  <div *ngIf="receiptData.costs.refundAmt">
    <p>Refund</p>
    <p class="right">-{{formatCost(receiptData.costs.refundAmt)}}</p>
  </div>

  <hr>

  <!-- charge -->
  <p>Total charge
    <span *ngIf="receiptData.customer.cardBrand !== ''">({{receiptData.customer.cardBrand}}
      *{{receiptData.customer.last4}})</span>
  </p>
  <p class="right">{{formatCost(receiptData.costs.charge)}}</p>

  <!-- barber tip -->
  <div *ngIf="receiptData.costs.tip">
    <p>Tip</p>
    <p class="right">{{formatCost(receiptData.costs.tip)}}</p>
  </div>

  <!-- business comp -->
  <div *ngIf="userService.isBusiness && receiptData.costs.businessComp">

    <hr>

    <p>Business commission</p>
    <p class="right">{{formatCost(receiptData.costs.businessComp)}}</p>
  </div>

</div>