import { Component, OnInit, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { PopoverController, LoadingController, IonSearchbar } from '@ionic/angular';

import { Address } from '../../models/address';

import { Alert } from '../../services/alert';
import { Timezones } from '../../services/timezones';
import { CurrentLocationService } from '../../services/current-location-service';

declare var google;

@Component({
  selector: 'app-enter-address',
  templateUrl: './enter-address.page.html',
  styleUrls: ['./enter-address.page.scss'],
})
export class EnterAddressPage implements OnInit {

  @ViewChild('searchBar', { read: IonSearchbar }) searchBar: IonSearchbar;

  autocompleteItems: any;
  autocomplete: any;
  autocompleteService = new google.maps.places.AutocompleteService();

  submitting = false

  constructor(
    public popoverCtrl: PopoverController,
    private loadingCtrl: LoadingController,
    public alert: Alert,
    private zone: NgZone,
    private currentLocationService: CurrentLocationService,
    private changeRef: ChangeDetectorRef,
  ) {
    this.autocompleteItems = [];
    this.autocomplete = {
      query: ''
    };
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.searchBar.setFocus();
  }

  cancel() {
    this.popoverCtrl.dismiss({});
  }

  async chooseItem(item: any) {

    if (this.submitting) { return }

    let loading = await this.loadingCtrl.create()
    loading.present()
    this.submitting = true

    this.getAddressFromItem(item, loading, (address: Address) => {

      loading.dismiss()
      this.submitting = false
      this.popoverCtrl.dismiss({
        address,
        isFromGPS: false
      });

    }, (msg: string) => {
      loading.dismiss()
      this.submitting = false
      this.alert.show('Uh Oh', msg)
    })
  }

  updateSearch() {

    if (this.autocomplete.query == '') {
      this.autocompleteItems = [];
      return;
    }

    this.changeRef.detectChanges()
    
    let self = this;
    this.autocompleteService.getPlacePredictions({ input: this.autocomplete.query, types: [] }, (predictions, status) => {
      // console.log(predictions)
      if (!predictions) {
        return
      }

      self.autocompleteItems = [];
      self.zone.run(() => {
        predictions.forEach(function (prediction) {
          self.autocompleteItems.push(prediction);
        });
        self.changeRef.detectChanges()
      });
    });
  }

  async getLocation() {

    let loading = await this.loadingCtrl.create({
      message: 'Getting location...'
    })
    loading.present()

    this.currentLocationService.fetchCurrentLocation((address: Address) => {

      loading.dismiss()
      this.popoverCtrl.dismiss({
        address,
        isFromGPS: true
      });

    }, (err: string) => {
      loading.dismiss()
      this.alert.show('Error', err)
    });
  }

  private getAddressFromItem(
    item: any,
    loading: any,
    success: (address: Address) => void,
    error: (msg: string) => void) {

    let placesService = new google.maps.places.PlacesService(document.createElement('div'));

    placesService.getDetails({ placeId: item.place_id }, (place: any, status: any) => {
      if (status == 'OK') {

        let components = place.address_components;
        let streetNumber = this.extractFromAddress(components, 'street_number')
        let streetName = this.extractFromAddress(components, 'route')
        let city = this.extractFromAddress(components, 'locality')
        let state = this.extractFromAddress(components, 'administrative_area_level_1')
        let zip = this.extractFromAddress(components, 'postal_code')
        let country = this.extractFromAddress(components, 'country')

        if (!city) {
          // If city wasn't found under "locality", search for city name under "sublocality"
          city = this.extractFromAddress(components, 'sublocality')
        } 
        if (!city) {
          // If city wasn't found under "locality" or "sublocality"
          city = this.extractFromAddress(components, 'postal_town')
        }
        if (!city) {
          // If city wasn't found under "postal_town"
          city = this.extractFromAddress(components, 'administrative_area_level_3')
        }

        // console.log(components)
        // console.log(streetName)
        // console.log(city)
        // console.log(state)
        // console.log(zip)
        // console.log(country)

        if (!streetName || !city || !state || !zip) {
          // console.log(components)
          error("Selection invalid. Please choose a valid U.S. or U.K. address.")
          return
        }
        if (zip.length < 2) {
          this.alert.show('Uh Oh', "Please select an address with a zip/postal code.")
          return
        }

        let address = {
          street: streetName,
          city: city,
          state: state,
          zip: zip,
          country: country,
          coordinates: {
            latitude: place.geometry.location.lat() || 0,
            longitude: place.geometry.location.lng() || 0
          }
        }

        if (streetNumber) {
          address.street = `${streetNumber} ${streetName}`
          this.addressSelected(address, success, error)
        } else {
          loading.dismiss()
          this.alert.showPrompt('Please re-enter street number', null, 'streetNumber', 'Street number', 'number', 'Save', (data) => {
            let newStreetNumber = data.streetNumber
            if (newStreetNumber && newStreetNumber != '') {
              address.street = `${newStreetNumber} ${streetName}`
              this.addressSelected(address, success, error)
            } else {
              error('You must provide a street number')
            }
          })
        }

      } else {
        console.log(status)
        if (status == 'NOT_FOUND') {
          status = 'Google cannot find details about your address. Please contact support.'
        }
        error(status)
      }
    })
  }

  private extractFromAddress(components: any[], type: string) {
    for (var i = 0; i < components.length; i++) {
      for (var j = 0; j < components[i].types.length; j++) {
        if (components[i].types[j] == type) return components[i].short_name;
      }
    }

    return undefined;
  }

  private addressSelected(
    address: Address,
    success: (address: Address) => void,
    error: (msg: string) => void
  ) {

    if (address.coordinates.latitude !== 0 && address.coordinates.longitude !== 0) {
      success(address)
      return
    }

    // Use CC func instead.
    Timezones.coordinatesForAddress(address, (coordinates: any) => {

      address.coordinates = {
        latitude: Number(coordinates.latitude),
        longitude: Number(coordinates.longitude)
      }

      success(address)

    }, (errMsg: string) => {
      this.alert.show("Error", errMsg);
    })
  }

}
