import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';

import { Constants } from '../models/constants';
import { ImageData } from '../models/image-data'

import { AlertController, LoadingController, ActionSheetController } from "@ionic/angular";

import { BrowserCamera } from './vibes-browser/camera';
import { ImageScaler } from "./image-scaler";

@Injectable({ providedIn: 'root' })
export class ProfPicService {

	constructor(
	      private camera: Camera,
	      private browserCamera: BrowserCamera,
		  private alertCtrl: AlertController,
		  private loadingCtrl: LoadingController,
		  private actionSheetCtrl: ActionSheetController,
		  private transfer: FileTransfer,
		  private file: File
	) {}


	  async uploadPhoto(
	    showActionSheet: boolean,
	    successCallback: (data: ImageData) => void,
	    errorCallback: (errorMsg: string) => void
	  ) {

	  	if (Constants.BROWSER_MODE) {
	  		console.log('browser mode')
	  		let shouldScale = true
	  		this.browserCamera.selectPhotoFromLibrary(shouldScale, successCallback, errorCallback);
	  		return
	  	}

	    let buttons: any[] = [
	      {
	        text: "Take New Photo",
	        handler: (() => {
	          this.takeNewPhoto(successCallback, errorCallback);
	        })
	      },
	      {
	        text: "Select from Library",
	        handler: (() => {
	          this.choosePhotoFromLibrary(successCallback, errorCallback);
	        })
	      },
	      {
	        text: "Cancel",
	        handler: (() => {})
	      }
	    ]
	    if (showActionSheet) {
	      let actionSheet = await this.actionSheetCtrl.create({
	        header: 'Add Image',
	        buttons: buttons
	      });

	      actionSheet.present();
	    } else {
	      let alert = await this.alertCtrl.create({
	        header: 'Add Image',
	        message: null,
	        buttons: buttons
	      });
	  
	      alert.present();
	    }
	  }

	  downloadAndUploadProfPic(imgURL: string,
	  	successCallback: (data: ImageData) => void,
	    errorCallback: (errorMsg: string) => void) {

	  	let fileTransfer: FileTransferObject = this.transfer.create();
	  	fileTransfer.download(imgURL, this.file.dataDirectory + 'file.jpeg').then((entry) => {
	  	  entry.file((file:any) => {
	  	    let reader = new FileReader();
	  	    reader.onloadend = (readerEvent: any) => {
	  	      
	  	      ImageScaler.scaleImage(reader.result as string, 
	  	        (data: ImageData) => {
	  	        	successCallback(data)
	  	        }, 
	  	        (errorMsg: string) => {
			  		errorCallback(errorMsg)
	  	        });
	  	    };
	  	    reader.readAsDataURL(file);
	  	  });
	  	}, (errorMsg: string) => {
	  		errorCallback(errorMsg)
	  	});

	  }





	  //  PRIVATE METHODS

	  private async takeNewPhoto(
	    successCallback: (data: ImageData) => void,
	    errorCallback: (errorMsg: string) => void
	  ) {

	    let options: CameraOptions = {
	      quality: 100,
	      targetWidth: 400,
	      targetHeight: 400,
	      allowEdit: true,
	      destinationType: this.camera.DestinationType.DATA_URL,
	      encodingType: this.camera.EncodingType.JPEG,
	      mediaType: this.camera.MediaType.PICTURE,
	      correctOrientation: true
	    }

	    let loading = await this.loadingCtrl.create();
	    loading.present();

	    this.camera.getPicture(options).then((imageData) => {
	      
	      let base64Img = 'data:image/jpeg;base64,' + imageData;
	      
	      ImageScaler.scaleImage(base64Img, (data: ImageData) => {
            loading.dismiss();
            successCallback(data);
          }, (errorMsg: string) => {
            loading.dismiss();
            errorCallback(errorMsg);
	      });
	    }, (err: string) => {
	      loading.dismiss();
	      errorCallback(err);
	    });
	  }

	  private async choosePhotoFromLibrary(
	    successCallback: (data: ImageData) => void,
	    errorCallback: (errorMsg: string) => void
	  ) {
	    let options: CameraOptions = {
	      quality: 100,
	      targetWidth: 400,
	      targetHeight: 400,
	      allowEdit: true,
	      destinationType: this.camera.DestinationType.DATA_URL,
	      encodingType: this.camera.EncodingType.JPEG,
	      mediaType: this.camera.MediaType.PICTURE,
	      correctOrientation: true,
	      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
	    }

	    let loading = await this.loadingCtrl.create();
	    loading.present();

	    this.camera.getPicture(options).then((imageData) => {

	      let base64Img = 'data:image/jpeg;base64,' + imageData;

	      ImageScaler.scaleImage(base64Img, (data: ImageData) => {
            loading.dismiss();
            successCallback(data);
          }, (errorMsg: string) => {
            loading.dismiss();
            errorCallback(errorMsg);
	      });
	    }, (err: string) => {
	      loading.dismiss();
	      errorCallback(err);
	    });
	  }

	  

}