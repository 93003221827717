import * as dateFormat from "dateformat";
import * as moment from "moment";

import { Address } from "../models/address";
import { ProductQuantityList } from "./product-sale-request-tasks";

import { Timezone } from "./timezones";

export class Util {

  // date & time manipulators

  static isNavigationError(msg: string) {
    return msg.includes('Attempt to use a destroyed view')
  }

  static copyByValue(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static addMinutes(d: Date, m: number): Date {
    var n = new Date(d);
    n.setMinutes(n.getMinutes() + m);
    return n;
  }

  static secondsBetween(start: any, end: any) {
    var diffMs = (end - start);
    return Math.floor(diffMs / 60);
  }

  static minutesBetween(start: any, end: any) {
    var diffMs = (end - start);
    return Math.floor(diffMs / 60000);
  }

  static daysAgo(n: number) {
    let nDaysAgo = new Date();
    nDaysAgo.setTime(nDaysAgo.getTime() - 1000 * 60 * 60 * 24 * n);
    return nDaysAgo;
  }

  static prettyTime(d: Date, timezoneAbbreviation?: string) {
    var date = (new Date(d)).toLocaleTimeString();
    var split = date.split(':');
    var prettyString = split[0]+":"+split[1]+""+split[2].substring(3,5);
    prettyString = prettyString.toLowerCase()
    if (timezoneAbbreviation) prettyString += ' ' + timezoneAbbreviation;
    return prettyString;
  }

  static roundToNext5Minutes(d: Date) {
    d = Util.addMinutes(d, 2.5);
    var coeff = 1000 * 60 * 5;
    return new Date(Math.round(d.getTime() / coeff) * coeff);
  }

  static roundToTwo(num: number, showDecimals: boolean): number | string {
    return Util.roundToNDecimals(num, 2, showDecimals);
  }

  static roundToNDecimals(value: number, n: number, showDecimals: boolean): number | string {
    const rounded = Number(+(Math.round(Number(value + 'e+' + n)) + 'e-' + n));
    return showDecimals ? rounded.toFixed(n) : +rounded.toFixed(n);
  }

  static timestamp(d: Date) {

    var todayMidnight = new Date()
      todayMidnight.setHours(0,0,0,0)
    var oneDayAgoMidnight = Util.addHours(todayMidnight, -24)
    var beginningOfYear = new Date((new Date()).getFullYear(), 0, 1);

    if (d < todayMidnight && d >= oneDayAgoMidnight) {
      return 'Yesterday'
    } else if (d > todayMidnight) {
      return Util.prettyTime(d)
    } else if (d < beginningOfYear) {
      return moment(d).format('MMM Do, YYYY')
    } else {
      return moment(d).format('MMM Do')
    }
  }

  static timestampDay(d: Date) {

    var todayMidnight = new Date()
      todayMidnight.setHours(0,0,0,0)
    var oneDayAgoMidnight = Util.addHours(todayMidnight, -24)
    var beginningOfYear = new Date((new Date()).getFullYear(), 0, 1);

    if (d < todayMidnight && d >= oneDayAgoMidnight) {
      return 'Yesterday'
    } else if (d > todayMidnight) {
      return 'Today'
    } else if (d < beginningOfYear) {
      return moment(d).format('MMM Do, YYYY')
    } else {
      return moment(d).format('MMM Do')
    }
  }

  static timestampDayLowercase(d: Date) {

    var todayMidnight = new Date()
      todayMidnight.setHours(0,0,0,0)
    var oneDayAgoMidnight = Util.addHours(todayMidnight, -24)
    var beginningOfYear = new Date((new Date()).getFullYear(), 0, 1);

    if (d < todayMidnight && d >= oneDayAgoMidnight) {
      return 'yesterday'
    } else if (d > todayMidnight) {
      return 'today'
    } else if (d < beginningOfYear) {
      return moment(d).format('MMM Do, YYYY')
    } else {
      return moment(d).format('MMM Do')
    }
  }

  static prettyTimeAdjusted(d: Date, timezone?: Timezone) {
    let adjustedDate = timezone ? Util.getDateAdjustedForOffset(d, timezone.offset) : d
    return Util.prettyTime(adjustedDate)
  }

  static timeRange(d1: Date, d2: Date) {
    let date1 = (new Date(d1)).toLocaleTimeString();
    let date2 = (new Date(d2)).toLocaleTimeString();

    let split1 = date1.split(':');
    let time1 = split1[0]+split1[2].substring(3,5);

    let split2 = date2.split(':');
    let time2 = split2[0]+split2[2].substring(3,5);

    return (time1 + " - " + time2).toLowerCase();
  }

  static timeRangeLong(d1: Date, d2: Date) {

    let time1 = Util.prettyTime(d1).toLowerCase().replace(/ /g,'');
    let time2 = Util.prettyTime(d2).toLowerCase().replace(/ /g,'');

    return time1 + " - " + time2;
  }

  static timeslotTime(d: Date) {
    return Util.prettyTime(d).toLowerCase().replace(/ /g,'');
  }

  static dayName(date: Date) {
    let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    return days[ date.getDay() ];
  }

  static mmddyyDate(date: Date) {
    var year = date.getFullYear();
    return Util.mmddDate(date) + "/" + year.toString().substr(2,2);
  }

  static mmddDate(date: Date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return month + "/" + day;
  }

  static addHours(d: Date, h: number){
      var n = new Date(d);
      n.setHours(n.getHours() + h);
      return n;
  }

  static getDateAdjustedForOffset(d: Date, offset: number) {

    var localOffset = d.getTimezoneOffset();
    var offsetDifference = localOffset - offset;
    return Util.addMinutes(d, offsetDifference);
  }

  static isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  static findClosestDate(date: Date, dates: Date[]): [Date, number] {
    let initialDateInterval = date.getTime();
    let dateIntervals = dates.map((date) => date.getTime());

    let closestDateIndex = 0;
    let closestDateIntervalDifference = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < dateIntervals.length; i++) {
      let dateIntervalDifference = Math.abs(initialDateInterval - dateIntervals[i]);
      if (dateIntervalDifference < closestDateIntervalDifference) {
        closestDateIntervalDifference = dateIntervalDifference;
        closestDateIndex = i;
      }
    }

    return [dates[closestDateIndex], closestDateIndex];
  }

  static startOfThisDay_Local(d: Date) {
    d.setHours(0, 0, 0, 0);
    return d;
  }

  static endOfThisDay_Local(d: Date) {
    d.setHours(24, 0, 0, 0);
    return d;
  }

  static shortMonthDate(d: Date) {
    return dateFormat(d, "m/d")
  }

  static dayMonthDate(d: Date, timezone: Timezone) {
    let adjustedDate = timezone ? Util.getDateAdjustedForOffset(d, timezone.offset) : d;
    return dateFormat(adjustedDate, "dddd, mmmm dS")
  }

  static dayMonthDate_ShortMonth(d: Date, timezone: Timezone) {
    let adjustedDate = timezone ? Util.getDateAdjustedForOffset(d, timezone.offset) : d;
    return dateFormat(adjustedDate, "dddd, mmm dS")
  }

  static dayMonthDateYear(d: Date, timezoneOffset?: number) {
    let adjustedDate = timezoneOffset ? Util.getDateAdjustedForOffset(d, timezoneOffset) : d
    return moment(adjustedDate).format('dddd, MMM Do YYYY')
  }

  static dayMonthDateYear_Shorter(d: Date, timezoneOffset?: number) {
    let adjustedDate = timezoneOffset? Util.getDateAdjustedForOffset(d, timezoneOffset) : d;
    return moment(adjustedDate).format('ddd, MMM Do YYYY')
  }

  static longDateString(d: Date, timezone?: Timezone) {
    let adjustedDate = timezone? Util.getDateAdjustedForOffset(d, timezone.offset) : d;
    return dateFormat(adjustedDate, "ddd, mmmm dS") + " at " + Util.prettyTime(adjustedDate)
  }

  static longDateStringAutoTZ(d: Date) {
    return dateFormat(d, "ddd, mmmm dS") + " at " + Util.prettyTime(d)
  }

  static longDateRangeString(d: Date, d2: Date) {
    let momentDate = moment(d)
    if (!momentDate.isValid()) {
      return ""
    }
    return momentDate.format("ddd, MMMM Do") + ", " + Util.prettyTime(d) + ' - ' + Util.prettyTime(d2)
  }

  static monthDate(d: Date, timezone?: Timezone) {
    let adjustedDate = timezone ? Util.getDateAdjustedForOffset(d, timezone.offset) : d
    return dateFormat(adjustedDate, "mmmm dS")
  }

  static shortMonthDayNoTh(d: Date, timezone?: Timezone) {
    let adjustedDate = timezone ? Util.getDateAdjustedForOffset(d, timezone.offset) : d
    return dateFormat(adjustedDate, "mmm d")
  }

  static shortMonthDay(d: Date, timezone?: Timezone) {
    let adjustedDate = timezone ? Util.getDateAdjustedForOffset(d, timezone.offset) : d
    return dateFormat(adjustedDate, "mmm dS")
  }

  static shortMonthDayYear(d: Date, timezone?: Timezone) {
    let adjustedDate = timezone ? Util.getDateAdjustedForOffset(d, timezone.offset) : d
    return moment(adjustedDate).format('MMM Do, YYYY')
  }

  static stringFromDateWithFormat(d: Date, format: string) {
    return moment(d).format(format);
  }

  static isEmptyObject(obj: Object) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  static productQuantityText(productQuantityList: ProductQuantityList) {
    let productsText = [];
    for (const productQuantity of productQuantityList) {
      if (productQuantity.quantity > 0) {
        productsText.push(`${productQuantity.name}: ${productQuantity.quantity}`)
      }
    }
    return productsText.join(', ')
  }

  static formatCost(num: number, currency: string, decimals: number = 2) {
    switch (currency) {
      case 'USD': {
        if (!num) {
          return '$0.00'
        }
        return `$${num.toFixed(decimals)}`;
      }
      case 'GBP': {
        if (!num) {
          return '£0.00'
        }
        return `£${num.toFixed(decimals)}`;
      }
      case 'CAD': {
        if (!num) {
          return 'CA$0.00'
        }
        return `CA$${num.toFixed(decimals)}`;
      }
      default: {
        // Default to USD
        if (!num) {
          return '$0.00'
        }
        return `$${num.toFixed(decimals)}`;
      }
    }
  }

  static formatPercentage(p: number) {
    return (p * 100).toFixed(0) + "%";
  }

  static containsSubstring(a: string, b: string) {
    return a.indexOf(b) !== -1;
  }

  static contains(a: any[], obj: any) {
    if(a === undefined){
      return false;
    }
    return a.indexOf(obj) !== -1;
  }

  static removeItem(a: any[], obj: any) {

    for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
            a.splice(i,1);
            i--;
        }
    }
  }

  static removeItemWithField(a: any[], field: string, value: any) {

    for (var i = 0; i < a.length; i++) {
        if (a[i][field] === value) {
            a.splice(i,1);
            i--;
        }
    }
  }

  static validateEmail(email: string) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
  }

  static validateFullName(fullName: string) {
    return Util.getFirstName(fullName).length > 1 && Util.getLastName(fullName).length > 1
  }

  static getFirstName(fullName: string) {

    let nameArray = fullName.trim().split(" ");
    var firstName = "";
    if (nameArray.length > 0) {
      firstName = Util.capitalize(nameArray[0]);
    }

    return firstName.trim();
  }

  static getLastName(fullName: string) {
    let nameArray = fullName.trim().split(" ");
    var name = ""
    if (nameArray.length > 1) {
      name = nameArray[nameArray.length - 1];
    }
    return Util.capitalize(name);
  }

  static capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  // Address
  static formatAddress(address: Address): string {

    if (!address.unit) {
      return Util.formatAddressWithoutUnit(address)
    }

    let addressString = address.street;
    if (address.unit && address.unit !== '') {
      addressString += ` ${address.unit}`;
    }

    addressString += `, ${address.city}, ${address.state} ${address.zip}`;
    return addressString;
  }

  static formatAddressWithoutUnit(address: Address): string {
    let addressString = `${address.street}, ${address.city}, ${address.state} ${address.zip}`;
    return addressString;
  }

  static formatShortAddress(address: Address): string {
    if (address.unit) {
      return `${address.street} ${address.unit}, ${address.city}, ${address.state}`;
    } else {
      return `${address.street}, ${address.city}, ${address.state}`;
    }
  }

  static formatCityState(address: Address): string {
    let addressString = `${address.city}, ${address.state}`;
    return addressString;
  }

  static generatePass(length: number, charset?: string): string {
    charset = charset || "abcdefghijklnopqrstuvwxyz0123456789";
    var retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  static isDefined(o: any) {
    return (typeof o !== "undefined" && o !== null);
  }

  // makes an array with n elements from a number -- for use in ngFor
  static toRange(n: number){
    let range = [];

    for (var i = 0; i < n; i++) {
      range.push(i)
    }

    return range
  }

  // REGEX

  static REGEX_URL = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/g;

}












// From the SCC:



  // static prettyTimeAdjustedForOffset(d: Date, offset: number, abbreviation?: string) {

  //   var localOffset = d.getTimezoneOffset()
  //   var offsetDifference = localOffset - offset;
  //   d = that.addMinutes(d, offsetDifference);

  //   var date = (new Date(d)).toLocaleTimeString();
  //   var split = date.split(':');
  //   var prettyString = split[0]+":"+split[1]+" "+split[2].substring(3,5);
  //   if (abbreviation) prettyString += ' ' + abbreviation;
  //   return prettyString;
  // }

// this.prettyTime = function(d) {
//     var date = (new Date(d)).toLocaleTimeString();
//     var split = date.split(':');
//     var prettyString = split[0]+":"+split[1]+" "+split[2].substring(3,5);
//     return prettyString;
//   }



//   this.getDay = function(d) {
//     return (new Date(d)).toDateString();
//   }

//   this.exists = function(o) {
//     return (typeof o !== "undefined" && o !== null);
//   }

//   this.keyContains = function(a, key, obj) {
//     if(a === undefined){
//       return false;
//     }
//     for (var i = 0; i < a.length; i++) {
//         if (a[i][key] == obj) {
//             return true;
//         }
//     }
//     return false;
//   }

//   this.getKeyContains = function(a, key, obj) {
//     if(a === undefined){
//       return false;
//     }
//     for (var i = 0; i < a.length; i++) {
//         if (a[i].get(key) == obj) {
//             return true;
//         }
//     }
//     return false;
//   }

//   this.containsID = function(a, id) {
//     if(a === undefined){
//       return false;
//     }
//     for (var i = 0; i < a.length; i++) {
//         if (a[i].id == id) {
//             return true;
//         }
//     }
//     return false;
//   }

//   this.removeItem = function(a, obj) {

//     for (var i = 0; i < a.length; i++) {
//         if (a[i] === obj) {
//             a.splice(i,1);
//             i--;
//         }
//     }
//   }

//   this.isBeforeYesterday = function(date){

//     var oneDayAgo = new Date(new Date().getTime() - 60 * 60 * 24 * 1000);

//     if (date < oneDayAgo){
//       return true;
//     }

//     return false;

//   }

//   this.shortenYear = function(gradYear) {

//     console.log('grad year: ' + gradYear);

//     var shortenedGY = "";

//     if (gradYear >= 2010) {
//       shortenedGY =  "'" + (gradYear - 2000).toString();
//     } else if (gradYear >= 2000 && gradYear < 2010) {
//       shortenedGY =  "'0" + (gradYear - 2000).toString();
//     } else if (gradYear < 2000){
//       shortenedGY =  "'" + (gradYear - 1900).toString();
//     }

//     console.log('shortened: ' + shortenedGY);
//     return shortenedGY;

//   }

//   this.timeSince = function(date) {

//       var seconds = Math.floor((new Date() - date) / 1000);

//       var interval = Math.floor(seconds / 31536000);

//       interval = Math.floor(seconds / 86400);
//       if (interval > 1) {
//           return interval + " days ago";
//       }
//       interval = Math.floor(seconds / 3600);
//       if (interval == 1) {
//           return interval + " hour ago";
//       }
//       if (interval > 1) {
//           return interval + " hours ago";
//       }
//       interval = Math.floor(seconds / 60);
//       if (interval == 1) {
//           return interval + " minute ago";
//       }
//       if (interval > 1) {
//           return interval + " minutes ago";
//       }
//       if (seconds < 10){
//         return "Just now";
//       }
//       return Math.floor(seconds) + " seconds ago";
//   }

//   this.timeSinceShort = function(date) {

//       var seconds = Math.floor((new Date() - date) / 1000);

//       var interval = Math.floor(seconds / 31536000);

//       interval = Math.floor(seconds / 3600);
//       if (interval >= 1) {
//           return interval + " hr";
//       }
//       interval = Math.floor(seconds / 60);
//       if (interval >= 1) {
//           return interval + " min";
//       }
//       return Math.floor(seconds) + " sec";
//   }

//   this.timeSinceNotif = function(date) {

//       var seconds = Math.floor((new Date() - date) / 1000);
//       var interval = Math.floor(seconds / 31536000);

//       if (interval > 1) {
//           return interval + " years ago";
//       }
//       interval = Math.floor(seconds / 2592000);
//       if (interval > 1) {
//           return interval + " months ago";
//       }
//       interval = Math.floor(seconds / 86400);
//       if (interval > 1) {
//           return interval + " days ago";
//       }
//       interval = Math.floor(seconds / 3600);
//       if (interval == 1) {
//           return interval + " hour ago";
//       }
//       if (interval > 1) {
//           return interval + " hours ago";
//       }
//       interval = Math.floor(seconds / 60);
//         if (interval == 1) {
//             return interval + " minute ago";
//       }
//       if (interval > 1) {
//           return interval + " minutes ago";
//       }
//       if (seconds < 10){
//           return "Just now";
//       }
//       return Math.floor(seconds) + " seconds ago";
//   }

//   this.timeSincePost = function(date) {

//       var seconds = Math.floor((new Date() - date) / 1000);
//       var interval = Math.floor(seconds / 31536000);

//       if (interval > 1) {
//           return interval + " yrs";
//       }
//       interval = Math.floor(seconds / 2592000);
//       if (interval > 1) {
//           return interval + " mos";
//       }
//       interval = Math.floor(seconds / 86400);
//       if (interval > 1) {
//           return interval + " days";
//       }
//       interval = Math.floor(seconds / 3600);
//       if (interval == 1) {
//           return interval + " hr";
//       }
//       if (interval > 1) {
//           return interval + " hrs";
//       }
//       interval = Math.floor(seconds / 60);
//       if (interval >= 1) {
//           return interval + " min";
//       }
//       return Math.floor(seconds) + " sec";
//   }

//   this.daysAgo = function(num){
//     return new Date(new Date().getTime() - 60 * 60 * 24 * num * 1000);
//   }

//   this.isNew = function(date) {

//     var oneWeekAgo = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);

//     if (date > oneWeekAgo){
//       return true;
//     }

//     return false;

//   }

//   this.addMinutes= function(d, m){
//       var n = new Date(d);
//       n.setMinutes(n.getMinutes() + m);
//       return n;
//   }

//   this.convertTo24Hrs = function(time){
//     var hours = Number(time.match(/^(\d+)/)[1]);
//     var minutes = Number(time.match(/:(\d+)/)[1]);
//     var AMPM = time.match(/\s(.*)$/)[1];
//     if(AMPM == "pm" && hours<12) hours = hours+12;
//     if(AMPM == "am" && hours==12) hours = hours-12;
//     var sHours = hours.toString();
//     var sMinutes = minutes.toString();
//     if(hours<10) sHours = "0" + sHours;
//     if(minutes<10) sMinutes = "0" + sMinutes;
//     return [sHours, sMinutes];
//   }

//   this.convertToClientTimezone = function(d, clientOffset) {
//     var localOffset = d.getTimezoneOffset()
//     var offsetDifference = clientOffset - localOffset;
//     return this.addMinutes(d, offsetDifference);
//   }

//   this.convertToClientTimezoneFromTimezoneName = function(d, timezoneName) {
//     var clientOffset = moment.tz.zone(timezoneName).offset(d);
//     var localOffset = d.getTimezoneOffset()
//     var offsetDifference = clientOffset - localOffset;
//     return this.addMinutes(d, offsetDifference);
//   }

//   this.isUndefined = function(item) {
//     return (typeof item == 'undefined' || item == null);
//   }

//   this.validateEmail = function(email) {
//       var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//       return re.test(email);
//   }

//   this.formatPhone = function(phone) {

//     phone = phone + '';
//     return '(' + phone.substring(0,3) + ') ' + phone.substring(3,6) + '-' + phone.substring(6,10);
//     // return phone;
//   }