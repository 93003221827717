import { Component, ChangeDetectorRef, ViewChild } from "@angular/core";
import {
  Platform,
  MenuController,
  LoadingController,
  NavController,
  ModalController,
  PopoverController,
} from "@ionic/angular";

import { UserService } from "./services/user-service";
import { ApptService, ClientConfigResponse } from "./services/appt-service";
import { DeeplinkRoutingService } from "./services/deeplink-routing-service";
import { VibeToast } from "./services/vibe-toast";
import { Alert } from "./services/alert";
import { PromoService, PromoActivation } from "./services/promo-service";
import { ApptCreator } from "./services/appt-creation-service";
import { AnalyticsService } from "./services/analytics-service";
import { Util } from "./services/util";
import { ParseInitService } from "./services/parse-init";
import { ChatManager } from "./services/chat-manager";
import { DeviceService } from "./services/device-service";

import { Constants } from "./models/constants";

import { Storage } from "@ionic/storage";

import { LoginPage } from "./pages/login/login.page";
import { SignUpPage } from "./pages/sign-up/sign-up.page";
import { AddProfilePicPage } from "./pages/add-profile-pic/add-profile-pic.page";
import { EnterPhoneNumberPage } from "./pages/enter-phone-number/enter-phone-number.page";
import { EnterVerifyCodePage } from "./pages/enter-verify-code/enter-verify-code.page";
import { ReviewService, EventTimeslot } from "./services/review-service";
import { Appointment } from "./models/appointment";
import { ApptReviewPage } from "./pages/appt-review/appt-review.page";
import { RoomsPage } from "./pages/rooms/rooms.page";
import { EmptyNameEmailPage } from "./pages/empty-name-email/empty-name-email.page";
import { RoomTasks } from "./services/room-tasks";
import { ObservableService } from "./services/observable-service";
import {
  ApptRequestTasks,
  ApptRequestAlertInfo,
  RequestMessageInfo,
} from "./services/appt-request-tasks";
import { ApptRequestAlertPage } from "./pages/appt-request-alert/appt-request-alert.page";
import { SharingService } from "./services/sharing-service";
import { SelectedServiceTypeList } from "./models/selected-service-type-list";
import { SelectProTypePage } from "./pages/select-pro-type/select-pro-type.page";

declare var branch;

export interface PageInterface {
  title: string;
  subtitle?: string;
  icon: string;
  url?: string;
  externalURL?: string;
  logsOut?: boolean;
  showUpcomingTotal?: boolean;
  logIn?: boolean;
  signUp?: boolean;
  hideOnMobile?: boolean;
  isSwitchProType?: boolean;
}

interface MenuUser {
  fullName: string;
  email: string;
  profPicURL?: string;
  credit?: number;
  userCreditCurrency: string;
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  @ViewChild("businessProChatSelector") businessProChatSelector;

  currentRoot: string = "welcome";

  placeholderImg = "assets/img/profile-placeholder.png";
  didRecentlySendVerificationNumber = false;

  numUpcomingTotal: number;

  // default (blank) user object to load the side menu for tutorial screen
  user: MenuUser = {
    fullName: "",
    email: "",
    profPicURL: "",
    credit: 0,
    userCreditCurrency: "",
  };

  menuPages: PageInterface[];
  bottomMenuPages: PageInterface[] = [];
  isMenuOpen = false;

  checkingApptRequest = false;

  constructor(
    private navCtrl: NavController,
    private platform: Platform,
    private observables: ObservableService,
    public menu: MenuController,
    private storage: Storage,
    public userService: UserService,
    public deeplinkRoutingService: DeeplinkRoutingService,
    public toast: VibeToast,
    private apptCreator: ApptCreator,
    private alert: Alert,
    private promoService: PromoService,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService,
    private modalCtrl: ModalController,
    private changeRef: ChangeDetectorRef,
    private reviewService: ReviewService,
    public chatManager: ChatManager,
    private popoverCtrl: PopoverController,
    public deviceService: DeviceService,
    private sharingService: SharingService
  ) {
    if (
      Constants.DEV_MODE ||
      Constants.LOCAL_MODE ||
      window.location.host === "localhost:8100"
    ) {
      this.analytics.disableAnalytics();
    } else {
      // Prod, Server
      window.console.log = function () {};
    }
    this.initializeApp();
  }

  ngOnInit() {
    // init parse
    ParseInitService.initParse();

    this.refreshApp();
    this.subscribeToEvents();

    branch.init(Constants.BRANCH_KEY, (err, response) => {
      const data = response.data_parsed;

      if (this.userService.isLoggedIn()) {
        // Logged in. Handle navigation or branch link immediately.

        if (data.barber) {
          this.handleBranchLink_Barber(data.barber);
        }
        if (data.promo) {
          this.handleBranchLink_Promo(data.promo);
        }
        if (data.event) {
          this.handleBranchLink_Event(data.event);
        }
        if (data.room) {
          this.handleBranchLink_Room(data.room);
        }
        if (data.invite) {
          this.handleBranchLink_Invite();
        }
        if (data.giftCard) {
          this.handleBranchLink_GiftCard(data.giftCard);
        }
        if (data.openGiftCard) {
          this.handleBranchLink_OpenGiftCard();
        }
        if (data.chatSupport) {
          this.handleBranchLink_ChatSupport();
        }
      } else {
        // Save navigation until they hit main screen or upcoming appt/event.
        if (data.barber) {
          this.deeplinkRoutingService.storedBarberID = data.barber;
          this.deeplinkRoutingService.barberProfileNavHistory = "App Link";
        }
        if (data.promo) {
          this.deeplinkRoutingService.storedPromo = data.promo;
        }
        if (data.event) {
          this.deeplinkRoutingService.storedEventCode = data.event;
        }
        if (data.room) {
          this.deeplinkRoutingService.storedRoomID = data.room;
        }
        if (data.invite) {
          this.deeplinkRoutingService.navToInvite = true;
        }
        if (data.giftCard) {
          this.deeplinkRoutingService.storedGiftCardCode = data.giftCard;
        }
        if (data.openGiftCard) {
          this.deeplinkRoutingService.openGiftCard = true;
        }
        if (data.chatSupport) {
          this.userService.generateGuest(
            undefined,
            () => {
              this.analytics.setUserID(this.userService.user.id);
              let destructive = true;
              this.refreshMainView(destructive);
              this.handleBranchLink_ChatSupport();
            },
            (errorMsg: string) => {
              console.log(errorMsg);
            }
          );
        }
      }
    });
  }

  private refreshApp() {
    // set default page
    if (this.userService.isLoggedIn()) {
      this.chatManager.setupLiveQuery();

      let hasLoadedAnyPage = this.hasLoadedAnyPage();
      if (!hasLoadedAnyPage) {
        this.navCtrl.navigateRoot("/...");
      }

      console.log("logged in");

      this.userService.unloadFromParse();
      console.log("Device: " + this.userService.getDevice());

      this.userService.fetch(
        () => {
          this.user = this.userService.user;

          const destructive = true;
          let skipNavChanges = hasLoadedAnyPage;
          const forceBooking = false;
          const forceUpcomingAppts = false;

          this.refreshMainView(
            destructive,
            forceBooking,
            forceUpcomingAppts,
            skipNavChanges
          );

          this.userService.update(
            { lastWebSignin: new Date() },
            () => {},
            (errorMsg: string) => {}
          );
        },
        (errorMsg: string) => {
          console.error("could not update user: " + errorMsg);
          if (
            errorMsg.toLowerCase().includes("invalid session token") ||
            errorMsg.toLowerCase().includes("object not found")
          ) {
            console.log("invalid session token, log out");
            this.logOut();
          }
          this.observables.publishLoadUserFailed();
        }
      );
    } else {
      console.log("not logged in");

      this.setUpMenuPages();

      if (
        !this.hasLoadedPage("welcome") &&
        !this.hasLoadedPage("pro") &&
        !this.hasLoadedPage("event") &&
        !this.hasLoadedPage("events")
      ) {
        this.navCtrl.navigateRoot("/welcome");
        this.currentRoot = "/welcome";
      }
    }
  }

  private hasLoadedAnyPage() {
    let loadedPath = window.location.pathname.substr(1).split("?")[0];

    if (loadedPath.substr(0, 3) == "...") {
      return false;
    }
    if (loadedPath.length < 2) {
      return false;
    }

    return true;
  }

  private hasLoadedPage(pageString: string) {
    let loadedPath = window.location.pathname.substr(1).split("?")[0];

    if (loadedPath.substr(0, pageString.length) == pageString) {
      console.log("loaded page: " + pageString);
      return true;
    }

    return false;
  }

  private hasLoadedProPage(barberLink: string) {
    let loadedPath = window.location.pathname.substr(1).split("?")[0];
    let totalPathLength = 4 + barberLink.length;

    if (loadedPath.substr(0, totalPathLength) == `pro/${barberLink}`) {
      return true;
    }

    return false;
  }

  private getClientConfig() {
    ApptService.pullClientConfig(
      (clientConfig: ClientConfigResponse) => {
        this.apptCreator.homeProTypes = clientConfig.homeProTypes;
        this.apptCreator.homeProTypeDetails = clientConfig.homeProTypeDetails;

        this.setUpMenuPages();

        this.sharingService.referralBonus = clientConfig.referralBonusAmount;
        this.sharingService.referralDiscountPercentage =
          clientConfig.referralDiscountPercentage;

        let upcomingAppts = clientConfig.upcomingAppts;
        let upcomingEventTimeslots = clientConfig.upcomingEventTimeslots;
        this.numUpcomingTotal =
          upcomingAppts.length + upcomingEventTimeslots.length;
        this.changeRef.detectChanges();
      },
      (msg: string) => {
        console.error(msg);
      }
    );
  }

  private proTypeTab() {
    let currentProType = this.userService.user
      ? this.userService.user.currentProType
      : "Hair";
    if (!currentProType) currentProType = "Hair";
    let switchString = "Change";
    if (this.apptCreator.homeProTypes) {
      const otherProTypes = this.apptCreator.homeProTypes.filter(
        (proType) => proType !== currentProType
      );
      switchString = `Switch to ${otherProTypes.join(", ")}`;
    }
    let icon = "cut-outline";
    if (currentProType === "Massage") {
      icon = "hand-left-outline";
    } else if (currentProType === "Beauty") {
      icon = "brush-outline";
    }

    const proTypeTab: PageInterface = {
      title: `${currentProType} services`,
      subtitle: switchString,
      url: "",
      icon,
      isSwitchProType: true,
    };

    return proTypeTab;
  }

  setUpMenuPages() {
    if (this.userService.user && this.userService.user.type == "business") {
      this.menuPages = [];

      this.menuPages.push(
        { title: "My Pros", url: "/team", icon: "people" },
        {
          title: "Appointments",
          url: "/appointments",
          icon: "list",
          showUpcomingTotal: true,
          hideOnMobile: true,
        },
        { title: "Analytics", url: "/analytics", icon: "analytics-outline" },
        {
          title: "Register a Pro",
          url: "/register-new-pro",
          icon: "people-circle-outline",
          hideOnMobile: true,
        },
        {
          title: "Concierges",
          url: "/register-concierge",
          icon: "person-circle-outline",
          hideOnMobile: true,
        }
      );
      if (this.userService.business.enableProductConfiguration) {
        this.menuPages.push({
          title: "Products",
          url: "/product-sales",
          icon: "cart",
          hideOnMobile: true,
        });
      }
      this.menuPages.push(
        { title: "Payments", url: "/payments", icon: "logo-usd" },
        { title: `Business Settings`, url: "/business", icon: "settings" }
      );

      this.bottomMenuPages = [];
      return;
    }

    if (!this.userService.user || this.userService.user.isGuest) {
      this.menuPages = [
        { title: "Search Pros", url: "/explore", icon: "search" },
        {
          title: "My appointments",
          url: "/appointments",
          icon: "list",
          showUpcomingTotal: true,
          hideOnMobile: true,
        },
        {
          title: "Join an event",
          url: "/events",
          icon: "calendar",
          hideOnMobile: true,
        },
        { title: "Create account", icon: "person", signUp: true },
        { title: "Sign in", icon: "log-in", logIn: true },
      ];

      this.bottomMenuPages = [this.proTypeTab()];
    } else {
      this.menuPages = [
        { title: "Search Pros", url: "/explore", icon: "search" },
        {
          title: "My appointments",
          url: "/appointments",
          icon: "list",
          showUpcomingTotal: true,
          hideOnMobile: true,
        },
        {
          title: "Join an event",
          url: "/events",
          icon: "calendar",
          hideOnMobile: true,
        },
        { title: "My account", url: "/account", icon: "person" },
      ];

      this.bottomMenuPages = [this.proTypeTab()];
    }

    // if (this.userService.user.currentProType == 'Hair') {
    //   this.menuPages.push({ title: 'Switch to Massage', icon: 'body', switchToProType: 'Massage' })
    // } else if (this.userService.user.currentProType == 'Massage') {
    //   this.menuPages.push({ title: 'Switch to Hair', icon: 'cut', switchToProType: 'Hair' })
    // }
  }

  menuToggled(isMenuOpen: boolean) {
    this.isMenuOpen = isMenuOpen;
  }

  closeMenu() {
    this.menu.close();
  }

  // The 'destructive' boolean decides whether to replace the nav stack entirely,
  //   which should be avoided on app-resume.
  private refreshMainView(
    destructive: boolean,
    forceBooking: boolean = false,
    forceUpcomingAppts: boolean = false,
    skipNavChanges: boolean = false
  ) {
    console.log("refreshMainView. skipNavChanges: " + skipNavChanges);

    this.getClientConfig();
    this.checkForReview();
    this.checkForApptRequest();
    this.chatManager.getHasUnreadMessages();

    if (!this.userService.isLoggedIn()) {
      return;
    }

    if (this.userService.user.type == "business") {
      console.log("business account");

      if (!skipNavChanges && (destructive || this.currentRoot !== "/team")) {
        this.setMainScreenPage_Business();
      }
      return;
    }

    const type = this.userService.user.type;
    if (type == "barber" || type == "admin") {
      this.alert.show(
        "Oops",
        "You must log into the Shortcut Web App with a Client or Business account."
      );
      this.logOut();
      return;
    }

    if (!skipNavChanges) {
      this.navCtrl.navigateRoot("/...");
    }

    if (!skipNavChanges && (destructive || this.currentRoot != "/explore")) {
      this.setMainScreenPage();
    }

    const noPhoneNumber =
      !this.userService.user.isoPhoneNumber ||
      this.userService.user.isoPhoneNumber === "";
    if (noPhoneNumber && !this.userService.user.isGuest) {
      // Present add prof pic modal if verified phone number does not exist and user is not guest
      if (!this.userService.user.profPicURL) {
        this.showAddProfPicModal();
      } else {
        this.showEnterPhoneNumberModal();
      }
    }

    this.handleStoredDeeplinkData();
  }

  private setMainScreenPage_Business() {
    this.navCtrl.navigateRoot("/team");
    this.currentRoot = "/team";
    this.storage.get(Constants.PROMO_CODE).then((val) => {
      if (val) {
        this.toast.showPromoToast(val);
      }
    });
  }

  private setMainScreenPage() {
    // "b" param is for Business ID.
    const queryParams = this.deeplinkRoutingService.storedBusinessID
      ? { b: this.deeplinkRoutingService.storedBusinessID }
      : undefined;
    this.navCtrl.navigateRoot("/explore", { queryParams });
    this.currentRoot = "/explore";
    this.storage.get(Constants.PROMO_CODE).then((val) => {
      if (val) {
        this.toast.showPromoToast(val);
      }
    });
  }

  private async showAddProfPicModal() {
    const addProfPicModal = await this.modalCtrl.create({
      component: AddProfilePicPage,
      componentProps: {},
      backdropDismiss: false,
    });

    addProfPicModal.present();
  }

  private async showEnterPhoneNumberModal() {
    const enterPhoneModal = await this.modalCtrl.create({
      component: EnterPhoneNumberPage,
      componentProps: {
        didRecentlySendNumber: this.didRecentlySendVerificationNumber,
      },
      backdropDismiss: false,
      cssClass: "enter-phone-number-modal",
    });

    enterPhoneModal.onDidDismiss().then((val: any) => {
      if (!val || !val.data) {
        return;
      }

      this.didRecentlySendVerificationNumber = val.data.didRecentlySendNumber;
    });

    enterPhoneModal.present();
  }

  private async showReviewModal(
    appt?: Appointment,
    eventTimeslot?: EventTimeslot
  ) {
    const apptReviewModal = await this.modalCtrl.create({
      component: ApptReviewPage,
      componentProps: {
        appt,
        eventTimeslot,
      },
      backdropDismiss: false,
      cssClass: "appt-review-modal",
    });

    apptReviewModal.onDidDismiss().then(() => {
      this.checkForReview();
    });

    await apptReviewModal.present();
  }

  onResize() {
    this.deviceService.getPlatform();
  }

  goToWebsite() {
    window.open(Constants.MAIN_WEBSITE_URL, "_blank");
  }

  openProProfile(event: any, barberLink: string, shouldCloseChat = false) {
    event.stopPropagation();
    if (shouldCloseChat) {
      this.chatManager.minimizeAllChats();
    }
    if (this.hasLoadedProPage(barberLink)) {
      console.log("Already loaded Pro page.");
      return;
    }
    this.navCtrl.navigateForward(`/pro/${barberLink}`);
  }

  logOut() {
    this.userService.logout();
    this.chatManager.clearSettings();
    this.apptCreator.resetApptDetails();
    this.navCtrl.navigateRoot("/welcome");
    this.currentRoot = "/welcome";
    this.changeRef.detectChanges();
  }

  async openMenuPage(page: PageInterface) {
    // } else if (page.isEnterPromoCode === true) {

    //   this.alert.showPrompt('Enter Promo Code', null, 'promo', 'CODE', 'text', 'Submit', (data) => {
    //     let promo = data.promo;
    //     this.promoService.checkPromoValidity(promo, (barberID?: string, activation?: PromoActivation, eventRedirectionData?: EventSeriesInfo[], giftCardData?: GiftCard) => {

    //       if (activation) {
    //         this.promoService.displayPromoActivation(activation)
    //       } else if (eventRedirectionData) {
    //         this.handleMobileEventCodeSuccess(promo, eventRedirectionData)
    //       } else if (giftCardData) {
    //         this.alert.show('Redeem gift card?', `Code: ${giftCardData.hyphenatedCode}`, 'Redeem', async () => {

    //           let loading = await this.loadingCtrl.create()
    //           loading.present()

    //           GiftCardsService.redeemGiftCard(giftCardData.code, (giftCard: GiftCard) => {
    //             loading.dismiss()
    //             this.alert.show('Success!', `${Util.formatCost(giftCard.amount)} has been credited to your account and will be applied automatically to your next order.`);
    //             this.events.publish(Constants.UPDATE_USER_EVENT)
    //           }, (errorMsg: string) => {
    //             loading.dismiss()
    //             this.alert.show('Error', errorMsg)
    //           })
    //         }, 'Cancel', () => { });
    //       } else {
    //         this.promoService.storeCoupon(promo);
    //         this.toast.showPromoToast(promo);

    //         if (barberID) {
    //           const navigationExtras: NavigationExtras = {
    //             state: {
    //               barberID,
    //               barberProfileNavHistory: 'Coupon'
    //             }
    //           }
    //           this.navCtrl.navigateForward(`/pro/${barberID}`, navigationExtras);
    //         }
    //       }

    //     }, (errorMsg) => {
    //       this.toast.show(`Promo invalid. ${errorMsg}`);
    //     });
    //   }, 'Cancel', () => { }, 'SC-caps');

    // }

    // else if (page.isReferBarber) {

    //   const navigationExtras: NavigationExtras = {
    //     state: {
    //       proType: 'Hair'
    //     }
    //   }
    //   this.navCtrl.navigateForward('/refer-pro', navigationExtras)

    // } else if (page.isReferMassageTherapist) {

    //   const navigationExtras: NavigationExtras = {
    //     state: {
    //       proType: 'Massage'
    //     }
    //   }
    //   this.navCtrl.navigateForward('/refer-pro', navigationExtras)

    // } else if (page.becomePro) {

    //   this.inAppBrowser.create('https://www.getshortcut.co/pros', "_blank");

    // }

    if (page.logsOut === true) {
      this.logOut();
    } else if (page.signUp) {
      const preventMainRefresh = false;
      this.signUp(preventMainRefresh);
    } else if (page.logIn) {
      const preventMainRefresh = false;
      this.logIn(preventMainRefresh);
    } else if (page.externalURL) {
      window.open(page.externalURL, "_blank");
    } else if (page.isSwitchProType) {
      let selectProTypeModal = await this.modalCtrl.create({
        component: SelectProTypePage,
      });

      selectProTypeModal.onDidDismiss().then(async (val: any) => {
        if (!val || !val.data) {
          return;
        }

        if (val.data.proType) {
          const newParams = {
            currentProType: val.data.proType,
          };

          const loading = await this.loadingCtrl.create();
          loading.present();

          this.userService.update(
            newParams,
            () => {
              loading.dismiss();
              let list = new SelectedServiceTypeList();
              list.items = [];
              list.refreshText();
              this.apptCreator.mainScreenSelectedServiceTypeList = list;
              this.navCtrl.navigateRoot("/...");
              setTimeout(() => {
                const destructive = true;
                const forceBooking = true;
                const forceUpcomingAppts = false;
                const skipNavChanges = false;
                this.refreshMainView(
                  destructive,
                  forceBooking,
                  forceUpcomingAppts,
                  skipNavChanges
                );
              }, 1);
            },
            (msg: string) => {
              loading.dismiss();
              alert(msg);
            }
          );
          return;
        }
      });

      selectProTypeModal.present();
    } else {
      this.navCtrl.navigateRoot(page.url);
      this.currentRoot = page.url;
      this.changeRef.detectChanges();
    }
  }

  private showDownloadPopup_Chat() {
    this.alert.show(
      "Download Shortcut",
      `Chat and book local Pros for in-home services in the award-winning Shortcut app.`,
      "Get the app",
      () => {
        window.open("https://shortcut.app.link/text-link", "_blank");
      }
    );
  }

  private showDownloadPopup_Login() {
    this.alert.show(
      "Download Shortcut",
      `Book local Pros for in-home services in the award-winning Shortcut app.`,
      "Get the app",
      () => {
        window.open("https://shortcut.app.link/text-link", "_blank");
      }
    );
  }

  private showDownloadPopup() {
    this.alert.show(
      "Download Shortcut",
      "Get updates, notifications, promotions and more with the award-winning Shortcut app.",
      "Get the app",
      () => {
        window.open("https://shortcut.app.link/text-link", "_blank");
      }
    );
  }

  private async logIn(
    preventMainRefresh: boolean,
    userParamsUpdateOnLogin?: Object
  ) {
    if (this.deviceService.isMobile) {
      this.showDownloadPopup_Login();
      return;
    }

    const signInModal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {
        preventMainRefresh,
        userParamsUpdateOnLogin,
      },
    });

    signInModal.present();
  }

  private async signUp(preventMainRefresh: boolean, source = "") {
    if (this.deviceService.isMobile) {
      this.showDownloadPopup_Login();
      return;
    }

    const signUpModal = await this.modalCtrl.create({
      component: SignUpPage,
      componentProps: {
        preventMainRefresh,
        signUpFromEvent: source === "event",
      },
    });

    signUpModal.present();
  }

  private openBusinessProChatSelector() {
    this.businessProChatSelector.open();
  }

  private subscribeToEvents() {
    this.observables.goToLogin.subscribe(
      ({ preventMainRefresh, userParamsUpdateOnLogin }) => {
        this.logIn(preventMainRefresh, userParamsUpdateOnLogin);
      }
    );

    this.observables.goToSignUp.subscribe(({ preventMainRefresh, source }) => {
      this.signUp(preventMainRefresh, source);
    });

    this.observables.goToAddProfPic.subscribe(() => {
      this.showAddProfPicModal();
    });

    this.observables.goToEnterPhoneNumber.subscribe(() => {
      this.showEnterPhoneNumberModal();
    });

    this.observables.openBusinessProChatSelector.subscribe(() => {
      this.openBusinessProChatSelector();
    });

    this.observables.goToEmptyNameEmail.subscribe(async () => {
      const emptyNameEmailModal = await this.modalCtrl.create({
        component: EmptyNameEmailPage,
        componentProps: {},
        backdropDismiss: false,
        cssClass: "auto-height",
      });

      emptyNameEmailModal.present();
    });

    this.observables.goToEnterVerificationCode.subscribe(async () => {
      const verificationCodeModal = await this.modalCtrl.create({
        component: EnterVerifyCodePage,
        componentProps: {},
        cssClass: "enter-verification-code-modal",
      });

      verificationCodeModal.present();
    });

    this.observables.openNewAdminChat.subscribe(async () => {
      if (this.userService.user.adminChatRoomID) {
        this.tryOpenNewChat(this.userService.user.adminChatRoomID);
        return;
      }

      const loading = await this.loadingCtrl.create();
      loading.present();

      let func = RoomTasks.createClientAdminRoom;
      if (this.userService.isBusiness) {
        func = RoomTasks.createBusinessAdminRoom;
      }

      func(
        (roomID: string) => {
          loading.dismiss();
          this.tryOpenNewChat(roomID);
        },
        (err: string) => {
          loading.dismiss();
          console.error(err);
        }
      );
    });

    this.observables.openNewChat.subscribe((roomID: string) => {
      this.tryOpenNewChat(roomID);
    });

    this.observables.minimizeAllChats.subscribe(() => {
      this.chatManager.minimizeAllChats();
    });

    this.observables.showApptReview.subscribe((appt: Appointment) => {
      this.showReviewModal(appt, undefined);
    });

    this.observables.showEventReview.subscribe((eventInfo: EventTimeslot) => {
      this.showReviewModal(undefined, eventInfo);
    });

    this.observables.checkForReview.subscribe(() => {
      this.checkForReview();
    });

    this.observables.updateUser.subscribe(() => {
      if (!this.userService.isLoggedIn()) {
        console.error("Cannot update user. Not logged in.");
        return;
      }

      this.userService.fetch(
        () => {
          this.user = this.userService.user;
        },
        (errorMsg: string) => {
          console.error("could not update user: " + errorMsg);
          if (
            errorMsg.toLowerCase().includes("invalid session token") ||
            errorMsg.toLowerCase().includes("object not found")
          ) {
            console.log("invalid session token, log out");
            this.logOut();
          }
        }
      );
    });

    this.observables.refreshMainView.subscribe(() => {
      let destructive = true;
      this.refreshMainView(destructive);

      this.userService.fetch(
        () => {
          this.user = this.userService.user;
        },
        (errorMsg: string) => {
          console.error("could not update user: " + errorMsg);
          if (
            errorMsg.toLowerCase().includes("invalid session token") ||
            errorMsg.toLowerCase().includes("object not found")
          ) {
            console.log("invalid session token, log out");
            this.logOut();
          }
        }
      );
    });

    this.observables.refreshMainViewForceBooking.subscribe(() => {
      const destructive = true;
      const forceBooking = true;
      const forceUpcomingAppts = false;
      this.refreshMainView(destructive, forceBooking, forceUpcomingAppts);
    });

    this.observables.refreshMainViewForceAppointments.subscribe(() => {
      const destructive = true;
      const forceBooking = false;
      const forceUpcomingAppts = true;
      this.refreshMainView(destructive, forceBooking, forceUpcomingAppts);
    });

    this.observables.refreshSideMenu.subscribe(() => {
      this.user = this.userService.user;
      this.setUpMenuPages();
    });

    this.observables.profPicUpdated.subscribe(() => {
      this.user = this.userService.user;
    });

    this.observables.showReferFriend.subscribe(() => {
      this.navCtrl.navigateForward("/refer-friend");
    });

    this.observables.showDownloadPopup.subscribe(() => {
      this.showDownloadPopup();
    });

    this.observables.logOut.subscribe(() => {
      this.logOut();
    });

    this.observables.chatMessageSent.subscribe(() => {
      this.checkForNotificationPermissions(Constants.CHAT_MESSAGE_SENT);
    });

    this.observables.setCurrentMenuRoot.subscribe((currentRoot: string) => {
      this.currentRoot = currentRoot;
      this.changeRef.detectChanges();
    });

    this.observables.booked.subscribe(() => {
      this.checkForNotificationPermissions(Constants.BOOKED);
      this.userService.fetch(() => {
        this.user = this.userService.user;
      });
      this.getClientConfig();
      this.chatManager.updateRoomInfos();
      this.apptCreator.resetApptDetails();
    });

    this.observables.updateAllChatRoomInfo.subscribe(() => {
      this.chatManager.updateRoomInfos();
    });

    this.observables.checkUnreadMessages.subscribe(() => {
      if (this.chatManager.hasUnreadMessages) {
        this.chatManager.getHasUnreadMessages();
      }
    });

    this.observables.joinedWaitlist.subscribe(() => {
      this.checkForNotificationPermissions(Constants.JOINED_WAITLIST);
    });

    this.observables.giftCardPurchased.subscribe((code: string) => {
      this.alert.show(
        "Gift card purchased!",
        `Code: ${code}. Check your email for more information.`
      );
    });

    this.observables.refreshApp.subscribe(() => {
      this.refreshApp();
    });

    // Deeplink events

    this.observables.handleDeeplinks.subscribe(() => {
      this.handleStoredDeeplinkData();
    });

    this.observables.deeplinkBarber.subscribe(() => {
      let item = this.deeplinkRoutingService.storedBarberID;
      this.handleBranchLink_Barber(item);
      this.deeplinkRoutingService.storedBarberID = null;
    });

    this.observables.deeplinkPromo.subscribe(() => {
      let item = this.deeplinkRoutingService.storedPromo;
      this.handleBranchLink_Promo(item);
      this.deeplinkRoutingService.storedPromo = null;
    });

    this.observables.deeplinkEvent.subscribe(() => {
      let item = this.deeplinkRoutingService.storedEventCode;
      this.handleBranchLink_Event(item);
      this.deeplinkRoutingService.storedEventCode = null;
    });

    this.observables.deeplinkChatRoom.subscribe(() => {
      let item = this.deeplinkRoutingService.storedRoomID;
      this.handleBranchLink_Room(item);
      this.deeplinkRoutingService.storedRoomID = null;
    });

    this.observables.deeplinkChatSupport.subscribe(() => {
      this.handleBranchLink_ChatSupport();
      this.deeplinkRoutingService.openChatSupport = null;
    });

    this.observables.deeplinkReferFriend.subscribe(() => {
      this.handleBranchLink_Invite();
      this.deeplinkRoutingService.navToInvite = null;
    });

    this.observables.deeplinkRedeemGiftCard.subscribe(() => {
      let item = this.deeplinkRoutingService.storedGiftCardCode;
      this.handleBranchLink_GiftCard(item);
      this.deeplinkRoutingService.storedGiftCardCode = null;
    });

    this.observables.deeplinkGiftCards.subscribe(() => {
      this.handleBranchLink_OpenGiftCard();
      this.deeplinkRoutingService.openGiftCard = null;
    });
  }

  async openChatRooms(event: any) {
    const chatRoomsPopover = await this.popoverCtrl.create({
      component: RoomsPage,
      event: event,
      componentProps: {},
      cssClass: ["SCWEB-popover", "SCWEB-popover-rooms"],
    });

    await chatRoomsPopover.present();
  }

  tryOpenNewChat(roomID: string) {
    if (this.deviceService.isMobile) {
      this.showDownloadPopup_Chat();
      return;
    }

    this.chatManager.openNewChat(roomID);
  }

  private checkForNotificationPermissions(type: string) {
    if (!this.platform.is("cordova")) {
      return;
    }

    // this.push.hasPermission().then((response) => {
    //   if (!response.isEnabled) {
    //     if (type === Constants.CHAT_MESSAGE_SENT) {
    //       this.storage.get(Constants.HAS_SEEN_CHAT_NOTIFICATION_PROMPT).then((val) => {
    //         if (val !== true) {
    //           this.storage.set(Constants.HAS_SEEN_CHAT_NOTIFICATION_PROMPT, true);
    //           this.alert.show('Notifications Disabled', 'Please go to your Settings app and enable notifications for Shortcut so we can notify you when someone responds in the chat.')
    //         }
    //       });
    //     } else if (type === Constants.BOOKED) {
    //       this.storage.get(Constants.HAS_SEEN_BOOKED_NOTIFICATION_PROMPT).then((val) => {
    //         if (val !== true) {
    //           this.storage.set(Constants.HAS_SEEN_BOOKED_NOTIFICATION_PROMPT, true);
    //           this.alert.show('Notifications Disabled', 'Please go to your Settings app and enable notifications for Shortcut so we can update you on your appointment.')
    //         }
    //       });
    //     } else if (type === Constants.JOINED_WAITLIST) {
    //       this.storage.get(Constants.HAS_SEEN_WAITLIST_NOTIFICATION_PROMPT).then((val) => {
    //         if (val !== true) {
    //           this.storage.set(Constants.HAS_SEEN_WAITLIST_NOTIFICATION_PROMPT, true);
    //           this.alert.show('Notifications Disabled', 'Please go to your Settings app and enable notifications for Shortcut so we can notify you when your waitlist status changes.')
    //         }
    //       });
    //     }
    //   }
    // })
  }

  public formatCost(cost: number, currency: string) {
    return Util.formatCost(cost, currency);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.analytics.initializeAnalytics();
      // handleBranch()
    });

    this.platform.resume.subscribe(() => {
      this.observables.publishAppBecameActive();
      // handleBranch()
      this.checkForReview();
      this.checkForApptRequest();
    });

    /*
    // Branch initialization
    const handleBranch = () => {

      // only on devices
      if (!this.platform.is('cordova')) { return }

      const Branch = (window as any)['Branch'];
      Branch.initSession().then((data: any) => {

        console.log('init branch')
        console.log('Branch link with data: ' + JSON.stringify(data))

        // read deep link data on click
        if (data['+clicked_branch_link']) {

          if (this.userService.isLoggedIn()) {

            // Logged in. Handle navigation or branch link immediately.

            if (data.barber) {
              this.handleBranchLink_Barber(data.barber)
            }
            if (data.promo) {
              this.handleBranchLink_Promo(data.promo)
            }
            if (data.event) {
              this.handleBranchLink_Event(data.event)
            }
            if (data.room) {
              this.handleBranchLink_Room(data.room)
            }
            if (data.invite) {
              this.handleBranchLink_Invite()
            }
            if (data.giftCard) {
              this.handleBranchLink_GiftCard(data.giftCard)
            }
            if (data.openGiftCard) {
              this.handleBranchLink_OpenGiftCard()
            }

          } else {

            // Save navigation until they hit main screen or upcoming appt/event.

            if (data.barber) {
              this.deeplinkRoutingService.storedBarberID = data.barber;
              this.deeplinkRoutingService.barberProfileNavHistory = 'App Link';
            }
            if (data.promo) {
              this.deeplinkRoutingService.storedPromo = data.promo;
            }
            if (data.event) {
              this.deeplinkRoutingService.storedEventCode = data.event;
            }
            if (data.room) {
              this.deeplinkRoutingService.storedRoomID = data.room;
            }
            if (data.invite) {
              this.deeplinkRoutingService.navToInvite = true
            }
            if (data.giftCard) {
              this.deeplinkRoutingService.storedGiftCardCode = data.giftCard
            }
            if (data.openGiftCard) {
              this.deeplinkRoutingService.openGiftCard = true
            }
          }

          this.events.publish(Constants.BRANCH_LINK_CLICKED);

          if (this.userService.user) {
            if (!this.userService.user.branchParams) { // Only save branch params once
              const campaignTitle = data['~campaign'];
              let branchParams: any = {}

              for (let key in data) {
                if (!data.hasOwnProperty(key)) continue

                let newKey = key.replace('$', '')
                branchParams[newKey] = data[key]
              }

              this.userService.update({
                branchParams: branchParams,
                branchCampaign: campaignTitle
              }, () => { }, (errorMsg) => { });
            }
          } else {
            // No user logged in. This is likely a first time install.
            // Save Branch params to native storage so we can store
            // on the user object once they log in.
            let branchParams: any = {}

            for (let key in data) {
              if (!data.hasOwnProperty(key)) continue

              let newKey = key.replace('$', '')
              branchParams[newKey] = data[key]
            }

            this.storage.set(Constants.BRANCH_PARAMS_KEY, branchParams)
          }
        }
      });
    }
    */
  }

  private handleStoredDeeplinkData() {
    if (this.deeplinkRoutingService.storedBarberID) {
      this.observables.publishDeeplinkBarber();
      return;
    }
    if (this.deeplinkRoutingService.storedPromo) {
      this.observables.publishDeeplinkPromo();
      return;
    }
    if (this.deeplinkRoutingService.storedEventCode) {
      this.observables.publishDeeplinkEvent();
      return;
    }
    if (this.deeplinkRoutingService.storedRoomID) {
      this.observables.publishDeeplinkChatRoom();
      return;
    }
    if (this.deeplinkRoutingService.navToInvite) {
      this.observables.publishDeeplinkReferFriend();
      return;
    }
    if (this.deeplinkRoutingService.storedGiftCardCode) {
      this.observables.publishDeeplinkRedeemGiftCard();
      return;
    }
    if (this.deeplinkRoutingService.openGiftCard) {
      this.observables.publishDeeplinkGiftCards();
      return;
    }
    if (this.deeplinkRoutingService.openChatSupport) {
      this.observables.publishDeeplinkChatSupport();
      return;
    }
  }

  private handleBranchLink_Barber(barberID: string) {
    if (
      !this.userService.user ||
      !this.userService.user.isoPhoneNumber ||
      this.userService.user.isoPhoneNumber === ""
    ) {
      return;
    }

    // this.permissionsService.locationPermissionStatus((status) => {
    //   if (status != PermissionStatus.Unknown) {
    //     if (barberID) {

    //       const navigationExtras: NavigationExtras = {
    //         state: {
    //           barberID,
    //           barberProfileNavHistory: this.deeplinkRoutingService.barberProfileNavHistory
    //         }
    //       }
    //       this.navCtrl.navigateForward(`/pro/${barberID}`, navigationExtras);

    //       this.deeplinkRoutingService.storedBarberID = null;
    //     }
    //   }
    // });
  }

  private handleBranchLink_Promo(promo: string) {
    if (promo) {
      this.deeplinkRoutingService.storedPromo = null;
      this.promoService.checkPromoValidity(
        promo,
        (
          barberID?: string,
          barberLink?: string,
          activation?: PromoActivation
        ) => {
          if (activation) {
            this.promoService.displayPromoActivation(activation);
          } else {
            this.promoService.storeCoupon(promo);
            this.toast.showPromoToast(promo);
          }
        },
        (errorMsg) => {
          console.log("Check promo failed: " + errorMsg);
        }
      );
    }
  }

  private handleBranchLink_Event(eventCode: string) {
    if (!this.userService.user || this.userService.user.isoPhoneNumber === "") {
      return;
    }

    // if (eventCode) {
    //   this.submitMobileEventCode(eventCode)
    // }
  }

  private handleBranchLink_Room(roomID: string) {
    if (roomID) {
      this.chatManager.openNewChat(roomID);
    }
  }

  private handleBranchLink_ChatSupport() {
    RoomTasks.createClientAdminRoom(
      (roomID: string) => {
        this.chatManager.openNewChat(roomID);
      },
      (err: string) => {
        console.error(err);
      }
    );
  }

  private handleBranchLink_Invite() {
    // this.navCtrl.navigateForward('/refer-friend')
  }

  private handleBranchLink_GiftCard(code: string) {
    if (!this.userService.user || this.userService.user.isGuest) {
      return;
    }

    // if (code) {
    //   this.alert.show('Redeem gift card?', `Code: ${code}`, 'Redeem', async () => {

    //     let loading = await this.loadingCtrl.create()
    //     loading.present()

    //     GiftCardsService.redeemGiftCard(code, (giftCard: GiftCard) => {
    //       loading.dismiss()
    //       this.alert.show('Success!', `${Util.formatCost(giftCard.amount)} has been credited to your account and will be applied automatically to your next order.`);
    //       this.events.publish(Constants.UPDATE_USER_EVENT)
    //     }, (errorMsg: string) => {
    //       loading.dismiss()
    //       this.alert.show('Error', errorMsg)
    //     })
    //   }, 'Cancel', () => { });
    // }
  }

  private handleBranchLink_OpenGiftCard() {
    if (!this.userService.user || this.userService.user.isGuest) {
      return;
    }

    // this.navCtrl.navigateForward('/gift-cards')
  }

  private checkForReview() {
    if (!this.userService.isLoggedIn()) {
      return;
    }

    if (
      this.userService.user.type == "concierge" ||
      this.userService.user.type == "business"
    ) {
      // Don't show review prompts for concierge/business user type
      return;
    }

    this.reviewService.fetchEventReviewIfNeeded(
      (eventTimeslot: EventTimeslot) => {
        if (eventTimeslot) {
          this.showReviewModal(undefined, eventTimeslot);
        }
      },
      (errorMsg) => {
        // Nothing to do here.
        console.error(errorMsg);
      }
    );

    this.reviewService.fetchReviewIfNeeded(
      (appt) => {
        if (appt) {
          this.showReviewModal(appt, undefined);
        }
      },
      (errorMsg) => {
        // Nothing to do here.
        console.error(errorMsg);
      }
    );
  }

  private checkForApptRequest() {
    if (this.checkingApptRequest) return;

    this.checkingApptRequest = true;

    ApptRequestTasks.checkForPendingRequestMessage(
      async (info: RequestMessageInfo) => {
        this.checkingApptRequest = false;

        if (!info || !info.roomID) return;

        let apptRequestAlertModal = await this.modalCtrl.create({
          component: ApptRequestAlertPage,
          componentProps: {
            info,
          },
          cssClass: "auto-height",
          backdropDismiss: false,
        });

        apptRequestAlertModal.onDidDismiss().then((val: any) => {
          console.log(val);
          if (val && val.data && val.data.roomID) {
            this.chatManager.openNewChat(val.data.roomID);
          }
        });

        apptRequestAlertModal.present();
      },
      (errMsg: string) => {
        this.checkingApptRequest = false;
        console.log(errMsg);
      }
    );
  }
}

enum PushNotificationType {
  Unknown = "-1",
  ReviewAppt = "0",
  ApptCancelled = "1",
  ApptRescheduled = "2",
  ReferralCodeUsed = "3",
  BarberID = "4",
  Event = "5",
  RoomID = "6",
  Promo = "7",
}
