import { Injectable } from '@angular/core';
import { Http, RequestOptionsArgs, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { UserService } from "./user-service";
import { Constants } from "./../models/constants";
import * as Parse from 'parse';

@Injectable({ providedIn: 'root' })
export class PushInstallationService {

  constructor(
    public http: Http,
    public userService: UserService
  ) {
  }

  readInstallationID(successCallback: (installationId: string) => void) {
    (Parse as any)._getInstallationId().then((result: any) => {
      console.log(result);
      successCallback(result);
    }, (error: any) => {
      console.log(error);
    });
  }

  saveInstallation(deviceToken?: string) {
    let parseServer: string;
    let parseAppID: string;
    if (Constants.DEV_MODE) {
      parseServer = Constants.DEV_PARSE_SERVER;
      parseAppID = Constants.DEV_PARSE_JS_KEY;
    } else {
      parseServer = Constants.PARSE_SERVER;
      parseAppID = Constants.PARSE_JS_KEY;
    }
    let url = `${parseServer}/installations`;

    console.log(`Save device token: ${deviceToken}`)

    this.readInstallationID((inId: string) => {
      let body: any = {
        deviceType: 'android',
        installationId: inId,
        pushType: 'gcm',
        GCMSenderId: '358390183023',
        appName: 'Shortcut'
      }

      if (deviceToken) {
        body['deviceToken'] = deviceToken;
      }

      if (this.userService.user.id) {
        body['UserID'] = this.userService.user.id;
      }

      let options: RequestOptionsArgs = {
        headers: new Headers({
          'X-Parse-Application-Id': parseAppID
        }) 
      }

      this.http.post(url, body, options).map(res => res.json()).subscribe(data => {
          console.log('Save installation to parse success.');
        }, error => {
          console.log('Save installation to parse failure.');
          console.log(error);
        });
    })
  }

}
