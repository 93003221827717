import { Injectable } from '@angular/core';

import { Appointment } from "./../models/appointment";
import { ApptService } from "./appt-service";
import { CloudCode } from "./cloudcode";
import { UserService } from "./user-service";
import { Timezone } from "./timezones";
import { Constants } from "./../models/constants";
import { EventBarber } from './../models/public-event-info';

export interface EventCosts {
  subtotal: number
  total: number
  tax: number
  currency: string
}

export interface EventTimeslot {
  id: string
  eventName: string
  sponsorName: string
  requiresPayment: boolean
  selectedService: any
  startTime: Date
  timezone: Timezone
  barber: EventBarber
  costs: EventCosts
}


@Injectable({ providedIn: 'root' })
export class ReviewService {

  constructor(private userService: UserService) {
  }

  fetchReviewIfNeeded(
    successCallback: (appt: Appointment) => void,
    errorCallback: (msg: string) => void
  ) {
    ApptService.fetchApptForReview(successCallback, errorCallback);
  }

  fetchEventReviewIfNeeded(
    successCallback: (eventTimeslot: EventTimeslot) => void,
    errorCallback: (msg: string) => void
  ) {
    this.fetchEventForReview(successCallback, errorCallback);
  }

  fetchEventForReview(
    successCallback: (eventTimeslot: EventTimeslot) => void,
    errorCallback: (msg: string) => void
  ) {

    CloudCode.run('pullEventToReview', {}, 
      (eventTimeslot: EventTimeslot) => {
        successCallback(eventTimeslot);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  skipApptReview(
    apptID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    const params: any = {
      apptID: apptID
    }

    CloudCode.run('skipApptReview', params,
      (rsp: any) => {
        successCallback();
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  submitReview(
    apptID: string,
    tip: number,
    rating: number,
    notes: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params: any = {
      apptID: apptID,
      tip: tip,
      rating: rating,
    }

    if (notes) {
      params.notes = notes;
    }

    if (tip > 0) {
      if (this.userService.user.customer) {
        params.customerID = this.userService.user.customer.id;
      } else {
        errorCallback(Constants.NO_PAYMENT);
        return;
      }
    }

    CloudCode.run('reviewAppt', params, 
      (rsp: any) => {
        this.userService.fetch(() => {
          successCallback();
        }, (errorMsg) => {
          successCallback();
        })
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }


  submitEventReview(
    eventTimeslotID: string,
    tip: number,
    rating: number,
    notes: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params: any = {
      eventTimeslotID: eventTimeslotID,
      tip: tip,
      rating: rating,
    }

    if (notes) {
      params.notes = notes;
    }

    if (tip > 0) {
      if (this.userService.user.customer) {
        params.customerID = this.userService.user.customer.id;
      } else {
        errorCallback(Constants.NO_PAYMENT);
        return;
      }
    }

    CloudCode.run('reviewEventTimeslot', params, 
      (rsp: any) => {
        successCallback();
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  additionalTip_Appt(
    apptID: string,
    tip: number,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params: any = {
      apptID: apptID,
      tip: tip,
    }

    if (this.userService.user.customer) {
      params.customerID = this.userService.user.customer.id;
    } else {
      errorCallback(Constants.NO_PAYMENT);
      return;
    }

    CloudCode.run('additionalTip_Appt', params, 
      (rsp: any) => {
        successCallback();
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  additionalTip_Event(
    eventTimeslotID: string,
    tip: number,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params: any = {
      eventTimeslotID: eventTimeslotID,
      tip: tip,
    }

    if (this.userService.user.customer) {
      params.customerID = this.userService.user.customer.id;
    } else {
      errorCallback(Constants.NO_PAYMENT);
      return;
    }

    CloudCode.run('additionalTip_Event', params, 
      (rsp: any) => {
        successCallback();
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }
}
