import { Component, OnInit, Input } from '@angular/core';

import { SelectedServiceType, SelectedServiceTypeList } from '../../models/selected-service-type-list';

import { EventHistoryCosts } from '../../services/order-history-tasks';
import { Util } from '../../services/util';

@Component({
  selector: 'invoice-receipt',
  templateUrl: './invoice-receipt.component.html',
  styleUrls: ['./invoice-receipt.component.scss'],
})
export class InvoiceReceiptComponent implements OnInit {

  @Input() costs: EventHistoryCosts;
  @Input() serviceTypeList: SelectedServiceType[];
  @Input() refunded: boolean;

  constructor() { }

  ngOnInit() { }

  formatServicesLabel() {
    let list = new SelectedServiceTypeList()
    list.items = this.serviceTypeList
    list.refreshText()
    return list.text
  }

  formatCost(num: number) {
    return Util.formatCost(num, this.costs.currency)
  }

  formatPercentage(p: number) {
    return Util.formatPercentage(p)
  }

}
