import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { CloudCode } from "./cloudcode";
import { Storage } from '@ionic/storage';
import { Constants } from "./../models/constants";
import { UserService } from "./user-service";
import { EventSeriesInfo } from './../models/event-series-info';
import { GiftCard } from './gift-cards-service';
import { ObservableService } from './observable-service';

export interface PromoActivation {
  title: string
  description: string
  img: string
  actionText: string
  action: string
}

@Injectable({ providedIn: 'root' })
export class PromoService {

  constructor(
    private storage: Storage, 
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private userService: UserService,
    private observables: ObservableService) {
  }

  checkPromoValidity(
    code: string,
    successCallback: (barberID?: string, barberLink?: string, activation?: PromoActivation, eventRedirectInfo?: EventSeriesInfo[], giftCardData?: GiftCard) => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      coupon: code
    }

    CloudCode.run('checkCoupon', params, 
      (rsp: any) => {
        successCallback(rsp.barberID, rsp.barberLink, rsp.activation, rsp.eventRedirectData, rsp.giftCardData);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  storeCoupon(code: string) {
    this.storage.set(Constants.PROMO_CODE, code);
  }

  getStoredCoupon(callback: (promo?: string) => void) {
    this.storage.get(Constants.PROMO_CODE).then((val) => {
      callback(val);
    });
  }

  clearCoupon() {
    this.storage.set(Constants.PROMO_CODE, null);
  }

  async displayPromoActivation(activation: PromoActivation) {

    let alert = await this.alertCtrl.create({
        header: activation.title,
        message: activation.description,
        buttons: [
          {
            text: activation.actionText,
            handler: data => {
              this.handleActivationAction(activation.action)
            }
          }
        ]
      });
      
      alert.present();
  }

  async handleActivationAction(action: string) {

    switch (action) {

      case 'activate-massage':

        // NOTE: We don't use this anymore. Keeping as an example.

        // let newParams: any = {
        //   activatedMassage: true,
        //   currentProType: 'Massage',
        // }

        // let loading = await this.loadingCtrl.create()
        // loading.present()

        // this.userService.update(newParams, 
        //   () => {
        //       loading.dismiss()
        //       this.observables.publishRefreshMainViewForceBooking()
        //   }, 
        //   (msg: string) => { 
        //       loading.dismiss()
        //       alert(msg)
        // });

        break
    }

  }

}
