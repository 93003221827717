import { Injectable } from '@angular/core';

import { Constants } from "./../models/constants";
import { CloudCode } from "./cloudcode";
import { UserService, Customer } from "./user-service";

export interface CreditCard {
  name: string;
  number: string;
  expMonth: number;
  expYear: number;
  cvc: string;
  postal_code: string;
}

@Injectable({ providedIn: 'root' })
export class PaymentService {

  constructor(
    private userService: UserService
  ) {
  }

  createConciergeCustomer(
    token: any,
    cardholderName: string,
    successCallback: (customer: Customer) => void,
    errorCallback: (msg: string) => void) {

    console.log(token)

    let params = {
      stripeToken: token.id,
      cardholderName: cardholderName,
    };

    CloudCode.run('createConciergeCustomer', params, 
      (customer: Customer) => {
        successCallback(customer)
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  createCustomer(
    token: any,
    cardholderName: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void) {

    console.log(token)

    let params = {
      stripeToken: token.id,
      cardholderName: cardholderName,
    };

    CloudCode.run('createCustomer', params, 
      (rsp: any) => {
        this.userService.fetch(() => {
          successCallback();
        }, (msg) => {
          errorCallback(msg);
        });
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  selectPayment(
    customerID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      id: customerID
    }
    CloudCode.run('selectCustomer', params, 
      (rsp: any) => {
        this.userService.fetch(() => {
          successCallback();
        }, (msg) => {
          errorCallback(msg);
        });
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  removePayment(
    customerID: string,
    successCallback: () => void,
    errorCallback: (msg: string) => void
  ) {
    let params = {
      id: customerID
    }
    CloudCode.run('removeCustomer', params, 
      (rsp: any) => {
        this.userService.fetch(() => {
          successCallback();
        }, (msg) => {
          errorCallback(msg);
        });
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

}
