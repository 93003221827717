import { CloudCode } from './cloudcode';

import { Address } from '../models/address';
import { ApptService, ClientConfigResponse } from './appt-service';

export interface ReserveApptRequestParams {
  customerID: string
  serviceTypeList: any[]
  startTime: Date
  availID: string
  timezoneOffset: number
  timezoneAbbreviation: string
  address: Address
  type: string
  device: string
  barberProfileNavHistory: string
  coupon?: string
}

export enum RequestMessageInfoType {
  ApptRequest = 'appt-request',
  ProductSaleRequest = 'product-sale-request',
}

export interface ApptRequestAlertInfo {
  roomID: string
  startTime: Date
  timezoneOffset: number
  barberID: string
  barberPic: string
  barberFullName: string
  barberFirstName: string
  isReschedule: boolean
}

export interface RequestMessageInfo {
  type: RequestMessageInfoType
  roomID: string
  startTime?: Date
  timezoneOffset?: number
  barberID: string
  barberPic: string
  barberFullName: string
  barberFirstName: string
  isReschedule: boolean
}

export class ApptRequestTasks {

  static reserveAppt(
    params: ReserveApptRequestParams,
    successCallback: (apptId: string) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('reserveAppt', 
      params,
      (rsp: any) => {
        if (rsp.id) {
          successCallback(rsp.id);
        } else {
          errorCallback('Something went wrong.');
        }
      }, 
      (msg: string) => {

        errorCallback(msg)

        // Error from server. Fetch appointments to see if appointment creation succeeded anyway.
        // ApptService.pullClientConfig((clientConfig: ClientConfigResponse) => {

        //   if (clientConfig.upcomingAppts && clientConfig.upcomingAppts.length > 0) {
        //     successCallback(clientConfig.upcomingAppts[0].id)
        //   } else {
        //     errorCallback(msg)
        //   }
    
        // }, (msg: string) => {
        //   errorCallback(msg);
        // })
        
      });
  }

  static calculateCost(
    apptRequestID: string,
    promoCode: string,
    successCallback: (costs: any) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('calculateCost_ApptRequest', 
      {
        apptRequestID: apptRequestID,
        promoCode: promoCode,
      },
      (costs: any) => {
        successCallback(costs);
      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static checkForActiveApptRequest(
    successCallback: (info: ApptRequestAlertInfo) => void,
    errorCallback: (msg: string) => void
  ) {
    CloudCode.run('checkForActiveApptRequest', {},
      (info: ApptRequestAlertInfo) => {
        successCallback(info);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  static checkForPendingRequestMessage(
    successCallback: (info: RequestMessageInfo) => void,
    errorCallback: (msg: string) => void
  ) {
    CloudCode.run('checkForPendingRequestMessage', {},
      (info: RequestMessageInfo) => {
        successCallback(info);
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }
  
}