import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-special-instructions',
  templateUrl: './add-special-instructions.page.html',
  styleUrls: ['./add-special-instructions.page.scss'],
})
export class AddSpecialInstructionsPage implements OnInit {

  entry = { text: "" }

  constructor(
    public modalCtrl: ModalController,
    private router: Router,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
  }

  save() {
    this.modalCtrl.dismiss({
      specialInstructions: this.entry.text
    });
  }

  cancel() {
      this.modalCtrl.dismiss({});
  }

}
