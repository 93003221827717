<ion-content>

	<div class="logo">
		<img src="assets/img/shortcut-icon.png" alt="Shortcut">
	</div>

	<p class="SCWEB-header-text">Welcome back!</p>

	<p id="description-text">
		Don't have an account? 
		<span id="signup-text-button" (click)="gotoSignup()">Sign up</span>
	</p>

	<form #loginForm="ngForm" id="login-form">
		<ion-item lines="none" class="email-item">
			<ion-input [(ngModel)]="loginFields.email" name="email" type="email" #email="ngModel" spellcheck="false"
				autocapitalize="off" required placeholder="Email address" (keyup.enter)="onLogin(loginForm)">
			</ion-input>
		</ion-item>

		<ion-item lines="none">
			<ion-input [(ngModel)]="loginFields.password" name="password" type="password" #password="ngModel" required
				placeholder="Password" (keyup.enter)="onLogin(loginForm)">
			</ion-input>
		</ion-item>

		<p *ngIf="errorStatus" style="text-align: center; color: red">
			{{errorStatus}}
		</p>

		<p id="forgot-pass-text-button" (click)="onForgotPassword()">
			<i>Forgot password?</i>
		</p>

		<ion-button class="SC-long-button" color="marine" (click)="onLogin(loginForm)" type="submit" block>
			Sign in
		</ion-button>

		<hr class="SC-or-horizontal-rule">
		
		<ion-button class="SC-long-button" color="facebook" (click)="onLogin_Facebook()" block>
			<div class="auth-button-content">
				<ion-img src="assets/img/facebook.png" class="auth-button-icon"></ion-img>
				<span class="auth-button-text">Sign in with Facebook</span>
			</div>
		</ion-button>

		<ion-button class="SC-long-button" color="white" id="google" block>
			<div class="auth-button-content">
				<ion-img src="assets/img/google.png" class="auth-button-icon"></ion-img>
				<span class="auth-button-text">Sign in with Google</span>
			</div>
		</ion-button>

		<ion-row class="SC-bottom-button-row">
			<p class="SC-light-bottom-button">View our <span (click)="gotoTerms()" class="terms-button">terms of service</span> and <span (click)="gotoPrivacy()" class="terms-button">privacy policy</span>.</p>
		</ion-row>
	</form>

</ion-content>