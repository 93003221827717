<ion-content>

  <div *ngIf="appt" icon-only (click)="onCancel()"class="close-button">
    <ion-icon name="close" color="grey"></ion-icon>
  </div>

  <p class="SCWEB-header-text">Rate and review your<br>appointment!</p>

  <div class="pro-img" *ngIf="appt || eventTimeslot">
    <img [src]="appt ? appt.barber.pic : eventTimeslot.barber.pic" />
  </div>

  <p class="appt-info-text" *ngIf="appt || eventTimeslot">
    <span class="pro-name">{{appt ? appt.barber.fullName : eventTimeslot.barber.fullName}}</span>
    <br>
    {{appt ? getLongDateString(appt.startTime, appt.timezone) : getLongDateString(eventTimeslot.startTime, eventTimeslot.timezone)}}
  </p>

  <div id="stars-wrapper">
    <span class="stars">
      <ion-icon name="star" *ngIf="this.selectedRating > 0" (click)="selectedRating = 1"></ion-icon>
      <ion-icon name="star" *ngIf="this.selectedRating > 1" (click)="selectedRating = 2"></ion-icon>
      <ion-icon name="star" *ngIf="this.selectedRating > 2" (click)="selectedRating = 3"></ion-icon>
      <ion-icon name="star" *ngIf="this.selectedRating > 3" (click)="selectedRating = 4"></ion-icon>
      <ion-icon name="star" *ngIf="this.selectedRating > 4" (click)="selectedRating = 5"></ion-icon>
      <ion-icon name="star-outline" *ngIf="this.selectedRating < 1" (click)="selectedRating = 1"></ion-icon>
      <ion-icon name="star-outline" *ngIf="this.selectedRating < 2" (click)="selectedRating = 2"></ion-icon>
      <ion-icon name="star-outline" *ngIf="this.selectedRating < 3" (click)="selectedRating = 3"></ion-icon>
      <ion-icon name="star-outline" *ngIf="this.selectedRating < 4" (click)="selectedRating = 4"></ion-icon>
      <ion-icon name="star-outline" *ngIf="this.selectedRating < 5" (click)="selectedRating = 5"></ion-icon>
    </span>

    <p>How was your service?</p>
  </div>

  <ion-textarea placeholder="Write a review" [(ngModel)]="notes"></ion-textarea>

  <!-- Tip choices -->
  <div id="barber-prof-availabilities">

    <div scrollX="true" id="avail-card-scroller">
      <ion-card *ngFor="let tipChoice of tipChoices" class="avail-card SC-clickable"
        (click)="tipChoicePressed(tipChoice)"
        [ngClass]="tipChoice.id == selectedTipChoice.id ? 'highlighted-avail-card' : ''">
        <ion-card-content>
          <ion-icon name="create" *ngIf="tipChoice.showIcon"></ion-icon>
          <p>{{ tipChoice.displayString }}</p>
        </ion-card-content>
      </ion-card>
    </div>
  </div>

  <ion-button color="marine" (click)="submit()" id="submit-button" class="SC-long-button">
    {{tipButtonText()}}</ion-button>

  <ion-button color="white" (click)="receipt()" id="receipt-button" class="SC-long-button">
    View receipt</ion-button>

</ion-content>