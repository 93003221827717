import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { Address } from './../models/address';

import { Timezones } from './timezones';
import { Alert } from './alert';

@Injectable({ providedIn: 'root' })
export class CurrentLocationService {

  constructor(
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    private alert: Alert
  ) {}

  fetchCurrentLocation(
    successCallback: (address: Address) => void,
    errorCallback: (msg: string) => void 
  ) {
    this.geolocation.getCurrentPosition({
      timeout: 8000,
      enableHighAccuracy: true
    }).then((resp) => {

      console.log(resp)

      if (resp.coords && resp.coords.accuracy > 800) {
        // Over 0.5mi inaccuracy.
        errorCallback("Your browser could not get an accurate reading of your location. Please enter your address instead.")
        return
      }

      let coordinates = { latitude: resp.coords.latitude, longitude: resp.coords.longitude }

      Timezones.addressForCoordinates(coordinates, (address: Address) => {

        successCallback(address)

      }, (errMsg: string) => {
        console.log(errMsg);
        errorCallback(errMsg)
      })
    }).catch((error) => {
      errorCallback('Error getting current location.')
      console.log(error);
    });
  }
}
