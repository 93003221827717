import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import {
  NavController,
  Platform,
  LoadingController,
  ActionSheetController,
  IonContent,
  IonTextarea,
} from '@ionic/angular';
import { NavigationExtras } from '@angular/router';

import * as Parse from 'parse';

import { Constants } from '../../models/constants';
import { Barber } from '../../models/barber';
import { AppointmentTime } from '../../models/appointment-time';
import {
  Message,
  Room,
  MessageType,
  ProductSaleRequestInfo,
} from '../../models/message';
import { SelectedServiceTypeList } from '../../models/selected-service-type-list';
import { CountdownTimer } from '../../models/countdown-timer';

import { BarberService } from '../../services/barber-service';
import { ApptCreator } from '../../services/appt-creation-service';
import { Alert } from '../../services/alert';
import { MessageTasks } from '../../services/message-tasks';
import { RoomTasks } from '../../services/room-tasks';
import { Util } from '../../services/util';
import { PicService, PicFiles } from '../../services/pic-service';
import { UserService } from '../../services/user-service';
import { AnalyticsService } from '../../services/analytics-service';

import { Storage } from '@ionic/storage';
import { ObservableService } from 'src/app/services/observable-service';
import { ProductQuantityList } from 'src/app/services/product-sale-request-tasks';

@Component({
  selector: 'chat-content',
  templateUrl: './chat-content.component.html',
  styleUrls: ['./chat-content.component.scss'],
})
export class ChatContentComponent implements OnInit {
  @Output() newUnreadMessageForRoom = new EventEmitter<string>();
  @Output() resetUnreadMessagesForRoom = new EventEmitter<string>();

  @Input() roomID: string;
  @Input() room: Room;
  @Input() windowOpened: boolean;

  @ViewChild('chat_content', { read: IonContent }) chatContent: IonContent;
  @ViewChild('chat_input', { read: IonTextarea }) messageInput: IonTextarea;

  formatCost = Util.formatCost;
  prettyTime = Util.prettyTime;
  prettyTimeAdjusted = Util.prettyTimeAdjusted;
  dayMonthDateYear = Util.dayMonthDateYear;
  timestampDayLowercase = Util.timestampDayLowercase;
  expandTextArea = false;

  placeholderImg = 'assets/img/profile-placeholder.png';

  messages: Message[];

  timestampedMessages: {
    timestamp: string;
    messages: Message[];
  }[];

  loadingNextBatch = false;

  sendingMessages: any[] = [];

  activeSenderID: string;
  latestApptRequestID?: string;
  latestInvoiceID?: string;

  chatReceivedSound: any;

  errorMsg?: string;

  editor = {
    msg: '',
  };

  // Subscriptions
  private updateChatRoomInfoSubscription: any;
  private liveMessageCreateSubscription: any;
  private liveMessageUpdateSubscription: any;
  private chatToggledOpenSubscription: any;

  constructor(
    public userService: UserService,
    private navCtrl: NavController,
    private alert: Alert,
    private actionSheetCtrl: ActionSheetController,
    private picService: PicService,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private observables: ObservableService,
    private analytics: AnalyticsService,
    private storage: Storage,
    private changeRef: ChangeDetectorRef,
    private apptCreator: ApptCreator,
  ) {
    this.platform.resume.subscribe((result: any) => {
      this.reload();
    });

    this.setupSound();
  }

  ngOnInit() {
    console.log(this.room);
    this.activeSenderID = Parse.User.current().id;
    this.loadRoomInfo();
    this.pullMessages();

    this.getSavedMessage();

    this.updateChatRoomInfoSubscription =
      this.observables.updateChatRoomInfo.subscribe((roomID: string) => {
        if (this.roomID == roomID) {
          this.loadRoomInfo();
        }
      });

    this.liveMessageCreateSubscription =
      this.observables.liveMessageCreate.subscribe((msg: any) => {
        if (msg.get('roomID') == this.roomID) {
          this.createMsg(msg);
        }
      });

    this.liveMessageUpdateSubscription =
      this.observables.liveMessageUpdate.subscribe((msg: any) => {
        if (msg.get('roomID') == this.roomID) {
          this.updateMsg(msg);
        }
      });

    this.chatToggledOpenSubscription =
      this.observables.chatToggledOpen.subscribe((roomID: string) => {
        if (roomID == this.roomID) {
          this.focus();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.windowOpened) {
      this.markLastMessageRead();
    }
  }

  ngOnDestroy() {
    this.updateChatRoomInfoSubscription.unsubscribe();
    this.liveMessageCreateSubscription.unsubscribe();
    this.liveMessageUpdateSubscription.unsubscribe();
    this.chatToggledOpenSubscription.unsubscribe();
  }

  ionViewWillLeave() {
    this.saveCurrentMessage();
  }

  private saveCurrentMessage() {
    let savedMessage = this.editor.msg;
    this.storage.set(Constants.SAVED_MESSAGES + this.roomID, savedMessage);
  }

  private getSavedMessage() {
    this.storage.get(Constants.SAVED_MESSAGES + this.roomID).then((val) => {
      if (val) {
        this.editor.msg = val;
      }
    });
  }

  reload() {
    this.pullMessages();
  }

  private setupSound() {
    this.chatReceivedSound = document.createElement('audio');
    this.chatReceivedSound.src = 'assets/sounds/chat-received.mp3';
    this.chatReceivedSound.setAttribute('preload', 'auto');
    this.chatReceivedSound.setAttribute('controls', 'none');
    this.chatReceivedSound.volume = 0.5;
    this.chatReceivedSound.style.display = 'none';
    document.body.appendChild(this.chatReceivedSound);
  }

  private playChatReceivedSound() {
    this.chatReceivedSound.play();
  }

  // GETTERS

  private loadRoomInfo() {
    if (!this.room) {
      this.error('Chat must have Room');
      return;
    }

    if (this.room.roomApptInfo && this.room.roomApptInfo.expiryDate) {
      const expiryTime = CountdownTimer.getTimerMinSec(
          this.room.roomApptInfo.expiryDate,
        ),
        timer = new CountdownTimer(expiryTime.minLeft, expiryTime.secLeft);

      timer.start();

      this.room.roomApptInfo.countdownTimer = timer;
    }

    this.changeRef.detectChanges();

    if (this.room.type == 'client-barber') {
      this.analytics.track(AnalyticsService.OpenProChat);
    } else if (this.room.type == 'client-concierge') {
      this.analytics.track(AnalyticsService.OpenConciergeChat);
    }
  }

  pullMessages(refresher?: any) {
    let loadNextBatch = Util.isDefined(refresher);

    let batchBeforeDate;
    if (loadNextBatch && this.messages.length) {
      if (this.loadingNextBatch) return;
      batchBeforeDate = this.messages[0].createdAt;
      this.loadingNextBatch = true;
    }

    MessageTasks.pullMessages(
      this.roomID,
      batchBeforeDate,
      (messages: Message[]) => {
        for (var i = 0; i < messages.length; i++) {
          var msg = messages[i];

          if (msg.apptRequestInfo) {
            msg.apptRequestInfo.startTime = new Date(
              msg.apptRequestInfo.startTime,
            );
            msg.apptRequestInfo.expiryDate = new Date(
              msg.apptRequestInfo.expiryDate,
            );
          }
          if (msg.invoiceRequestInfo) {
            msg.invoiceRequestInfo.startTime = new Date(
              msg.invoiceRequestInfo.startTime,
            );
            msg.invoiceRequestInfo.expiryDate = new Date(
              msg.invoiceRequestInfo.expiryDate,
            );
          }
          if (msg.reservedApptInfo) {
            msg.reservedApptInfo.startTime = new Date(
              msg.reservedApptInfo.startTime,
            );
          }
        }

        if (loadNextBatch) {
          this.messages = messages.concat(this.messages);
          this.timestampedMessages = this.createTimestampedMessages(
            messages,
          ).concat(this.timestampedMessages);
          this.loadingNextBatch = false;
          if (refresher) {
            refresher.target.complete();
          }
        } else {
          this.messages = messages;
          this.timestampedMessages = this.createTimestampedMessages(messages);
          this.scrollToBottom();
          this.markLastMessageRead();
        }

        this.changeRef.detectChanges();
        if (this.windowOpened) {
          this.focus();
        }
      },
      (err: any) => {
        console.error(err);
        this.errorMsg = err;
      },
    );
  }

  private createTimestampedMessages(messages: Message[]) {
    var timestampObjs: any = {};
    var timestampedMessages = [];

    for (var i = 0; i < messages.length; i++) {
      let m = messages[i];

      if (m.apptRequestInfo) {
        this.latestApptRequestID = m.apptRequestInfo.id;
        if (this.isPendingApptRequest(m.apptRequestInfo)) {
          this.addTimer(m);
        }
      } else if (m.invoiceRequestInfo) {
        this.latestInvoiceID = m.invoiceRequestInfo.id;
        if (this.isPendingInvoice(m.invoiceRequestInfo)) {
          this.addTimer(m);
        }
      }

      let timestamp = Util.timestampDay(m.createdAt);

      if (!timestampObjs[timestamp]) {
        timestampObjs[timestamp] = [m];
        timestampedMessages.push({
          timestamp: timestamp,
          messages: [m],
        });
      } else {
        timestampObjs[timestamp].push(m);
      }
    }

    for (var i = 0; i < timestampedMessages.length; i++) {
      let timestamp = timestampedMessages[i].timestamp;
      timestampedMessages[i].messages = timestampObjs[timestamp];
    }

    return timestampedMessages;
  }

  private newMessage(m: Message) {
    let timestamp = Util.timestampDay(new Date());

    let sendingIDs = this.sendingMessages.map((m) => {
      return m.sendingID;
    });
    if (m.sendingID && Util.contains(sendingIDs, m.sendingID)) {
      Util.removeItemWithField(this.sendingMessages, 'sendingID', m.sendingID);
    }

    if (!this.messages || !this.messages.length) {
      this.timestampedMessages = [
        {
          timestamp: timestamp,
          messages: [m],
        },
      ];

      this.messages = [m];
      this.changeRef.detectChanges();
      return;
    }

    let lastTimestampedMessages =
      this.timestampedMessages[this.timestampedMessages.length - 1];

    if (lastTimestampedMessages.timestamp == timestamp) {
      lastTimestampedMessages.messages.push(m);
      this.messages.push(m);
    } else {
      this.timestampedMessages.push({
        timestamp: timestamp,
        messages: [m],
      });

      this.messages.push(m);
    }

    if (m.sender.id !== this.activeSenderID) {
      if (!this.windowOpened) {
        this.newUnreadMessage();
      } else if (document.hidden) {
        this.playChatReceivedSound();
      }
    }

    this.markLastMessageRead();
    this.changeRef.detectChanges();
  }

  // -- new messages --

  private formatMsg(msg: any) {
    msg.msgType = msg.get('msgType');
    msg.text = msg.get('text');
    msg.sender = msg.get('sender');
    msg.apptRequestInfo = msg.get('apptRequestInfo');
    msg.invoiceRequestInfo = msg.get('invoiceRequestInfo');
    msg.reservedApptInfo = msg.get('reservedApptInfo');
    msg.photoURL = msg.get('photoURL');
    msg.sendingID = msg.get('sendingID');

    var hasReadList = msg.get('hasReadList');
    var isUnread = true;
    if (hasReadList && Util.contains(hasReadList, this.activeSenderID)) {
      isUnread = false;
    }

    msg.isUnread = isUnread;

    if (msg.apptRequestInfo) {
      this.latestApptRequestID = msg.apptRequestInfo.id;
      msg.apptRequestInfo.startTime = new Date(msg.apptRequestInfo.startTime);
      msg.apptRequestInfo.expiryDate = new Date(msg.apptRequestInfo.expiryDate);
      this.addTimer(msg);
      this.observables.publishUpdateAllChatRoomInfo();
    }
    if (msg.invoiceRequestInfo) {
      this.latestInvoiceID = msg.invoiceRequestInfo.id;
      msg.invoiceRequestInfo.startTime = new Date(
        msg.invoiceRequestInfo.startTime,
      );
      msg.invoiceRequestInfo.expiryDate = new Date(
        msg.invoiceRequestInfo.expiryDate,
      );
      this.addTimer(msg);
    }
    if (msg.reservedApptInfo) {
      msg.reservedApptInfo.startTime = new Date(msg.reservedApptInfo.startTime);
    }
  }

  private addTimer(msg: Message) {
    let requestInfo = msg.apptRequestInfo || msg.invoiceRequestInfo;

    const expiryTime = CountdownTimer.getTimerMinSec(requestInfo.expiryDate),
      timer = new CountdownTimer(expiryTime.minLeft, expiryTime.secLeft, msg);
    timer.delegate = this;
    timer.start();
    requestInfo.countdownTimer = timer;
  }

  updateTimer() {
    this.changeRef.detectChanges();
  }

  //
  // -- SENDING CHATS --
  //

  tapContent() {
    this.expandTextArea = false;
  }

  adjustTextarea(event: any): void {
    let textarea: any = event.target;
    if (textarea.scrollHeight > 100) {
      this.expandTextArea = true;
    }
  }

  onFocus() {
    // this.chatContent.resize();
    this.scrollToBottom();
  }

  onFocusOut() {
    // this.chatContent.resize();
  }

  onEnterKeydown(event: any) {
    event.preventDefault();
  }

  sendMsg() {
    if (!this.room) return;
    if (!this.editor.msg.trim()) return;

    if (
      !this.userService.user.fullName ||
      this.userService.user.fullName === ''
    ) {
      const backgroundDismiss = false;
      this.alert.showPrompt(
        'Enter full name',
        null,
        'name',
        'Name',
        'text',
        'Submit',
        async (data) => {
          let fullName = data.name.trim();
          if (!Util.validateFullName(fullName)) {
            this.error('Please enter your full name.');
            return;
          }
          let firstName = Util.getFirstName(fullName);
          let lastName = Util.getLastName(fullName);

          let loading = await this.loadingCtrl.create();
          loading.present();

          let newParams: any = {
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
          };

          this.userService.update(
            newParams,
            () => {
              loading.dismiss();
              this.sendMsg(); // Recursively call sendMsg again to send the message the user typed.
            },
            (msg: string) => {
              loading.dismiss();
              this.error(msg);
            },
          );
        },
        undefined,
        undefined,
        undefined,
        backgroundDismiss,
      );
      return;
    } else if (
      this.userService.user.isGuest &&
      !this.userService.user.tempPhoneNumber
    ) {
      const backgroundDismiss = false;
      this.alert.showPrompt(
        'Enter phone number for updates',
        null,
        'phone',
        'Phone number',
        'number',
        'Submit',
        (data) => {
          let phone = data.phone.trim();
          if (phone && phone !== '') {
            this.userService.storeTempPhoneNumber(
              phone,
              () => {
                // Nothing to do in success block
              },
              (err: string) => {
                // Nothing to do in error block
              },
            );
          }
        },
        undefined,
        undefined,
        undefined,
        backgroundDismiss,
      );
    }

    let newMsg: any = {
      sendingID: Util.generatePass(8),
      msgType: MessageType.Text,
      text: this.editor.msg.trim(),
    };

    this.sendingMessages.push(newMsg);

    this.errorMsg = undefined;
    this.editor.msg = '';
    this.expandTextArea = false;
    this.focus();
    this.changeRef.detectChanges();

    this.saveCurrentMessage();

    this.sendLiveMessage(newMsg);

    this.observables.publishChatMessageSent();

    if (this.room.type == 'client-barber') {
      this.analytics.track(AnalyticsService.SendProChat);
    } else if (this.room.type == 'client-concierge') {
      this.analytics.track(AnalyticsService.SendConciergeChat);
    }
  }

  updateTextAreaModel() {
    this.changeRef.detectChanges();
  }

  scrollToBottom(instantly = false) {
    setTimeout(
      () => {
        if (this.chatContent && this.chatContent.scrollToBottom) {
          let duration = 0;
          this.chatContent.scrollToBottom(duration);
        }
      },
      instantly ? 0 : 200,
    );
  }

  private focus() {
    if (this.messageInput) {
      this.messageInput.setFocus();
      // this.messageInput.nativeElement.setFocus();
    }
  }

  private async setTextareaScroll() {
    const textarea = await this.messageInput.getInputElement();
    textarea.scrollTop = textarea.scrollHeight;
  }

  private sendLiveMessage(params: any) {
    params.roomID = this.roomID;

    var liveMessage = new (Parse.Object as any)('LiveMessage');
    liveMessage.save(params).then(
      () => {
        // Nothing
        this.scrollToBottom(true);
      },
      (error: any) => {
        console.error(error.message);
        this.errorMsg = error.message;
        this.sendingMessages = [];
        this.changeRef.detectChanges();
        this.scrollToBottom();
      },
    );
  }

  private newUnreadMessage() {
    this.newUnreadMessageForRoom.emit(this.roomID);
    this.playChatReceivedSound();
  }

  private updateMsg(msg: any) {
    console.log('Update msg: ' + msg.id);

    if (!this.messages) return;

    this.formatMsg(msg);

    for (var i = 0; i < this.messages.length; i++) {
      if (this.messages[i].id == msg.id) {
        this.messages[i] = msg;
        break;
      }
    }
    for (var i = 0; i < this.timestampedMessages.length; i++) {
      var messages = this.timestampedMessages[i].messages;

      for (var j = 0; j < messages.length; j++) {
        if (messages[j].id == msg.id) {
          messages[j] = msg;
          break;
        }
      }
    }
  }

  private createMsg(msg: any) {
    console.log('Create msg: ' + msg.id);
    this.formatMsg(msg);
    this.newMessage(msg);
    this.scrollToBottom();
  }

  // Send images

  sendPhoto() {
    let shouldScale = false;
    let shouldResize = false;
    let showActionSheet = true;

    this.picService.getPhoto(
      shouldScale,
      shouldResize,
      showActionSheet,
      async (files: PicFiles) => {
        var newMsg = {
          sendingID: Util.generatePass(8),
          msgType: 'photo',
        };

        this.sendingMessages.push(newMsg);
        this.changeRef.detectChanges();
        this.scrollToBottom();

        files.pic.save().then(
          (savedFile: any) => {
            newMsg['photoURL'] = savedFile._url;
            this.sendLiveMessage(newMsg);
          },
          (errMsg: string) => {
            if (errMsg) {
              this.alert.show('Error', errMsg, 'Ok');
            }
          },
        );
      },
      (errorMsg: string) => {
        if (errorMsg) {
          this.alert.show('Error', errorMsg, 'Ok');
        }
      },
    );
  }

  canSendImage() {
    return (
      this.room.type == 'client-concierge' ||
      this.room.type == 'client-admin' ||
      this.room.clientCanSendImage
    );
  }

  openPhoto(photoURL: string) {
    if (!photoURL) {
      this.alert.show('Error', 'No photo URL');
      return;
    }

    // if (!Constants.BROWSER_MODE) {
    //   this.photoViewer.show(photoURL);
    // } else {
    window.open(
      photoURL,
      '_blank',
      'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes',
    );
    // }
  }

  getMessageTooltip(m: Message) {
    return Util.prettyTime(m.createdAt);
  }

  // MESSAGE / ROOM functions

  private markLastMessageRead() {
    // console.log('try mark read')
    if (!this.messages) {
      console.log("Don't mark read: no messages");
      return;
    }
    if (!this.messages.length) {
      console.log("Don't mark read: no messages length");
      return;
    }
    if (!this.windowOpened) {
      console.log("Don't mark read: chat closed");
      return;
    }

    this.resetUnreadMessagesForRoom.emit(this.roomID);

    let lastMsg = this.messages[this.messages.length - 1];

    if (lastMsg.sender.id == this.activeSenderID) {
      console.log("Don't mark read: same sender");
      return;
    }
    if (!lastMsg.isUnread) {
      console.log("Don't mark read: message already read");
      return;
    }

    MessageTasks.markHasRead(
      lastMsg.id,
      () => {
        lastMsg.isUnread = false;
        this.observables.publishCheckUnreadMessages();
      },
      (error: any) => {
        console.error(error);
      },
    );
  }

  private toggleMuteRoom(shouldMute: boolean) {
    RoomTasks.toggleMuteRoom(
      this.roomID,
      shouldMute,
      () => {
        this.room.isMuted = shouldMute;
        this.changeRef.detectChanges();
      },
      (err: string) => {
        alert(err);
      },
    );
  }

  countdownEnded(msg?: any) {
    if (msg && msg.apptRequestInfo) {
      this.apptRequestExpired(msg);
    } else if (msg && msg.invoiceRequestInfo) {
      this.invoiceRequestExpired(msg);
    }

    this.observables.publishUpdateAllChatRoomInfo();
    this.changeRef.detectChanges();
  }

  // BOOLEANS FOR UI

  hasRoomApptInfo() {
    return this.room && this.room.roomApptInfo;
  }

  isExpired(requestInfo: any) {
    return requestInfo.expired || new Date() > requestInfo.expiryDate;
  }

  isPendingInvoice(invoiceRequestInfo: any) {
    return !this.isExpired(invoiceRequestInfo) && !invoiceRequestInfo.paid;
  }

  isPendingApptRequest(apptRequestInfo: any) {
    return !this.isExpired(apptRequestInfo) && !apptRequestInfo.reserved;
  }

  reservedApptHeader(reservedApptInfo: any) {
    if (reservedApptInfo.rescheduled) {
      return 'Rescheduled Appointment';
    }
    if (reservedApptInfo.cancelled) {
      return 'Cancelled Appointment';
    }
    if (new Date(reservedApptInfo.startTime) > new Date()) {
      return 'Reserved Appointment';
    }
    if (new Date(reservedApptInfo.endTime) > new Date()) {
      return 'Current Appointment';
    }
    return 'Completed Appointment';
  }

  pendingApptHeader(apptRequestInfo: any) {
    if (apptRequestInfo.apptCancelled) {
      return 'Cancelled appointment';
    }

    if (apptRequestInfo.reserved) {
      return 'Reserved appointment';
    }

    if (this.isExpired(apptRequestInfo)) {
      return 'Expired appointment';
    }

    if (apptRequestInfo.rescheduleApptID) {
      return 'Reschedule request';
    }

    return 'Pending appointment';
  }

  productSaleHeader(productSaleRequestInfo: ProductSaleRequestInfo) {
    if (productSaleRequestInfo.purchased) {
      return 'Purchased product';
    }

    if (this.isExpired(productSaleRequestInfo)) {
      return 'Expired sale';
    }

    return 'Pending sale';
  }

  // ACTIONS

  clickRoomApptInfo() {
    if (!this.room || !this.room.roomApptInfo) return;
    if (this.room.roomApptInfo.confirmed) return;

    let apptRequestID = this.room.roomApptInfo.apptRequestID;

    if (apptRequestID) {
      let msg = this.findApptRequestMessage(apptRequestID);
      if (!msg) {
        this.error(
          'Cannot find pending appointment. Please refresh this chat page.',
        );
        return;
      }

      this.performApptAction(msg.apptRequestInfo);
    }
  }

  private findApptRequestMessage(apptRequestID: string) {
    for (let m of this.messages) {
      if (m.apptRequestInfo && m.apptRequestInfo.id == apptRequestID) {
        return m;
      }
    }
  }

  async performApptAction(apptRequestInfo: any) {
    if (this.userService.isBusinessAccount()) {
      return;
    }

    console.log('apptRequestInfo');
    console.log(apptRequestInfo);

    let loading = await this.loadingCtrl.create();
    loading.present();

    BarberService.pullBarberInfo_ID(
      this.room.barber.id,
      (barber: Barber) => {
        loading.dismiss();

        this.apptCreator.barber = barber;

        this.apptCreator.apptTime = new AppointmentTime(
          apptRequestInfo.startTime,
          {
            offset: apptRequestInfo.timezoneOffset,
            abbr: apptRequestInfo.timezoneAbbreviation,
          },
        );
        this.apptCreator.selectedServiceTypeList.items =
          apptRequestInfo.serviceTypeList;
        this.apptCreator.address = apptRequestInfo.address;
        this.apptCreator.availID = apptRequestInfo.availID;
        this.apptCreator.cost = apptRequestInfo.costs;
        this.apptCreator.apptRequestID = apptRequestInfo.id;
        this.apptCreator.barberProfileNavHistory = 'Appt Request';
        this.apptCreator.type = 'v3 appt request';
        this.apptCreator.expiryDate = apptRequestInfo.expiryDate;
        this.apptCreator.isReschedule = Util.isDefined(
          apptRequestInfo.rescheduleApptID,
        );

        let navigationExtras: NavigationExtras = {
          state: {
            previousController: `/pro/${barber.link}`,
          },
        };
        this.navCtrl.navigateForward('/confirm-booking', navigationExtras);
      },
      (msg: string) => {
        this.error(msg);
      },
    );
  }

  async performInvoiceAction(invoiceRequestInfo: any) {
    if (this.userService.isBusinessAccount()) {
      return;
    }

    console.log(invoiceRequestInfo);

    let loading = await this.loadingCtrl.create();
    loading.present();

    BarberService.pullBarberInfo_ID(
      this.room.barber.id,
      (barber: Barber) => {
        loading.dismiss();

        const safeInvoiceRequestInfo = Object.assign({}, invoiceRequestInfo);
        delete safeInvoiceRequestInfo.countdownTimer;

        safeInvoiceRequestInfo.barber = barber;

        const navigationExtras: NavigationExtras = {
          state: {
            invoiceRequestInfo: safeInvoiceRequestInfo,
            previousController: `/pro/${barber.link}`,
          },
        };
        this.navCtrl.navigateForward(`/confirm-invoice`, navigationExtras);
      },
      (msg: string) => {
        this.error(msg);
      },
    );
  }

  async performProductSaleAction(
    productSaleRequestInfo: ProductSaleRequestInfo,
  ) {
    let loading = await this.loadingCtrl.create();
    loading.present();

    BarberService.pullBarberInfo_ID(
      this.room.barber.id,
      (barber: Barber) => {
        loading.dismiss();

        const safeProductSaleRequestInfo = Object.assign(
          {},
          productSaleRequestInfo,
        );
        safeProductSaleRequestInfo.barber = barber;
        const navigationExtras: NavigationExtras = {
          state: {
            productSaleRequestInfo: safeProductSaleRequestInfo,
          },
        };
        this.navCtrl.navigateForward(`/pay-product-sale`, navigationExtras);
      },
      (msg: string) => {
        this.error(msg);
      },
    );
  }

  getApptAction(apptRequestInfo: any) {
    if (this.userService.isBusinessAccount()) {
      return;
    }
    if (this.isPendingApptRequest(apptRequestInfo)) {
      if (apptRequestInfo.rescheduleApptID) {
        return 'Accept new time';
      }
      return 'Confirm appointment';
    }
  }

  getInvoiceAction(invoiceRequestInfo: any) {
    if (this.userService.isBusinessAccount()) {
      return;
    }
    if (this.isPendingInvoice(invoiceRequestInfo)) {
      return 'Pay invoice';
    }
  }

  viewApptDetails() {
    this.navCtrl.navigateRoot(`/appointments`);
  }

  private apptRequestExpired(m: Message) {
    m.apptRequestInfo.expired = true;

    if (
      this.room &&
      this.room.roomApptInfo &&
      !this.room.roomApptInfo.confirmed
    ) {
      this.room.roomApptInfo = undefined;
      this.changeRef.detectChanges();
    }

    this.observables.publishUpdateAllChatRoomInfo();
  }

  private invoiceRequestExpired(m: Message) {
    m.invoiceRequestInfo.expired = true;
  }

  showMsgOptions(msg: Message) {
    let buttons: any[] = [];

    for (let url of this.findURLs(msg.text)) {
      buttons.push(this.linkButton(url));
    }

    if (buttons.length) {
      this.showActionSheet(buttons);
    }
  }

  // Services

  getServiceLongText(serviceTypeList: any[]) {
    if (!serviceTypeList) {
      return '';
    }

    let list = new SelectedServiceTypeList();
    list.items = serviceTypeList;
    list.refreshText();
    return list.longText;
  }

  getProductQuantityText(productQuantityList: ProductQuantityList) {
    return Util.productQuantityText(productQuantityList);
  }

  productImage(
    productSaleRequestInfo: ProductSaleRequestInfo,
    productID: string,
  ) {
    return (
      productSaleRequestInfo.productImageMap[productID] ?? this.placeholderImg
    );
  }

  // Helpers

  private error(msg: string) {
    this.alert.show('Error', msg);
  }

  urlify(text: string) {
    return text.replace(Util.REGEX_URL, (linkString) => {
      if (linkString.includes('.app.link/?room=')) {
        linkString = 'Open Pro chat';
      }

      return `<span class="message-url" style="cursor: pointer"><u>${linkString}</u></span>`;
    });
  }

  private findURLs(text: string) {
    let finalURLs: string[] = [];

    text.replace(Util.REGEX_URL, (match) => {
      let hasPrefix = match.charAt(0).toLowerCase() == 'h';
      let url = (hasPrefix ? '' : 'http://') + match;

      finalURLs.push(url);
      return match;
    });

    return finalURLs;
  }

  private linkButton(url: string): any {
    if (url.includes('.app.link/?room=')) {
      try {
        // TODO: fix this unsafe parsing...
        let roomID = url.split('.app.link/?room=')[1].split('&')[0];

        return {
          text: 'Open new Pro Chat',
          handler: () => {
            this.observables.publishOpenNewChat(roomID);
          },
        };
      } catch (err) {
        // Nothing... just go on
      }
    }

    return {
      text: 'Open link: ' + url,
      handler: () => {
        this.openPage(url);
      },
    };
  }

  private openPage(url: string) {
    // this.iab.create(url, "_blank");
    window.open(url, '_blank');
  }

  private async showActionSheet(buttons: any[]) {
    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {},
    });

    let actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
    });

    actionSheet.present();
  }
}
