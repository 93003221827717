import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeeplinkRoutingService {

  storedBarberID?: string;
  barberProfileNavHistory?: string;
  storedPromo?: string;
  storedEventCode?: string;
  storedRoomID?: string;
  navToInvite?: boolean;
  storedGiftCardCode?: string;
  openGiftCard?: boolean
  openChatSupport?: boolean
  storedBusinessID?: string
  
  constructor() {
    
  }

}
