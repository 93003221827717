import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApptCreator } from 'src/app/services/appt-creation-service';

@Component({
  selector: 'app-select-pro-type',
  templateUrl: './select-pro-type.page.html',
  styleUrls: ['./select-pro-type.page.scss'],
})
export class SelectProTypePage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    public apptCreator: ApptCreator,
  ) { }

  ngOnInit() {
  }

  backButtonPressed() {
    this.modalCtrl.dismiss()
  }

  proTypeSelected(proType: string) {
    this.modalCtrl.dismiss({
      proType
    })
  }



  getImage(proType: string) {
    if (!this.apptCreator.homeProTypeDetails[proType]) return;
    return this.apptCreator.homeProTypeDetails[proType].img;
  }

  getAvailableText(proType: string) {
    if (!this.apptCreator.homeProTypeDetails[proType]) return;
    return this.apptCreator.homeProTypeDetails[proType].availableText;
  }

}
