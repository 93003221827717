import { Component, OnInit } from '@angular/core';

import { SelectedServiceType } from '../../models/selected-service-type-list';

@Component({
  selector: 'app-invoice-receipt',
  templateUrl: './invoice-receipt.page.html',
  styleUrls: ['./invoice-receipt.page.scss'],
})
export class InvoiceReceiptPage implements OnInit {

  costs: any;
  serviceTypeList: SelectedServiceType[];
  refunded?: boolean;

  constructor() {}

  ngOnInit() {}

}
