import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Timezone } from '../../services/timezones';
import { Util } from '../../services/util';

interface WeekPickerOption {
  text: string;
  weekNumber: number;
}

@Component({
  selector: 'app-week-selector',
  templateUrl: './week-selector.page.html',
  styleUrls: ['./week-selector.page.scss'],
})
export class WeekSelectorPage implements OnInit {

  timezone: Timezone
  showAnyTime: boolean
  options: WeekPickerOption[] = []

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.options = this.mainScreenDateOptions_Weeks(this.timezone, this.showAnyTime)
  }

  private mainScreenDateOptions_Weeks(timezone: Timezone, showAnyTime: boolean): WeekPickerOption[] {

    var options: WeekPickerOption[] = [];

    if (showAnyTime) {
      options.push({
        text: "Any time",
        weekNumber: -1
      })
    }

    options.push({
      text: "This week",
      weekNumber: 0
    })

    let week2 = Util.addHours(new Date(), 24 * 7 * 1)
    let week2Day = Util.dayMonthDate_ShortMonth(week2, timezone)

    options.push({
      text: "Week of " + week2Day,
      weekNumber: 1
    })

    let week3 = Util.addHours(new Date(), 24 * 7 * 2)
    let week3Day = Util.dayMonthDate_ShortMonth(week3, timezone)

    options.push({
      text: "Week of " + week3Day,
      weekNumber: 2
    })

    return options;
  }

  timeOptionSelected(option: WeekPickerOption) {
    this.popoverCtrl.dismiss({
      weekNumber: option.weekNumber
    })
  }

}
