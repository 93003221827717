import { Injectable } from '@angular/core';
import { LoadingController } from "@ionic/angular";

import { ImageScaler } from "../image-scaler";
import { ImageData } from '../../models/image-data'

@Injectable({ providedIn: 'root' })
export class BrowserCamera {

  constructor(
    private loadingCtrl: LoadingController
  ) { }


  readScaleAndSubmit(
    file: any,
    successCallback: (data: ImageData) => void,
    errorCallback: (errorMsg: string) => void,
    loading: any
  ) {

    let readerImg = new FileReader();
    readerImg.readAsDataURL(file);

    readerImg.onloadend = function () {

      // console.log(readerImg.result)

      ImageScaler.scaleImage(readerImg.result as string,
        (data: ImageData) => {
          loading.dismiss()
          successCallback(data)
        },
        (errorMsg: string) => {
          loading.dismiss()
          errorCallback(errorMsg)
        });
    };
  }


  selectPhotoFromLibrary(
    shouldScale: boolean,
    successCallback: (data: ImageData) => void,
    errorCallback: (errorMsg: string) => void
  ) {

    let that = this;

    let fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');

    fileInput.onchange = async () => {

      let loading = await that.loadingCtrl.create()
      loading.present()

      let file = fileInput.files[0];

      let readerArrayBuffer = new FileReader();
      readerArrayBuffer.readAsArrayBuffer(file);

      readerArrayBuffer.onloadend = function () {

        that.getOrientation(readerArrayBuffer.result, (orientation: number) => {

          console.log('orientation: ' + orientation)

          if (orientation == 1) {
            that.readScaleAndSubmit(file, successCallback, errorCallback, loading)
            return
          }

          that.resetOrientation(URL.createObjectURL(file), orientation, function (resetBase64Image: string) {

            if (!shouldScale) {
              successCallback({
                pic: resetBase64Image,
                thumbnail: resetBase64Image
              })
              loading.dismiss()
              return
            }

            // console.log(resetBase64Image)

            ImageScaler.scaleImage(resetBase64Image,
              (data: ImageData) => {
                loading.dismiss()
                successCallback(data)
              },
              (errorMsg: string) => {
                loading.dismiss()
                errorCallback(errorMsg)
              });
          });

        });
      };

    };

    fileInput.click();
  }


  private getOrientation(
    result: any,
    callback: (orientation: number) => void) {

    var view = new DataView(result);

    if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

    var length = view.byteLength,
      offset = 2;

    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;

      if (marker == 0xFFE1) {
        if (view.getUint32(offset += 2, false) != 0x45786966) {
          return callback(-1);
        }
        var little = view.getUint16(offset += 6, false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;

        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + (i * 12), little) == 0x0112)
            return callback(view.getUint16(offset + (i * 12) + 8, little));
      }
      else if ((marker & 0xFF00) != 0xFF00) break;
      else offset += view.getUint16(offset, false);
    }
    return callback(-1);
  };

  private resetOrientation(srcBase64: string, srcOrientation: number, callback: (resetBase64Image: string) => void) {
    let img = new Image();

    img.onload = () => {
      let width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }

}