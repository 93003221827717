<ion-content>

	<div class="logo">
		<img src="assets/img/shortcut-icon.png" alt="Shortcut">
	</div>

	<p class="SCWEB-header-text">Let's verify your<br>phone number</p>

	<p id="description-text">We just sent a 4-digit code to the number<br>you provided.</p>

	<ion-item lines="none">
		<ion-input [(ngModel)]="code" type="phone" required placeholder="4-digit code">
		</ion-input>
	</ion-item>

	<ion-button class="SC-long-button" color="marine" (click)="onVerify()" type="submit" block>
		Verify
	</ion-button>

	<ion-row class="SC-bottom-button-row">
		<ion-col>
			<ion-button class="SC-light-bottom-button" fill="clear" (click)="backPressed()">Go Back</ion-button>
		</ion-col>
	</ion-row>

</ion-content>
