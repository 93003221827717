import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '../services/util';


@Pipe({
    name: 'timeRangeLong',
})
export class TimeRangeLongPipe implements PipeTransform {
    transform(value1: Date, value2: Date) {
        return Util.timeRangeLong(value1, value2);
    }
}