import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Constants } from '../models/constants';

const defaultTitle = Constants.DEV_MODE
  ? 'Shortcut [Dev]: Wellness At Work/Home'
  : 'Shortcut: Wellness At Work/Home';
const defaultDescription =
  'Searching for wellness services? Haircuts, Massages and Mindfulness Sessions available. Instantly book professionals near you.';
const defaultTags = {
  keywords: 'shortcut, haircut, barber, stylist',
  description:
    'Searching for wellness services? Haircuts, Massages and Mindfulness Sessions available. Instantly book professionals near you.',
  facebookTitle: 'Shortcut | Wellness at Work and Home',
  facebookDescription: defaultDescription,
  facebookURL: 'https://app.getshortcut.co/',
  facebookImage:
    'https://www.getshortcut.co/wp-content/uploads/2018/03/14124262_955881937870614_6967054273325273573_o-e1526404791289.jpg',
  facebookImageWidth: '1400',
  facebookImageHeight: '924',
  twitterDescription: defaultDescription,
  twitterTitle: 'Shortcut | Wellness at Work and Home',
  twitterImage:
    'https://www.getshortcut.co/wp-content/uploads/2018/03/14124262_955881937870614_6967054273325273573_o-e1526404791289.jpg',
};

interface MetaTagOptions {
  keywords?: string;
  description?: string;
  facebookTitle?: string;
  facebookDescription?: string;
  facebookURL?: string;
  facebookImage?: string;
  facebookImageWidth?: string;
  facebookImageHeight?: string;
  twitterDescription?: string;
  twitterTitle?: string;
  twitterImage?: string;
}

@Injectable({ providedIn: 'root' })
export class MetaTagService {
  constructor(private titleService: Title, private meta: Meta) {
    if (Constants.DEV_MODE) {
      // Set dev favicon
      document
        .getElementById('favicon')
        .setAttribute('href', 'assets/img/shortcut-icon-test.png');
    }
  }

  updateTitle(title: string = defaultTitle) {
    if (title === defaultTitle) {
      // This is terrible but not a big deal. Can't figure out why it's not working a smarter way.
      this.titleService.setTitle(title);
      return;
    }
    const appName = Constants.DEV_MODE ? 'Shortcut Dev' : 'Shortcut';
    this.titleService.setTitle(`${title} | ${appName}`);
  }

  updateTags(opts: MetaTagOptions = {}) {
    if (!opts) {
      opts = {};
    }

    this.meta.updateTag({
      name: 'keywords',
      content: opts.keywords || defaultTags.keywords,
    });

    this.meta.updateTag({
      name: 'description',
      content: opts.description || defaultTags.description,
    });

    this.meta.updateTag({
      property: 'og:title',
      content: opts.facebookTitle || defaultTags.facebookTitle,
    });

    this.meta.updateTag({
      property: 'og:url',
      content: opts.facebookDescription || defaultTags.facebookDescription,
    });

    this.meta.updateTag({
      property: 'og:url',
      content: opts.facebookURL || defaultTags.facebookURL,
    });

    this.meta.updateTag({
      property: 'og:image',
      content: opts.facebookImage || defaultTags.facebookImage,
    });

    this.meta.updateTag({
      property: 'og:image:secure_url',
      content: opts.facebookImage || defaultTags.facebookImage, // Reuse the normal fb image link
    });

    this.meta.updateTag({
      property: 'og:image:width',
      content: opts.facebookImageWidth || defaultTags.facebookImageWidth,
    });

    this.meta.updateTag({
      property: 'og:image:height',
      content: opts.facebookImageHeight || defaultTags.facebookImageHeight,
    });

    this.meta.updateTag({
      name: 'twitter:description',
      content: opts.twitterDescription || defaultTags.twitterDescription,
    });

    this.meta.updateTag({
      name: 'twitter:title',
      content: opts.twitterTitle || defaultTags.twitterTitle,
    });

    this.meta.updateTag({
      name: 'twitter:image',
      content: opts.twitterImage || defaultTags.twitterImage,
    });
  }
}
