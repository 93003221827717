import { NgModule } from '@angular/core';
import { Autosize } from '../directives/autosize';

@NgModule({
  declarations: [
    Autosize
  ],
  imports: [
  ],
  exports: [
    Autosize
  ]
})

export class AutosizeModule {}
