<!-- cancel fee -->
<div *ngIf="cancelled">
  <p>Cancellation fee</p>
  <p class="right">{{formatCost(costs.total - costs.tax)}}</p>
</div>

<!-- service charges -->
<div *ngIf="!cancelled">
  <p>{{serviceTitle}}</p>
  <p class="right">{{formatCost(costs.subtotal)}}</p>
</div>

<!-- tax -->
<p>Tax</p>
<p class="right">{{formatCost(costs.tax)}}</p>

<hr>

<div *ngIf="costs.creditAmt">
  <p>Account credit</p>
  <p class="right">{{formatCost(costs.creditAmt)}}</p>
</div>

<!-- charge -->
<p>Total charge</p>
<p class="right">{{formatCost(costs.total)}}</p>

<!-- barber tip -->
<div *ngIf="costs.tip && !cancelled">
  <p>Tip</p>
  <p class="right">{{formatCost(costs.tip)}}</p>
</div>