<ion-app (window:resize)="onResize()">
  <ion-split-pane when="(min-width: 840px)" content-id="main">
    <ion-menu contentId="main" type="overlay" swipeGesture="false" (ionWillOpen)="menuToggled(true)" (ionWillClose)="menuToggled(false)">

      <img class="logo" src="assets/img/shortcut-logo-white.png" (click)="goToWebsite()"/>
      <img class="menu-oval" src="assets/img/ovals/blue-oval-menu.png"/>

      <ion-content>

        <ion-list lines="none">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of menuPages">
            <ion-item (click)="openMenuPage(p)" [ngClass]="[currentRoot == p.url ? 'selected' : '', p.isSwitchProType ? 'switch-pro-type' : 'regular-hover']" *ngIf="!(p.hideOnMobile && deviceService.isMobile)">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}} <span class="upcoming-total-badge" *ngIf="p.showUpcomingTotal && numUpcomingTotal">{{numUpcomingTotal}}</span>
                <p *ngIf="p.subtitle">{{p.subtitle}}</p>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list lines="none" class="bottom-pages">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of bottomMenuPages">
            <ion-item (click)="openMenuPage(p)" [ngClass]="[currentRoot == p.url ? 'selected' : '', p.isSwitchProType ? 'switch-pro-type' : 'regular-hover']" *ngIf="!(p.hideOnMobile && deviceService.isMobile)">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}} <span class="upcoming-total-badge" *ngIf="p.showUpcomingTotal && numUpcomingTotal">{{numUpcomingTotal}}</span>
                <p *ngIf="p.subtitle">{{p.subtitle}}</p>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main">

      <div *ngIf="!deviceService.isMobile && currentRoot !== 'welcome'">

        <!-- CHAT WINDOW -->
        <div class="chat-holder" *ngFor="let chat of chatManager.activeChats" [ngClass]="chat.windowOpened ? 'open' : 'closed'" (transitionend)="chatManager.chatToggledOpen(chat, $event)">
          <div class="chat-header" (click)="chatManager.toggleChatOpened( chat.room.id )" [ngClass]="!chat.windowOpened && chat.numUnreadMessages ? 'chat-header-closed-has-unread' : ''">
            <div class="name-img-wrapper">
              <img class="header-img" src="{{ chat.room.img || placeholderImg }}"/>
              <span class="header-title" [ngClass]="chat.room.barber ? 'hover-select' : ''" (click)="chat.room.barber ? openProProfile($event, chat.room.barber.link, true) : !!false">
                {{ chat.room.name }}
                <div class="name-unread-number" *ngIf="chat.numUnreadMessages && !chat.windowOpened">
                  <div class="name-unread-text">{{chat.numUnreadMessages}}</div>
                </div>
              </span>
            </div>
            <ion-button *ngIf="userService.isBusinessAccount() && chat.windowOpened" fill="clear" class="header-button chat-minimize" (click)="chatManager.openBusinessChatOptions( chat.room.id, $event )">
              <ion-icon slot="icon-only" color="marine" name="ellipsis-horizontal-outline"></ion-icon>
            </ion-button>
            <ion-button *ngIf="!userService.isBusinessAccount() || !chat.windowOpened" fill="clear" class="header-button chat-minimize" (click)="chatManager.toggleChatOpened( chat.room.id, $event )">
              <ion-icon slot="icon-only" color="marine" name="{{chat.windowOpened ? 'remove' : 'chevron-up-circle-outline'}}"></ion-icon>
            </ion-button>
            <ion-button fill="clear" class="header-button chat-close" (click)="chatManager.closeChat( chat.room.id, $event )">
              <ion-icon slot="icon-only" color="marine" name="close"></ion-icon>
            </ion-button>
          </div>
          <div class="chat-messages">
            <chat-content
              roomID="{{chat.room.id}}"
              [room]="chat.room"
              [windowOpened]="chat.windowOpened"
              (newUnreadMessageForRoom)="chatManager.newUnreadMessageForRoom($event)"
              (resetUnreadMessagesForRoom)="chatManager.resetUnreadMessagesForRoom($event)"
            ></chat-content>
          </div>

          <!-- The ion-selector requires a rendered HTML element. -->
          <ion-select #businessProChatSelector [(ngModel)]="chatManager.selectedActiveLiveProID" (ionChange)="chatManager.selectActiveLivePro()" style="display: none"  value="" placeholder="" [interfaceOptions]="{header: 'Select a Pro', subHeader: 'Showing all Active/Live Pros.'}">
            <ion-select-option *ngFor="let p of chatManager.activeLiveProList" value="{{p.id}}">{{p.fullName}}</ion-select-option>
          </ion-select>
        </div>

        <div class="rooms-button" (click)="openChatRooms($event)">
          <ion-button shape="round" color="chatblue" slot="icon-only">
            <ion-icon name="chatbubbles"></ion-icon>
          </ion-button>
          <!-- <div class="unread-number" *ngIf="chatManager.numUnreadMessages">
            <div class="unread-text">{{chatManager.numUnreadMessages}}</div>
          </div> -->
          <div class="unread-dot" *ngIf="chatManager.hasUnreadMessages"></div>
        </div>

      </div>

    </ion-router-outlet>
    <div *ngIf="isMenuOpen" (click)="closeMenu()" class="close-menu-overlay"></div>
  </ion-split-pane>
</ion-app>