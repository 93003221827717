import { Injectable } from '@angular/core';

import { Barber } from '../models/barber';
import { Constants } from '../models/constants';

import { SocialSharing } from "@ionic-native/social-sharing/ngx";
// import { NgNavigatorShareService } from 'ng-navigator-share';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class SharingService {

  referralBonus: string = ''
  referralDiscountPercentage: string = ''

  constructor(
    private socialSharing: SocialSharing) {
  }

  shareReferralCode(referralCode: string) {

    let url = `https://shortcut.app.link/share?promo=${referralCode}`;
    let text = `Have you tried Shortcut? Sign up with my code: ${referralCode} and you’ll get 15% off your first haircut. \n${url}`;
    
    this.shareLink(url, text)
  }

  shareBarber(
    barber: Barber,
    successCallback?: () => void,
    errorCallback?: (errMsg: string) => void
  ) {

    let url = `https://shortcut.app.link/share?barber=${barber.id}`;
    let text = `You've gotta try Shortcut – we'll cut your hair anytime, anywhere.\n\nHere's a link to my profile in the app 🔥🔥: \n${url}`;

    this.shareLink(url, text, successCallback, errorCallback)
  }

  private shareLink(
    url: string, 
    text: string, 
    successCallback?: () => void,
    errorCallback?: (errMsg: string) => void) {

    if (Constants.BROWSER_MODE) {
      this.shareLinkBrowser(url, text, successCallback, errorCallback)
      return
    }

    this.socialSharing.share(text, '', null, null).then(() => {
      if (successCallback) successCallback();
    }).catch((errMsg: string) => {
      if (errorCallback) errorCallback(errMsg);
    });

  }

  private shareLinkBrowser(
    url: string, 
    text: string, 
    successCallback?: () => void,
    errorCallback?: (errMsg: string) => void) {

      if (window.navigator && window.navigator.share) {

        window.navigator.share({
            title: 'Share link',
            text: text,
            url: url,
        })
        .then(() => {
          console.log('Successful share')
          if (successCallback) successCallback();
        })
        .catch((errMsg: string) => {
          console.log('Error sharing', errMsg)
          if (errorCallback) errorCallback(errMsg);
        });

      } else {

        let errMsg = 'Cannot share from this device. Please download the Shortcut app.'

        if (errorCallback) {
          errorCallback(errMsg);
        } else {
          alert(errMsg)
        } 
      }
  }

}
