import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController, LoadingController, ModalController } from '@ionic/angular';

import { Constants } from "../../models/constants";

import { Alert } from "../../services/alert";
import { UserService } from "../../services/user-service";
import { AnalyticsService } from '../../services/analytics-service';
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/services/observable-service';

@Component({
  selector: 'app-enter-verify-code',
  templateUrl: './enter-verify-code.page.html',
  styleUrls: ['./enter-verify-code.page.scss'],
})
export class EnterVerifyCodePage implements OnInit {

  code?: string;

  constructor(
    public navCtrl: NavController,
    public loadingCtrl: LoadingController,
    public alert: Alert,
    public userService: UserService,
    public observables: ObservableService,
    private analytics: AnalyticsService,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  async onVerify() {
    if (this.code) {
      let loading = await this.loadingCtrl.create();
      loading.present();
      this.userService.verifyPhone(this.code, async () => {
        loading.dismiss();

        if (this.userService.user.eventSignUp) {
          this.analytics.track(AnalyticsService.VerifiedPhoneEvent)
        } else {
          this.analytics.track(AnalyticsService.VerifiedPhone)
        }

        this.observables.publishRefreshSideMenu()

        await this.modalCtrl.dismiss()

        this.observables.publishVerifyPhoneComplete()
        if (!this.userService.user.email || this.userService.user.email === '') {
          this.observables.publishGoToEmptyNameEmail()
        }

      }, (errorMsg) => {
        loading.dismiss();
        this.alert.show('Error', errorMsg, 'Ok');
      });
    } else {
      this.alert.show('Error', 'Please enter a code.', 'Ok');
    }
  }

  async backPressed() {
    await this.modalCtrl.dismiss()
    this.observables.publishGoToEnterPhoneNumber()
  }

}
