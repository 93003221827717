import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

import { Constants } from '../models/constants';
import { ImageData } from '../models/image-data'

import { AlertController, LoadingController, ActionSheetController } from "@ionic/angular";

import { BrowserCamera } from './vibes-browser/camera';
import { ImageScaler } from "./image-scaler";

export interface PicFiles {
  pic: any
  thumbnail: any
}

@Injectable({ providedIn: 'root' })
export class PicService {

  constructor(
    private camera: Camera,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private actionSheetCtrl: ActionSheetController,
    private browserCamera: BrowserCamera,
  ) { }


  async getPhoto(
    shouldScale: boolean,
    shouldResize: boolean,
    showActionSheet: boolean,
    successCallback: (files: PicFiles) => void,
    errorCallback: (errorMsg: string) => void
  ) {

    if (Constants.BROWSER_MODE) {
      console.log('use browser cam')
      this.browserCamera.selectPhotoFromLibrary(shouldScale, (data: ImageData) => {
        let picFiles = this.createFilesFromData(data)
        successCallback(picFiles)
      }, errorCallback);
      return
    }

    let buttons: any[] = [
      {
        text: "Take New Photo",
        handler: (() => {
          this.takeNewPhoto(shouldScale, shouldResize, successCallback, errorCallback);
        })
      },
      {
        text: "Select from Library",
        handler: (() => {
          this.choosePhotoFromLibrary(shouldScale, shouldResize, successCallback, errorCallback);
        })
      },
      {
        text: "Cancel",
        handler: (() => { })
      }
    ]
    if (showActionSheet) {
      let actionSheet = await this.actionSheetCtrl.create({
        header: 'Upload Image',
        buttons: buttons
      });

      actionSheet.present();
    } else {
      let alert = await this.alertCtrl.create({
        header: 'Upload Image',
        message: null,
        buttons: buttons
      });

      alert.present();
    }
  }

  uploadFile(
    file: any,
    successCallback: (url: string) => void,
    errorCallback: (errorMsg: string) => void
  ) {
    file.save().then(function(savedFile: any){
        successCallback(savedFile._url)
    }, function(err: string) {
        errorCallback(err)
    });
  }



  //  PRIVATE METHODS

  private async takeNewPhoto(
    shouldScale: boolean,
    shouldResize: boolean,
    successCallback: (files: PicFiles) => void,
    errorCallback: (errorMsg: string) => void
  ) {

    let options: CameraOptions = {
      quality: 100,
      targetWidth: shouldResize ? 400 : undefined,
      targetHeight: shouldResize ? 400 : undefined,
      allowEdit: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true
    }

    let loading = await this.loadingCtrl.create();
    loading.present();

    this.camera.getPicture(options).then((imageData) => {

      let base64Img = 'data:image/jpeg;base64,' + imageData;

      this.scaleImageAndCreateFiles(base64Img, shouldScale, (files: PicFiles) => {
        loading.dismiss();
        successCallback(files);
      }, (errorMsg: string) => {
        loading.dismiss();
        errorCallback(errorMsg);
      });
    }, (err: string) => {
      loading.dismiss();
      errorCallback(err);
    });
  }

  private async choosePhotoFromLibrary(
    shouldScale: boolean,
    shouldResize: boolean,
    successCallback: (files: PicFiles) => void,
    errorCallback: (errorMsg: string) => void
  ) {
    let options: CameraOptions = {
      quality: 100,
      targetWidth: shouldResize ? 400 : undefined,
      targetHeight: shouldResize ? 400 : undefined,
      allowEdit: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
    }

    let loading = await this.loadingCtrl.create();
    loading.present();

    this.camera.getPicture(options).then((imageData: string) => {
      
      let base64Img = 'data:image/jpeg;base64,' + imageData;
      
      this.scaleImageAndCreateFiles(base64Img, shouldScale, (files: PicFiles) => {
        loading.dismiss();
        successCallback(files);
      }, (errorMsg: string) => {
        loading.dismiss();
        errorCallback(errorMsg);
      });
    }, (err: string) => {
      loading.dismiss();
      errorCallback(err);
    });
  }

  private scaleImageAndCreateFiles(
    imageData: string,
    shouldScale: boolean,
    successCallback: (files: PicFiles) => void,
    errorCallback: (errorMsg: string) => void) {

    if (!shouldScale) {
      let data: ImageData = {thumbnail: imageData, pic: imageData};
      let files = this.createFilesFromData(data)
      successCallback(files)
      return
    }

    ImageScaler.scaleImage(imageData, (data: ImageData) => {
      let files = this.createFilesFromData(data)
      successCallback(files);
    }, (errorMsg: string) => {
      errorCallback(errorMsg);
    });
  }

  private createFilesFromData(data: ImageData): PicFiles {
    let parseFile = new Parse.File("pic.jpeg", { base64: data.pic });
    let thumbParseFile = new Parse.File("thumb.jpeg", { base64: data.thumbnail })
    return { pic: parseFile, thumbnail: thumbParseFile }
  }

}