import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptComponent } from './receipt/receipt.component';
import { EventReceiptComponent } from './event-receipt/event-receipt.component';
import { InvoiceReceiptComponent } from './invoice-receipt/invoice-receipt.component';
import { CostBreakdownComponent } from './cost-breakdown/cost-breakdown.component';
import { ConfirmFooterComponent } from './confirm-footer/confirm-footer.component';



@NgModule({
  declarations: [
    ReceiptComponent,
    EventReceiptComponent,
    InvoiceReceiptComponent,
    CostBreakdownComponent,
    ConfirmFooterComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ReceiptComponent,
    EventReceiptComponent,
    InvoiceReceiptComponent,
    CostBreakdownComponent,
    ConfirmFooterComponent
  ]
})
export class ComponentsModule { }
