import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes-module';

import { ChatContentComponent } from './chat-content/chat-content.component';

@NgModule({
  declarations: [
    ChatContentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    IonicModule
  ],
  exports: [
    ChatContentComponent,
  ]
})
export class ChatContentModule { }
