import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';

import { Constants } from "../../models/constants";

import { Alert } from "../../services/alert";
import { UserService } from "../../services/user-service";
import { PushInstallationService } from "../../services/push-installation-service";
import { ObservableService } from 'src/app/services/observable-service';

interface UserFields {
  email?: string;
  firstName?: string;
  lastName?: string;
}

@Component({
  selector: 'app-empty-name-email',
  templateUrl: './empty-name-email.page.html',
  styleUrls: ['./empty-name-email.page.scss'],
})
export class EmptyNameEmailPage implements OnInit {

  fields: UserFields = {}

  constructor(
    private userService: UserService,
    public alert: Alert,
    private observables: ObservableService,
    private loadingCtrl: LoadingController,
    public pushInstallationService: PushInstallationService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.userService.user) {
      this.fields.email = this.userService.user.email;
      this.fields.firstName = this.userService.user.firstName;
      this.fields.lastName = this.userService.user.lastName;
    }
  }

  async onSubmit(form: NgForm) {
    if (!form.valid || !this.fields || !this.fields.email || !this.fields.firstName || !this.fields.lastName) {
      this.alert.show('Error', 'Please make sure you enter correct values for all fields.', 'Ok');
      return
    }

    let loading = await this.loadingCtrl.create();
    loading.present();

    let params = {
      email: this.fields.email.trim(),
      firstName: this.fields.firstName.trim(),
      lastName: this.fields.lastName.trim(),
      fullName: `${this.fields.firstName} ${this.fields.lastName}`.trim()
    }

    this.userService.update(params, () => {
      this.userService.sendWelcomeEmail(() => {
        loading.dismiss()
        this.saveSuccess();
      }, (errorMsg) => {
        loading.dismiss();
        this.alert.show('Email Failed', 'Please enter your email again.', 'Ok');
      });
    }, (errorMsg) => {
      loading.dismiss();
      this.alert.show('Error', errorMsg, 'Ok');
    });
  }

  private saveSuccess() {
    this.observables.publishRegisterPush()
    this.observables.publishRefreshMainView()
    this.observables.publishRefreshSideMenu()

    this.modalCtrl.dismiss()
  }

}
