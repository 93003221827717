<div id="cost-wrapper">

    <!-- service charges -->
    <div *ngFor="let s of selectedServiceTypeList.items">
      <p>{{s.title}} (x{{s.quantity}})</p>
      <p class="right">{{formatCost(s.price * s.quantity)}}</p>
    </div>
  
    <!-- promo code -->
    <div *ngIf="promoCode">
      <p>Promo ({{promoCode}})</p>
      <p class="right">-{{formatCost(cost.promoAmt)}}</p>
    </div>
  
    <!-- credit -->
    <div *ngIf="cost.creditAmt">
      <p>Credit</p>
      <p class="right">-{{formatCost(cost.creditAmt)}}</p>
    </div>
  
    <!-- tax -->
    <p>Tax</p>
    <p class="right">{{formatCost(cost.taxAmt)}}</p>
  
    <div *ngIf="cost.travelUpcharge">
      <p>Travel upcharge</p>
      <p class="right">{{formatCost(cost.travelUpcharge)}}</p>
    </div>

    <div *ngIf="cost.convenienceFee">
      <p>Convenience fee</p>
      <p class="right">{{formatCost(cost.convenienceFee)}}</p>
    </div>
  
    <hr>
  
    <!-- charge -->
    <p>Total charge</p>
    <p class="right">{{formatCost(cost.finalCost)}}</p>
  
  
  </div>