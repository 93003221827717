<ion-header>

	<ion-toolbar color="marine">

	  <ion-title>Shortcut in-home services</ion-title>

	</ion-toolbar>

</ion-header>

<ion-content>
  <div *ngFor="let proType of apptCreator.homeProTypes" class="container" (click)="proTypeSelected(proType)">
    
  	<div class="img-background" [ngStyle]="{'background-image': 'url(' + getImage(proType) + ')'}">
  	</div>

  	<div class="text-container">
      <p class="title">{{proType}}</p>
      <p class="subtitle">{{getAvailableText(proType)}}</p>
    </div>

  </div>
</ion-content>