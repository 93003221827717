<div class="inner-content" [ngClass]="!showAcceptDecline ? 'extra-bottom-padding' : ''">
  <div class="logo">
    <img src="assets/img/shortcut-icon.png" alt="Shortcut">
  </div>
  <div *ngIf="!isCovidGuideline">
    <p class="SCWEB-header-text">{{ popup.header }}</p>
    <p class="popup-sub-header">{{ popup.subHeader }}</p>
    <p class="popup-sub-sub-header">{{ popup.subSubHeader }}</p>
    <div *ngFor="let step of popup.steps; let i = index" class="step">
      <div class="step-number">
        <p>{{ i + 1 }}</p>
      </div>
      <p class="instructions">{{ step }}</p>
    </div>
    <ion-button color="marine" class="SC-floating-button submit-button"
      (click)="mainPressed()">{{ showAcceptDecline ? acceptButtonTitle : 'Okay' }}</ion-button>
    <p *ngIf="showAcceptDecline" class="decline-button" (click)="declinePressed()">{{declineButtonTitle}}</p>
  </div>
  <div *ngIf="isCovidGuideline">
    <p class="covid-text">We've updated our COVID-19 guidelines. Please view and accept our updated <span class="link" (click)="linkPressed('https://www.getshortcut.co/terms/')">terms of service.</span></p>
    <ion-row class="SC-bottom-button-row">
      <ion-col>
        <ion-button color="marine" class="SC-floating-button submit-button" (click)="mainPressed()">{{acceptButtonTitle}}</ion-button>
        <p *ngIf="showAcceptDecline" class="decline-button" (click)="declinePressed()">{{declineButtonTitle}}</p>
      </ion-col>
    </ion-row>
  </div>
</div>