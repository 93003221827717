import { CloudCode } from './cloudcode';
import { Util } from './util';

export interface BarberProfileReview {
  firstName: string
  message: string
  rating: number
  reviewID: string
  state: string
  thumbnail: string
  date: Date
}

export class MoreReviewsTasks {

  static pullMoreReviews(
    barberID: string,
    curatedReviewIDs: string[],
    successCallback: (reviews: BarberProfileReview[]) => void,
    errorCallback: (msg: string) => void) {

    CloudCode.run('pullUncuratedReviewsForBarber', 
      {barberID: barberID}, 
      (reviews: BarberProfileReview[]) => {

        console.log(reviews)

        var filteredReviews: BarberProfileReview[] = []

        for (let review of reviews) {

          if (!Util.contains(curatedReviewIDs, review.reviewID)) {
            filteredReviews.push(review)
          }

        }

        successCallback(filteredReviews);

      }, 
      (msg: string) => {
        errorCallback(msg);
      });
  }

}
