import { Injectable } from '@angular/core';
import { CloudCode } from "./cloudcode";
import { ServiceType } from '../models/service-type';


@Injectable({ providedIn: 'root' })
export class HomeService {

  constructor() {
  }

  pullActiveHomeServices_Categories(
    type: string,
    successCallback: (serviceTypes: ServiceType[], categories: string[]) => void,
    errorCallback: (msg: string) => void
  ) {

    let params = {
      type: type,
      includeChatOnlyServices: true,
    };

    CloudCode.run('pullActiveHomeServices_Categories', params,
      (rsp: any) => {
        if (rsp) {
          successCallback(rsp.services, rsp.categories);
        } else {
          errorCallback('No services found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

  pullAddOnsForTheseHomeServices(
    serviceIDs: string[],
    successCallback: (serviceTypes: ServiceType[]) => void,
    errorCallback: (msg: string) => void
  ) {

    let params = { serviceIDs: serviceIDs };

    CloudCode.run('pullAddOnsForTheseHomeServices', params,
      (rsp: ServiceType[]) => {
        if (rsp) {
          successCallback(rsp);
        } else {
          errorCallback('No services found.');
        }
      },
      (msg: string) => {
        errorCallback(msg);
      });
  }

}


