import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';

import { EventService, EventApptCost } from '../../services/event-service';
import { Alert } from '../../services/alert';
import { Util } from '../../services/util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-receipt',
  templateUrl: './event-receipt.page.html',
  styleUrls: ['./event-receipt.page.scss'],
})
export class EventReceiptPage implements OnInit {

  cost: EventApptCost
  currency: string
  serviceTitle: string

  constructor(
    private navCtrl: NavController,
    private eventService: EventService,
    private alert: Alert,
    private router: Router,
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
    this.cost.currency = this.currency
  }

}
